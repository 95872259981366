import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { downloadExcel } from 'react-export-table-to-excel';

import * as Yup from 'yup';
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Pagination,
  Box,
  Drawer,
  TextField,
  Grid,
  MenuItem,
} from '@mui/material';
import moment from 'moment';
import { useFormik } from 'formik';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Modal } from '@material-ui/core';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import { UserListHead } from '../../sections/@dashboard/user';
import BaseColorDialog from '../../components/DialogBox/tree-data/BaseColorDialog';
import TreeCensusMenu from '../../sections/@dashboard/tree/TreeCensusMenu';
import ViewImageDialog from '../../components/DialogBox/tree-data/ViewImageDialog';
import GenerateNoticeDialog from '../../components/DialogBox/GenerateNoticeDialog';
import { GetReportRequest } from '../../actions/WorkReportAction';
import { GetMyActiveTeam } from '../../actions/TeamsAction';
import {
  DeleteCensusTrees,
  GetTreeCensus,
  ImportCensusTrees,
  SearchTreeCensus,
  UpdateQCStatusOfTreeCensus,
  UpdateViewCensusTree,
  exportCensusReportsApi,
  ImportStatus,
} from '../../actions/TreeCensusAction';
import { GetActiveCouncil, SetActiveCouncil } from '../../actions/CouncilAction';
import { GetUsersByRoleID } from '../../actions/UserAction';
import { GetActiveZonesByCouncilId, GetActiveZones, SetActiveZones } from '../../actions/ZonesAction';
import { GetActiveWardsByCouncilId, GetActiveWards, SetActiveWards } from '../../actions/WardsActions';
import { GetActiveTreeName } from '../../actions/TreeNameAction';
import DeleteConfirmationDialog from '../../components/DialogBox/DeleteConformationDialog';
import QcStatusDialog from '../../components/DialogBox/tree-data/QcStatusDialog';
import CencusViewDetailsDialog from '../../components/DialogBox/tree-data/CensusViewDetailsDialog';
import CencusViewWardDialog from '../../components/DialogBox/tree-data/CencusViewWardDialog';
import StatusPendngButton from '../../components/statusbutton/StatusPendngButton';
import StatusApprovedButton from '../../components/statusbutton/StatusApprovedButton';
import StatusUnapprovedButton from '../../components/statusbutton/StatusUnapprovedButton';
import WarningMessageDialog from '../../components/DialogBox/WarningMessageDialog';
import FullLoader from '../../components/Loader/FullLoader';
import { ShowLoader } from '../../actions/CommonAction';
// import JWTServer  from '../../api/withJWTServer'
import { SetNewAlert } from '../../actions/AlertActions';
import { DeficientTreeNoticeAction } from '../../actions/DeficientTreeNoticeAction';
import { GetActiveTreeType } from '../../actions/TreeTypeActions';
import './Census.css';
import ImportTreeDialog from '../../components/DialogBox/ImportTreeDialog';
import ImportErrorDialog from '../../components/DialogBox/tree-data/ImportErrorDialog';
import { GetPropertyByCouncilZoneWard } from '../../actions/PropertyAction';
import warningSound from '../../Assets/warning_sound.mp3';

// import { HandleExceptionWithSecureCatch } from "../../actions/CombineCatch";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'srno', label: '#', alignRight: false },
  { id: 'treeNumber', label: 'Tree Number', alignRight: false },
  { id: 'treeName', label: 'Tree Name', alignRight: false },
  { id: 'addedBy', label: 'Added By', alignRight: false },
  { id: 'addedOn', label: 'Added On', alignRight: false },
  // { id: 'age', label: 'Tree Age', alignRight: false },
  { id: 'locationAccuracyNeeded', label: 'Location Accuracy Captured', alignRight: false },
  { id: 'QCStatus', label: 'QC Status', alignRight: false },
  { id: 'isReferredToExpert', label: 'Is Referred To Expert?', alignRight: false },
  { id: 'action', label: 'Action', alignRight: true },
];

const TABLE_HEADTWO = [
  { id: 'srno', label: '#', alignRight: false },
  { id: 'importedat', label: 'Imported At', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'error', label: 'Error', alignRight: false },
];

const COUNCIL_HEAD = [
  { id: 'srno', label: '#', alignRight: false },
  { id: 'treeNumber', label: 'Tree Number', alignRight: false },
  { id: 'treeName', label: 'Tree Name', alignRight: false },
  { id: 'addedBy', label: 'Added By', alignRight: false },
  { id: 'addedOn', label: 'Added On', alignRight: false },
  // { id: 'age', label: 'Tree Age', alignRight: false },
  { id: 'locationAccuracyNeeded', label: 'Location Accuracy Captured', alignRight: false },
  { id: 'action', label: 'Action', alignRight: true },
];

// ----------------------------------------------------------------------

export default function Census() {
  const dispatch = useDispatch();
  const [councilID, setCouncilID] = React.useState('');
  const [zoneID, setZoneID] = React.useState('');
  const [wardID, setWardID] = React.useState('');
  const [page, setPage] = useState(1);
  const [statusPage, setStatusPage] = useState(1);
  const [status, setStatus] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(10);
  const [addedBy, setAddedBy] = React.useState('');
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [stateName, setStateName] = useState('');
  const [zoneId, setZoneId] = useState('');
  const [wardId, setWardId] = useState('');
  const [coucilId, setCouncilId] = useState('');
  const [imageList, setImageList] = useState([]);
  const [showList, setShowList] = useState(false);
  const [showStatusList, setShowStatusList] = useState(false);

  const [qcDialogOpen, setQcDialogOpen] = useState(false);
  const [viewCensusDetails, setViewCensusDetails] = useState(false);
  const [viewWardDetails, setViewWardDetails] = useState(false);

  const [treeCensusId, setTreeCensusId] = useState('');
  const [addedByForm, setAddedByForm] = React.useState();
  const [heightFrom, setHeightFrom] = React.useState();
  const [heightTo, setHeightTo] = React.useState();
  const [girthFrom, setGirthFrom] = React.useState();
  const [girthTo, setGirthTo] = React.useState();
  const [treeNameId, setTreeNameId] = React.useState();
  const [treeNameFrom, setTreeNameFrom] = React.useState();
  const [formDate, setFromDate] = React.useState();
  const [toDate, setToDate] = React.useState();
  const [treeNumber, setTreeNumber] = React.useState();
  const [heightFromId, setHeightFromId] = React.useState();
  const [heightToId, setHeightToId] = React.useState();
  const [girthFromId, setGirthFromId] = React.useState();
  const [girthToId, setGirthToId] = React.useState();
  const [reportForRequest, setReportForRequest] = React.useState();
  const [councilName, setCouncilName] = React.useState();
  const [buttonClick, setButtonClick] = React.useState(true);
  const [treeNameInput, setTreeNameInput] = React.useState();
  const todayDate = moment(new Date()).format('YYYY-MM-DD');
  const [generateReportModel, setGenerateReportModel] = useState(false);

  const [coucilIdForReport, setCoucilIdForReport] = useState(0);
  const [wardIdForReport, setWardIdForReport] = useState(0);
  const [zoneIdForReport, setZoneIdForReport] = useState(0);
  const [generateNoticeDialogOpen, setGenerateNoticeDialogOpen] = useState(false);
  const [topModalOpen, setTopModalOpen] = React.useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = React.useState(false);
  const [fileValue, setFileValue] = React.useState('');
  const [treeBinaryFile, setTreeBinaryFile] = useState('');
  const [fromPropertyNumber, setFromPropertyNumber] = React.useState();
  const [toPropertyNumber, setToPropertyNumber] = React.useState();
  const [showImportTreeDialog, setShowImportTreeDialog] = React.useState(false);
  const [showImportStatus, setshowImportStatus] = React.useState(false);
  const [importErrorDialog, setImportErrorDialog] = React.useState(false);

  const userPermissions = [];

  // const audio = new Audio(warningSound);
  const message =
    "Users in the row 'Added By User' will get logged out automatically from their mobile once you start importing. Please make sure they have synced all their offline data.";

  const [newState, setNewState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const {
    council,
    zones,
    wards,
    treeCensus,
    treeName,
    userByRoleID,
    activeTeams,
    editBaseColorTreesLog,
    deleteTreeCensusLog,
    updateQCStatusLog,
    pageInfo,
    loggedUser,
    showLoader,
    treeActiveType,
    exportCensusReport,
    isReportDownloadable,
    importCensusTrees,
    importCensusTreesLog,
    exceptionCensusTreesLog,
    properties,
    importStatus,
  } = useSelector((state) => ({
    council: state.council.activeCouncil,
    activeTeams: state.teams.activeTeams,
    zones: state.zones.activeZonesByCID,
    wards: state.wards.activeWardsByCID,
    treeCensus: state.treeCensus.treeCensus,
    userByRoleID: state.users.userByRoleID,
    treeName: state.treeName.activeTreeName,
    // editBaseColorTreesLog:state.baseColor.editBaseColorTreesLog,
    deleteTreeCensusLog: state.treeCensus.deleteTreeCensusLog,
    updateQCStatusLog: state.treeCensus.updateQCStatusLog,
    pageInfo: state.treeCensus.pageInfo,
    loggedUser: state.auth.loggedUser,
    showLoader: state.common.showLoader,
    exportCensusReport: state.treeCensus.exportCallLogs,
    isReportDownloadabletCallLogs: state.treeCensus.isReportDownloadable,
    importCensusTrees: state.treeCensus.importCensusTrees,
    importCensusTreesLog: state.treeCensus.importCensusTreesLog,
    exceptionCensusTreesLog: state.treeCensus.exceptionCensusTreesLog,
    properties: state.properties.properties,
    importStatus: state.treeCensus.importStatus,
  }));

  console.log('importStatus', importStatus?.data?.data);
  loggedUser.roles[0].permissions.map((item, index) => userPermissions.push(item.name));
  const userRole = loggedUser?.roles[0].slug;

  const { state } = useLocation();

  const councilArr = council?.find((val) => val.id === councilID);

  useEffect(() => {
    let cId = null;
    let wId = null;
    let zId = null;
    if (state?.councilId) {
      setCouncilId(state.councilId);
      setCouncilID(state.councilId);
      cId = state.councilId;
    }
    if (state?.wardId) {
      setWardId(state.wardId);
      wId = state.wardId;
    }
    if (state?.zoneId) {
      setZoneId(state.zoneId);
      zId = state.zoneId;
    }
    if (state?.pageNumber) {
      setPage(state.pageNumber);
    }
    if (state) {
      dispatch(GetTreeCensus(state.pageNumber, rowsPerPage, cId, zId, wId));
    }
    dispatch(GetUsersByRoleID(1, 3, 4, 5, 6, 7, 8));
    dispatch(GetActiveTreeName(1));
  }, []);

  useEffect(() => {
    setShowStatusList(true);
    if (loggedUser.roles[0].slug !== 'council') {
      dispatch(ImportStatus(statusPage, rowsPerPage));
    }
  }, [showImportStatus, statusPage, rowsPerPage, loggedUser]);

  const toggleDrawer = (anchor, open) => (event) => {
    setshowImportStatus(false);
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setNewState({ ...newState, [anchor]: open });
  };

  const firstRun = React.useRef(true);
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    dispatch(ShowLoader(false));
    dispatch(GetTreeCensus(activeTeams?.active_council_id, activeTeams?.active_zone_id, activeTeams?.active_ward_id));
    setCouncilID(activeTeams?.active_council_id);
    setCouncilName(activeTeams?.active_council_name);
    setZoneID(activeTeams?.active_zone_id);
    setWardID(activeTeams?.active_ward_id);
    const activeCouncilObj = {
      data: [{ id: activeTeams?.active_council_id, name: activeTeams?.active_council_name, status: 1 }],
    };
    const activeWardObj = {
      data: [{ id: activeTeams?.active_ward_id, name: activeTeams?.active_ward_name, status: 1 }],
    };
    const activeZoneObj = {
      data: [{ id: activeTeams?.active_ward_id, name: activeTeams?.active_zone_name, status: 1 }],
    };
    dispatch(SetActiveCouncil(activeCouncilObj));
    dispatch(SetActiveWards(activeWardObj));
    dispatch(SetActiveZones(activeZoneObj));
    // setSelectedIndex(0);
  }, [activeTeams]);

  const secondRun = React.useRef(true);
  useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    dispatch(ShowLoader(false));
    setShowList(true);
  }, [treeCensus]);

  const exceptionRun = React.useRef(true);
  useEffect(() => {
    if (exceptionRun.current) {
      exceptionRun.current = false;
      return;
    }
    console.log('BEFORE HANDLE');
    handleErroDialog();
  }, [exceptionCensusTreesLog]);

  const importRun = React.useRef(true);
  useEffect(() => {
    if (importRun.current) {
      importRun.current = false;
      return;
    }
    console.log('Hii');
  }, [importCensusTreesLog]);

  const forthRun = React.useRef(true);
  useEffect(() => {
    if (forthRun.current) {
      forthRun.current = false;
      return;
    }
    setShowList(true);
    dispatch(
      GetTreeCensus(
        page,
        rowsPerPage,
        councilID,
        zoneID,
        wardID,
        addedByForm,
        treeNameFrom,
        heightFrom,
        heightTo,
        girthFrom,
        girthTo,
        formDate,
        toDate
      )
    );
  }, [deleteTreeCensusLog]);
  console.log('LOGGED USER', loggedUser);

  useEffect(() => {
    if (loggedUser?.roles[0]?.slug === 'get-tree-census') {
      dispatch(GetMyActiveTeam());
      dispatch(ShowLoader(true));
      dispatch(GetUsersByRoleID(1, 6, 8));
    } else {
      dispatch(GetActiveCouncil(1));
      dispatch(GetActiveWards(1));
      dispatch(GetActiveZones(1));
    }
  }, []);

  useEffect(() => {
    if (loggedUser?.roles[0]?.slug === 'get-tree-census') {
      dispatch(GetMyActiveTeam());
      dispatch(ShowLoader(true));
      dispatch(GetUsersByRoleID(1, 3, 4, 5, 6, 7, 8));
      // dispatch(GetTreeName())
    } else {
      dispatch(GetUsersByRoleID(1, 3, 4, 5, 6, 7, 8));
      // dispatch(GetTreeName())
      dispatch(GetActiveCouncil(1));
      dispatch(GetActiveWards(1));
      dispatch(GetActiveZones(1));
    }
  }, []);
  // useEffect(()=>{get-tree-census
  //   dispatch(ShowLoader(true));
  //   dispatch(GetActiveCouncil(1));
  //   dispatch(GetUsersByRoleID(1, 6, 8));
  //   // dispatch(GetBaseColorTreeById(1));
  // },[])

  useEffect(() => {
    if (pageInfo) {
      setCount(pageInfo?.total);
    }
  }, [pageInfo]);

  const councilRun = React.useRef(true);
  useEffect(() => {
    if (councilRun.current) {
      councilRun.current = false;
      return;
    }
    if (loggedUser.roles[0].slug === 'council') {
      setCouncilID(council?.[0].id);
      dispatch(GetPropertyByCouncilZoneWard(council?.[0].id, null, null));
      dispatch(GetActiveZonesByCouncilId(1, council?.[0].id));
      dispatch(GetActiveWardsByCouncilId(1, council?.[0].id));
    }
  }, [council]);

  const handleNewUserClick = () => {
    setDialogData(null);
    setOpen(!open);
  };

  const handleViewOpen = (images) => {
    setViewOpen(!viewOpen);
    setImageList(images || []);
  };

  const handleQcDialog = (id) => {
    setQcDialogOpen(!qcDialogOpen);
    setTreeCensusId(id);
  };

  const handleErroDialog = () => {
    // setErrorDialogOpen(!errorDialogOpen);
    setShowImportTreeDialog(false);
  };

  const handleErrorStatusDialog = () => {
    setImportErrorDialog(open);
  };

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleTreeTypehange = (e) => {
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      // [name]: value,
      treeType: e.target.value,
    }));
  };
  // const [getTreeNameId ,setTreeNameId] = useState('')
  const [getTreeNameId, setSelectedTreeNameId] = useState('');
  const handleTreeNameDataChange = (e) => {
    dispatch(
      SetNewAlert({
        msg: 'Name changes will reflect only after the approval from Data Analyst',
        alertType: 'success',
      })
    );
    // alert("Name changes will reflect only after the approval from Data Analyst")
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      // [name]: value,
      treeName: e.target.value,
    }));
    setBoticalTreeName(e.target.value);
  };
  const setBoticalTreeName = (getTreeId) => {
    treeName.map((obj) => {
      if (obj.id === getTreeId) {
        inputField.treeBName = obj?.botanical_name;
      }
      return null;
    });
  };
  const [checkTreeNumber, setCheckTreeNumber] = useState(false);
  const handleTreeNumberDataChange = (e) => {
    console.log('TREE NUMBER CHANGED');
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
      treeNumber: e.target.value,
    }));
    if (e.target.value) {
      setCheckTreeNumber(true);
      // if (inputField.treeNumber) {
      // dispatch(SetNewAlert({
      //   msg: "Tree number changes will reflect only after the approval from Data Analyst",
      //   alertType: "success",
      // }));
      // }
    } else {
      setCheckTreeNumber(false);
    }
  };
  const handleTreeDiseasechange = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      // [name]: value,
      treeDisease: e.target.value,
    }));
  };
  const handleTreeConditionchange = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      // [name]: value,
      treeCondition: e.target.value,
    }));
  };
  const [inputField, setInputField] = useState({
    locationType: '',
    treeNumber: '',
    treeType: '',
    treeName: '',
    treeBName: '',
    girth: '',
    height: '',
    treeCanopy: '',
    treeCondition: '',
    plantationDate: '',
    actionToBeTaken: '',
    censunTreeId: '',
  });
  const [getTreeCensusId, setTreeDataId] = React.useState();
  const handleCensusViewDetailsDialog = (data) => {
    if (!data) {
      setCheckTreeNumber(false);
      setViewCensusDetails(!viewCensusDetails);
    } else {
      setCheckTreeNumber(false);
      setDialogData(data);
      setTreeDataId(data.id);
      setInputField({
        locationType: data?.location_type?.location_type,
        treeNumber: data?.tree_number,
        treeType: data?.tree_type.id,
        treeName: data?.tree_name.id,
        treeBName: data?.tree_name.botanical_name,
        girth: data?.girth,
        height: data?.height,
        treeCanopy: data?.canopy,
        treeDisease: data?.tree_disease?.id !== null ? data?.tree_disease?.id : '',
        treeCondition: data?.tree_condition?.id,
        plantationDate: moment(data?.plantation_date).format('YYYY-MM-DD'),
        actionToBeTaken: data?.action_need ? data?.action_need : '-',
        censunTreeId: data?.id,
      });
      setViewCensusDetails(!viewCensusDetails);
    }
  };
  const reLast = /-\d+$/;
  const [getCheckAlert, CheckAlert] = useState(false);
  const saveTreeDetails = () => {
    if (checkTreeNumber === true) {
      dispatch(
        SetNewAlert({
          msg: 'Tree number changes will reflect only after the approval from Data Analyst',
          alertType: 'success',
        })
      );
      CheckAlert(true);
    }
    setTimeout(() => {
      const reqObj = {
        tree_type_id: inputField.treeType,
        // "tree_name_id": dialogData?.tree_name?.id===inputField.treeName?null:inputField.treeName,
        tree_disease_id: inputField.treeDisease,
        plantation_date:
          moment(inputField.plantationDate).format('YYYY/MM/DD') === 'Invalid date'
            ? null
            : moment(inputField.plantationDate).format('YYYY/MM/DD'),
        // "tree_number": dialogData?.tree_number===inputField.treeNumber?null:inputField.treeNumber,
        action_need: inputField.actionToBeTaken,
        girth: inputField.girth,
        height: inputField.height,
        canopy: inputField.treeCanopy,
        tree_condition_id: inputField.treeCondition,
      };
      if (dialogData?.tree_name?.id !== inputField.treeName) {
        reqObj.tree_name_id = inputField.treeName;
      }
      if (
        dialogData?.tree_number !==
        `${dialogData?.tree_number?.replace(reLast, '')}-${
          inputField.treeNumber.split('-')[inputField.treeNumber.split('-').length - 1]
        }`
      ) {
        reqObj.tree_number = `${dialogData?.tree_number?.replace(reLast, '')}-${inputField.treeNumber}`;
      }
      dispatch(UpdateViewCensusTree(reqObj, getTreeCensusId));
      setTimeout(() => {
        setViewCensusDetails(!viewCensusDetails);
      }, 3000);
    }, 3000);
    // }
  };

  const handleCensusWardsDetailsDialog = (data) => {
    setViewWardDetails(!viewWardDetails);
    setDialogData(data);
  };

  const handleGenerateNoticeDialog = () => {
    setGenerateNoticeDialogOpen(!generateNoticeDialogOpen);
  };

  const handleQcSubmit = (data, id) => {
    const obj = {};

    if (data) {
      obj.qc_status = 'Unapproved';
      obj.qc_remark_id = data;
    } else {
      obj.qc_status = 'Approved';
    }

    dispatch(UpdateQCStatusOfTreeCensus(id, obj));
  };

  const handleEdit = (data) => {
    setDialogData(data);
    setOpen(!open);
  };

  const handleDelete = (e) => {
    dispatch(DeleteCensusTrees(e.id, 0));
    setTopModalOpen(!topModalOpen);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setShowList(false);
    dispatch(
      GetTreeCensus(
        newPage,
        rowsPerPage,
        councilID,
        zoneID,
        wardID,
        addedByForm,
        treeNameFrom,
        heightFrom,
        heightTo,
        girthFrom,
        girthTo,
        formDate,
        toDate
      )
    );
  };

  const handleChangeStatus = (event, newPage) => {
    setStatusPage(newPage);
    setShowStatusList(false);
    dispatch(ImportStatus(newPage, rowsPerPage));
  };

  const handleChangeRowsPerPage = (event) => {
    setShowList(false);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    if (search) {
      dispatch(SearchTreeCensus(1, parseInt(event.target.value, 10), coucilId, zoneId, wardId, searchValue));
    } else {
      dispatch(GetTreeCensus(1, parseInt(event.target.value, 10), coucilId, zoneId, wardId));
    }
  };
  function handleClick(event) {
    event.preventDefault();
  }

  let timer = null;
  const filterByName = (event) => {
    const { value } = event.currentTarget;
    clearTimeout(timer);
    // Wait for X ms and then process the request
    timer = setTimeout(() => {
      if (value) {
        dispatch(SearchTreeCensus(1, rowsPerPage, coucilId, zoneId, wardId, value));
        setSearch(true);
        setShowList(false);
        setPage(1);
        setSearchValue(value);
      } else {
        dispatch(GetTreeCensus(1, rowsPerPage, coucilId, zoneId, wardId));
        setShowList(false);
        setSearch(false);
        setPage(1);
        setSearchValue('');
      }
    }, 1000);
  };
  const requestForWithoutFilter = () => {
    dispatch(
      SetNewAlert({
        msg: 'Please Select Filter First',
        alertType: 'danger',
      })
    );
  };

  const requestForWithoutReportFilter = () => {
    dispatch(
      SetNewAlert({
        msg: 'Please Select the Council,Zone & Ward Filter',
        alertType: 'danger',
      })
    );
  };

  const controlStyle = (base) => ({
    ...base,
    boxShadow: 'none',
    borderColor: '#cccccc',
    '&:hover': {
      borderColor: '#cccccc',
    },
  });
  const [exportButtonPressed, setExportButtonPressed] = useState(false);

  useEffect(() => {
    if (exportButtonPressed) {
      DownloadReportExcel();
      setExportButtonPressed(false);
    }
  }, [isReportDownloadable, exportCensusReport]);
  const header = [
    '#',
    'Zone',
    'Ward',
    'Tree Number',
    'Latitude',
    'Longitude',
    'Location Accuracy',
    'Location Type',
    'Property Type',
    'Property Number',
    'Owner Name',
    'Tenant Name',
    'Address',
    'Area',
    'Tree Type',
    'Name',
    'Botanical Name',
    'Girth',
    'Height',
    'Canopy',
    'Condition',
    'Tree Disease',
    'Plantation Date',
    'Action Need',
    'Added By User',
    'Added On',
    'Qc Status',
    'Qc By User',
    'Qc Date',
    'Remark',
  ];
  function DownloadReportExcel() {
    const value1 = [];
    exportCensusReport?.map((option, index) => {
      const value2 = [index + 1];
      value2.push(option.zone);
      value2.push(option.ward);
      value2.push(option.tree_number);
      value2.push(option.latitude);
      value2.push(option.longitude);
      // value2.push(option.location)
      value2.push(option.location_accuracy);
      value2.push(option.location_type);
      value2.push(option.property_type);
      value2.push(option.property_number);
      value2.push(option.owner_name);
      value2.push(option.tenant_name);
      value2.push(option.address);
      value2.push(option.area);
      value2.push(option.tree_type);
      value2.push(option.name);
      value2.push(option.botanical_name);
      value2.push(option.girth);
      value2.push(option.height);
      value2.push(option.canopy);
      value2.push(option.condition);
      value2.push(option.tree_disease);
      value2.push(option.plantation_date);
      value2.push(option.action_need);
      value2.push(option.added_by_user);
      value2.push(option.added_on);
      value2.push(option.qc_status);
      value2.push(option.qc_by_user);
      value2.push(option.qc_date);
      value2.push(option.remark);
      value1.push(value2);
      return null;
    });
    downloadExcel({
      fileName: `${councilName}-Census Reports ${formDate} to ${toDate}`,
      sheet: 'Census Report',
      tablePayload: {
        header,
        // accept two different data structures
        body: value1,
      },
    });
  }

  const handleAlertDialogAnswer = (answer) => {
    if (answer) {
      // audio.loop = false;
      const formData = new FormData();
      formData.append('file', treeBinaryFile);
      dispatch(ImportCensusTrees(formData));
    }
    setAlertDialogOpen(!alertDialogOpen);
    setFileValue('');
    setTreeBinaryFile('');
    // audio.loop = false;
  };

  const handleImportTree = () => {
    console.log('clikkk1998');
    setshowImportStatus(false);
    setShowImportTreeDialog(!showImportTreeDialog);
  };

  const handleImportStatus = () => {
    setshowImportStatus(true);
  };

  const importTrees = (e) => {
    const i = parseInt(Math.floor(Math.log(e.target.files[0].size) / Math.log(1024)), 10);
    const validExtensions = ['.csv', '.xls', '.xlsx'];
    const fileExtension = e.target.files[0].name.split('.')[1];

    const selectedFile = e.target.files[0];
    setTreeBinaryFile(selectedFile);
    setAlertDialogOpen(true);
  };

  const exportCensusReports = () => {
    setshowImportStatus(false);
    if (councilID && formDate && toDate) {
      const requestObj = {
        type: 'census',
        from_date: formDate.split('-').reverse().join('-'),
        to_date: toDate.split('-').reverse().join('-'),
        council_id: councilID,
      };
      if (zoneID) {
        requestObj.zone_id = zoneID;
      }
      if (wardID) {
        requestObj.ward_id = wardID;
      }
      if (addedBy) {
        requestObj.user_id = addedBy;
      }
      if (girthFrom) {
        requestObj.start_girth = girthFrom;
      }
      if (girthTo) {
        requestObj.end_girth = girthTo;
      }
      if (heightFrom) {
        requestObj.start_height = heightFrom;
      }
      if (heightTo) {
        requestObj.end_height = heightTo;
      }
      if (treeNameFrom) {
        requestObj.tree_names_id = treeNameFrom;
      }
      dispatch(exportCensusReportsApi(requestObj));
      setExportButtonPressed(true);
    } else {
      requestForWithoutFilter();
    }
  };
  const requestForReport = () => {
    const requestObj = {
      type: 'census',
      from_date: formDate.split('-').reverse().join('-'),
      to_date: toDate.split('-').reverse().join('-'),
      council_id: councilID,
    };
    if (zoneID) {
      requestObj.zone_id = zoneID;
    }
    if (wardID) {
      requestObj.word_id = wardID;
    }
    if (addedBy) {
      requestObj.user_id = addedBy;
    }
    if (girthFrom) {
      requestObj.start_girth = girthFrom;
    }
    if (girthTo) {
      requestObj.end_girth = girthTo;
    }
    if (heightFrom) {
      requestObj.start_height = heightFrom;
    }
    if (heightTo) {
      requestObj.end_height = heightTo;
    }
    if (treeNameFrom) {
      requestObj.tree_names_id = treeNameFrom;
    }

    dispatch(GetReportRequest(requestObj));
  };

  const handleCouncilChange = (e) => {
    setCouncilID(e.target.value);
    setZoneID('');
    setWardID('');
    dispatch(GetActiveZonesByCouncilId(1, e.target.value));
    dispatch(GetActiveWardsByCouncilId(1, e.target.value));

    council.map((value, index) => {
      if (value.id === e.target.value) {
        setCouncilName(value.name);
      }
      return null;
    });
  };
  const [selectedError, setSelectedError] = useState(null);
  const HandleViewError = (errorData) => {
    setSelectedError(errorData);
    setImportErrorDialog(true);
  };

  const handleStatusDialog = () => {
    setImportErrorDialog(false);
  };

  const handleZoneChange = (event) => {
    setZoneID(event.target.value);
    setFromPropertyNumber('');
    setToPropertyNumber('');
    dispatch(GetPropertyByCouncilZoneWard(councilID, event.target.value, wardID));
  };

  const handleWardChange = (event) => {
    setWardID(event.target.value);
    setFromPropertyNumber('');
    setToPropertyNumber('');
    dispatch(GetPropertyByCouncilZoneWard(councilID, zoneID, event.target.value));
  };

  const handleAddedByChange = (event) => {
    setAddedBy(event.target.value);
  };

  const handleTreeNameChange = (event) => {
    setTreeNameId(event.target.value);
  };

  const handleSelect = (e) => {
    setTreeNameInput(e);
    console.log('data', e);
  };

  const generateNotices = () => {
    if (councilID === '' || zoneID === '' || wardID === '') {
      requestForWithoutReportFilter();
    } else {
      dispatch(DeficientTreeNoticeAction(councilID, zoneID, wardID));
    }
  };

  const useStyles = makeStyles({
    icon: {
      fill: '#214C50',
    },
  });

  const FilterSchema = Yup.object().shape({
    councilForm: Yup.string().required('Please select council'),
    toDateForm: Yup.string().required('Please select End Date'),
    fromDateForm: Yup.string().required('Please select Start Date'),
    heightFrom: Yup.string().matches(/^[0-9\b]+$/, 'Only Numbers are allowed  '),
    heightTo: Yup.string().when('heightFrom', {
      is: (heightFrom) => heightFrom >= 0,
      then: Yup.string()
        .required('Height from is required')
        .matches(/^[0-9\b]+$/, 'Only Numbers are allowed  '),
      otherwise: Yup.string().matches(/^[0-9\b]+$/, 'Only Numbers are allowed  '),
    }),

    girthFrom: Yup.string().matches(/^[0-9\b]+$/, 'Only Numbers are allowed  '),
    girthTo: Yup.string().when('girthFrom', {
      is: (girthFrom) => girthFrom >= 0,
      then: Yup.string()
        .required('Girth from is required')
        .matches(/^[0-9\b]+$/, 'Only Numbers are allowed  '),
      otherwise: Yup.string().matches(/^[0-9\b]+$/, 'Only Numbers are allowed  '),
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      councilForm: councilArr?.id || councilID,
      wardForm: wardID || '',
      zoneForm: zoneID || '',
      addedByForm: addedBy || '',
      treeNameFrom: treeNameInput?.value || '',
      heightFrom: heightFromId || '',
      heightTo: heightToId || '',
      girthFrom: girthFromId || '',
      girthTo: girthToId || '',
      toDateForm: councilArr?.project_end_date || todayDate,
      fromDateForm: councilArr?.project_start_date || todayDate,
    },
    validationSchema: FilterSchema,
    onSubmit: (value) => {
      console.log('in submit', value.treeNameFrom);
      setAddedByForm(value.addedByForm);
      setTreeNameFrom(value.treeNameFrom);
      setHeightFrom(value.heightFrom);
      setHeightTo(value.heightTo);
      setGirthFrom(value.girthFrom);
      setGirthTo(value.girthTo);

      setFromDate(value.fromDateForm);
      setToDate(value.toDateForm);

      if (toPropertyNumber?.value && !fromPropertyNumber?.value) {
        dispatch(
          SetNewAlert({
            msg: 'Please select from property number',
            alertType: 'danger',
          })
        );
      } else {
        setNewState({ ...newState, right: false });
        dispatch(ShowLoader(true));
        dispatch(ShowLoader(true));
        setPage(1);
        dispatch(
          GetTreeCensus(
            1,
            rowsPerPage,
            councilID,
            zoneID,
            wardID,
            value.addedByForm,
            value.treeNameFrom,
            value.heightFrom,
            value.heightTo,
            value.girthFrom,
            value.girthTo,
            value.fromDateForm,
            value.toDateForm,
            fromPropertyNumber?.value,
            toPropertyNumber?.value
          )
        );
        setButtonClick(false);
      }
    },
  });

  const classes = useStyles();
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const [optionData, setOptionData] = useState();
  const [optionWardData, setWardOptionData] = useState();
  const handleDeleteData = (e) => {
    setTopModalOpen(!topModalOpen);
    setOptionData(e);
    setWardOptionData(e);
  };
  const handleWardByChange = (event) => {
    const {
      target: { value },
    } = event;
    setAddedBy(typeof value === 'string' ? value.split(',') : value);
  };

  const handleFromPropertyNumber = (e) => {
    setFromPropertyNumber(e);
  };

  const handleToPropertyNumber = (e) => {
    setToPropertyNumber(e);
  };

  if (alertDialogOpen) {
    // audio.loop = true;
    // audio.play();
  }

  console.log('INPUT FIELD', inputField);
  return (
    <Page title="Base Color">
      <FullLoader showLoader={showLoader} />
      <Container>
        <ImportErrorDialog isOpen={importErrorDialog} handleClose={handleStatusDialog} data={selectedError} />
        {alertDialogOpen ? (
          <WarningMessageDialog
            isOpenConfirm={alertDialogOpen}
            message={message}
            handleClose={(answer) => handleAlertDialogAnswer(answer)}
          />
        ) : null}
        {showImportTreeDialog ? (
          <ImportTreeDialog isOpen={showImportTreeDialog} handleClose={handleImportTree} />
        ) : null}
        {errorDialogOpen ? (
          <ImportErrorDialog isOpen={showImportTreeDialog} handleClose={handleErroDialog} data={importCensusTrees} />
        ) : null}

        {open ? <BaseColorDialog isOpen={open} handleClose={handleNewUserClick} data={dialogData} /> : null}

        {viewOpen ? <ViewImageDialog isOpen={viewOpen} handleClose={handleViewOpen} data={imageList} /> : null}

        {qcDialogOpen ? (
          <QcStatusDialog
            isOpen={qcDialogOpen}
            baseColorId={treeCensusId}
            handleClose={() => handleQcDialog(null)}
            handleSubmit={(data, id) => handleQcSubmit(data, id)}
          />
        ) : null}

        {viewCensusDetails ? (
          <CencusViewDetailsDialog
            isOpen={viewCensusDetails}
            treeType={inputField.treeType}
            treeNumber={inputField.treeNumber}
            treeName={inputField.treeName}
            treeBName={inputField.treeBName}
            girth={inputField.girth}
            height={inputField.height}
            treeCanopy={inputField.treeCanopy}
            treeCondition={inputField.treeCondition}
            treeDisease={inputField.treeDisease}
            plantationDate={inputField.plantationDate}
            handleTreeNumberDataChange={(e) => handleTreeNumberDataChange(e)}
            handleTreeTypehange={(e) => handleTreeTypehange(e)}
            handleClose={() => handleCensusViewDetailsDialog()}
            handleTreeNameChange={(e) => {
              handleTreeNameDataChange(e);
            }}
            handleTreeConditionchange={(e) => {
              handleTreeConditionchange(e);
            }}
            handleTreeDiseasechange={(e) => {
              handleTreeDiseasechange(e);
            }}
            inputsHandler={inputsHandler}
            saveTreeDetails={() => saveTreeDetails()}
            data={dialogData}
          />
        ) : null}
        {viewWardDetails ? (
          <CencusViewWardDialog
            isOpen={viewWardDetails}
            addedBy={addedBy}
            onChange={(e) => {
              handleWardByChange(e);
            }}
            wards={wards}
            handleClose={() => handleCensusWardsDetailsDialog()}
            data={dialogData}
          />
        ) : null}

        {generateNoticeDialogOpen ? (
          <GenerateNoticeDialog
            isOpen={generateNoticeDialogOpen}
            handleClose={handleGenerateNoticeDialog}
            data={null}
          />
        ) : null}

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs
              aria-label="breadcrumb"
              style={{ color: '#000000', fontWeight: 900, fontSize: '20px' }}
              separator=":">
              <Typography variant="h4" gutterBottom style={{ color: '#000000' }}>
                Tree Data
              </Typography>
              <Typography variant="h4" gutterBottom style={{ color: '#000000', fontWeight: 400 }}>
                Census
              </Typography>
            </Breadcrumbs>
            <Typography variant="h6" style={{ fontSize: '18px', fontWeight: '400', marginTop: '-8px' }}>
              It is showing list of trees with its details
            </Typography>
          </div>
          <div className="actionContainer" style={{ flexDirection: 'row-reverse' }}>
            <Button
              onClick={toggleDrawer('right', true)}
              variant="contained"
              to="#"
              sx={{ fontSize: '10px', fontWeight: '700', height: '27px' }}
              startIcon={<Iconify icon="eva:funnel-fill" />}>
              Filters
            </Button>
            {userPermissions.includes('view-census-report') ? (
              <Button
                variant="contained"
                component="label"
                onClick={handleImportTree}
                style={{ fontWeight: 700, fontSize: '10px', backgroundColor: '#E8762F', color: '#fff' }}>
                Import Trees
              </Button>
            ) : null}

            {userPermissions.includes('view-census-report') ? (
              <Button
                variant="contained"
                component="label"
                onClick={handleImportStatus}
                style={{ fontWeight: 700, fontSize: '10px', backgroundColor: '#E8762F', color: '#fff' }}>
                Import Status
              </Button>
            ) : null}
            {userPermissions.includes('view-census-report') ? (
              <Button
                variant="contained"
                // className="desktop-button-"
                onClick={exportCensusReports}
                // onClick={() => (buttonClick ? requestForWithoutFilter() : requestForReport())}
                // onClick={handleExportCallLogReport}
                style={{ fontWeight: 700, fontSize: '10px', backgroundColor: '#E8762F', color: '#fff' }}>
                Export Report
              </Button>
            ) : null}
            {userPermissions.includes('create-request-deficient-trees') ? (
              <Button
                to="#"
                onClick={() => {
                  handleGenerateNoticeDialog();
                }}
                style={{ fontWeight: 700, fontSize: '10px', backgroundColor: '#B42B3E', color: '#fff' }}
                // className="desktop-button-"
              >
                Generate Notices
              </Button>
            ) : null}
          </div>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Box sx={{ height: '100' }}>
            <Drawer
              sx={{
                '& .MuiDrawer-paper': {
                  width: '300px',
                  maxWidth: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              }}
              anchor={'right'}
              open={newState.right}
              onClose={toggleDrawer('right', false)}>
              <div>
                <Grid container spacing={1} style={{ width: '90%', marginLeft: '5%', marginRight: '5%' }}>
                  <Grid item xs={12}>
                    <TextField
                      select
                      disabled={loggedUser.roles[0].slug === 'council'}
                      id="councilForm"
                      label="Council*"
                      displayEmpty
                      value={councilID}
                      style={{ width: '100%' }}
                      size="small"
                      onChange={(e) => {
                        handleCouncilChange(e);
                        formik.handleChange(e);
                      }}
                      renderValue={(selected) => {
                        if (selected?.length === 0) {
                          return <em>Select Council*</em>;
                        }
                      }}
                      // onChange={handleCouncilChange}
                      error={Boolean(touched.councilForm && errors.councilForm)}
                      helperText={touched.councilForm && errors.councilForm}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}>
                      <MenuItem disabled value="">
                        <em>Select Council*</em>
                      </MenuItem>
                      {council?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      select
                      disabled={loggedUser?.roles[0]?.slug === 'qc_base_color_offsite'}
                      id="zoneForm"
                      label="Zone"
                      displayEmpty
                      value={zoneID}
                      style={{ width: '100%', marginTop: 5 }}
                      size="small"
                      onChange={(e) => {
                        handleZoneChange(e);
                        formik.handleChange(e);
                      }}
                      // onChange={handleZoneChange}
                      // error={Boolean(touched.zoneForm && errors.zoneForm)}
                      // helperText={touched.zoneForm && errors.zoneForm}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}>
                      <MenuItem disabled value="">
                        <em>Select Zone*</em>
                      </MenuItem>
                      {councilID
                        ? zones?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))
                        : null}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      select
                      disabled={loggedUser?.roles[0]?.slug === 'qc_base_color_offsite'}
                      id="wardForm"
                      label="Ward"
                      displayEmpty
                      value={wardID}
                      style={{ width: '100%', marginTop: 5 }}
                      size="small"
                      onChange={(e) => {
                        handleWardChange(e);
                        formik.handleChange(e);
                      }}
                      // onChange={handleWardChange}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}>
                      <MenuItem disabled value="">
                        <em>Select Ward*</em>
                      </MenuItem>
                      {councilID
                        ? wards?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))
                        : null}
                    </TextField>
                  </Grid>

                  {loggedUser.roles[0].slug === 'council' ? (
                    ''
                  ) : (
                    <Grid item xs={12}>
                      <TextField
                        select
                        id="addedBy"
                        label="Added By"
                        displayEmpty
                        value={addedBy}
                        style={{ width: '100%', marginTop: 5 }}
                        size="small"
                        // placeholder='*Status'
                        onChange={(e) => {
                          handleAddedByChange(e);
                          formik.handleChange(e);
                        }}
                        // onChange={handleAddedByChange}
                        // {...getFieldProps("addedByForm")}
                        inputProps={{
                          classes: {
                            icon: classes.icon,
                          },
                        }}>
                        <MenuItem disabled value="">
                          <em>Select Added By</em>
                        </MenuItem>
                        <MenuItem value="">
                          <em>----Null----</em>
                        </MenuItem>
                        {userByRoleID?.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.first_name} {option.last_name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Select
                      id="treeNameFrom"
                      placeholder="Select Tree Name"
                      label="Tree Name"
                      value={treeNameInput}
                      className="react-select-container"
                      componentsProps={{
                        listbox: {
                          sx: { backgroundColor: '#000' },
                        },
                      }}
                      style={{ borderColor: 'red' }}
                      clearable={false}
                      options={treeName?.map((item) => {
                        return { value: item.id, label: item.name };
                      })}
                      // eslint-disable-next-line react/jsx-no-bind
                      onChange={handleSelect}
                    />
                  </Grid>
                  {loggedUser.roles[0].slug === 'council' ? (
                    <>
                      <Grid item xs={12}>
                        <Select
                          id="fromPropertyNumber"
                          placeholder="From Property Number"
                          label="From Property Number"
                          value={fromPropertyNumber}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              minHeight: '50px',
                            }),
                          }}
                          className="react-select-container"
                          componentsProps={{
                            listbox: {
                              sx: { backgroundColor: '#000' },
                            },
                          }}
                          clearable={false}
                          options={properties?.map((item) => {
                            return {
                              value: item.property_number,
                              label: `${item.property_number} -  ${item.owner_name}`,
                            };
                          })}
                          // eslint-disable-next-line react/jsx-no-bind
                          onChange={handleFromPropertyNumber}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Select
                          id="toPropertyNumber"
                          placeholder="To Property Number"
                          label="To Property Number"
                          value={toPropertyNumber}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              minHeight: '50px',
                            }),
                          }}
                          className="react-select-container"
                          componentsProps={{
                            listbox: {
                              sx: { backgroundColor: '#000' },
                            },
                          }}
                          clearable={false}
                          options={properties?.map((item) => {
                            return {
                              value: item.property_number,
                              label: `${item.property_number} - ${item.owner_name}`,
                            };
                          })}
                          // eslint-disable-next-line react/jsx-no-bind
                          onChange={handleToPropertyNumber}
                        />
                      </Grid>
                    </>
                  ) : null}
                  <Grid container spacing={2} style={{ zIndex: 0 }}>
                    <Grid item xs={6}>
                      <TextField
                        id="heightFrom"
                        type="text"
                        autoComplete="Height Start"
                        label="Height Start"
                        value={values.heightFrom}
                        style={{ width: '100%', marginTop: 10, marginLeft: 10 }}
                        size="small"
                        error={Boolean(touched.heightFrom && errors.heightFrom)}
                        helperText={touched.heightFrom && errors.heightFrom}
                        {...getFieldProps('heightFrom')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="heightTo"
                        type="text"
                        autoComplete="Height Start"
                        // placeholder="Height Start*"
                        label="Height End"
                        style={{ width: '100%', marginTop: 10 }}
                        size="small"
                        value={values.heightTo}
                        error={Boolean(touched.heightTo && errors.heightTo)}
                        helperText={touched.heightTo && errors.heightTo}
                        {...getFieldProps('heightTo')}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ zIndex: 0 }}>
                    <Grid item xs={6}>
                      <TextField
                        id="girthFrom"
                        type="text"
                        autoComplete="Tree No"
                        placeholder="Tree No*"
                        label="Girth Start"
                        style={{ width: '100%', marginTop: 10, marginLeft: 10 }}
                        size="small"
                        value={values.girthFrom}
                        error={Boolean(touched.girthFrom && errors.girthFrom)}
                        helperText={touched.girthFrom && errors.girthFrom}
                        {...getFieldProps('girthFrom')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="girthTo"
                        type="text"
                        autoComplete="Girth End"
                        label="Girth End"
                        style={{ width: '100%', marginTop: 10 }}
                        size="small"
                        value={values.girthTo}
                        error={Boolean(touched.girthTo && errors.girthTo)}
                        helperText={touched.girthTo && errors.girthTo}
                        {...getFieldProps('girthTo')}
                      />
                    </Grid>
                  </Grid>
                  {councilID ? (
                    <>
                      {loggedUser.roles[0].slug === 'council' ? (
                        ''
                      ) : (
                        <>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              id="fromDate"
                              type="date"
                              label="Start Date*"
                              margin="normal"
                              name="fromDateForm"
                              style={{ width: '100%', marginTop: 5, zIndex: 0 }}
                              size="small"
                              // label="Plantation Date"
                              // value={values.fromDateForm || ''}
                              error={Boolean(touched.fromDateForm && errors.fromDateForm)}
                              helperText={touched.fromDateForm && errors.fromDateForm}
                              // }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{ max: todayDate }}
                              {...getFieldProps('fromDateForm')}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ zIndex: 0 }}>
                            <TextField
                              fullWidth
                              id="toDate"
                              label="End Date*"
                              type="date"
                              margin="normal"
                              name="toDateForm"
                              style={{ width: '100%', marginTop: 5 }}
                              size="small"
                              error={Boolean(touched.toDateForm && errors.toDateForm)}
                              helperText={touched.toDateForm && errors.toDateForm}
                              // }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{ max: todayDate }}
                              {...getFieldProps('toDateForm')}
                            />
                          </Grid>
                        </>
                      )}
                    </>
                  ) : (
                    ''
                  )}
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    style={{ width: '60%', marginLeft: '20%', marginRight: '20%', marginTop: 5 }}>
                    Apply
                  </Button>
                </Grid>
              </div>
            </Drawer>
          </Box>
        </Stack>

        <Card>
          <Scrollbar>
            {!showImportStatus ? (
              <TableContainer sx={{ minWidth: 800 }}>
                <Table size="small" aria-label="a dense table">
                  <UserListHead headLabel={userRole === 'council' ? COUNCIL_HEAD : TABLE_HEAD} />
                  {!showList && (
                    <TableRow>
                      <TableCell align="center" colSpan={8} fontWeight={700} sx={{ p: 5 }}>
                        Please select council to get census data
                      </TableCell>
                    </TableRow>
                  )}
                  <TableBody>
                    {showList &&
                      treeCensus?.data?.map((option, index) => {
                        return (
                          <TableRow hover>
                            <TableCell align="left">
                              <b>{(page - 1) * rowsPerPage + (index + 1)}</b>
                            </TableCell>
                            <TableCell align="left">
                              <b>{option.tree_number ? option.tree_number : '-'}</b>
                            </TableCell>
                            <TableCell align="left">{option.tree_name?.name}</TableCell>
                            <TableCell align="left">
                              {option.added_by?.first_name} {option.added_by?.last_name}{' '}
                            </TableCell>
                            <TableCell align="left">{option.added_on_date}</TableCell>
                            {/* <TableCell align="left">{option.age}</TableCell> */}
                            <TableCell align="left">{option.location_accuracy}</TableCell>
                            {userRole === 'council' ? null : (
                              <TableCell align="left">
                                {option.qc_status === 'Pending' ? (
                                  <StatusPendngButton qcStatus={option.qc_status} />
                                ) : (
                                  ''
                                )}
                                {option.qc_status === 'Approved' ? (
                                  <StatusApprovedButton qcStatus={option.qc_status} />
                                ) : (
                                  ''
                                )}
                                {option.qc_status === 'Unapproved' ? (
                                  <StatusUnapprovedButton qcStatus={option.qc_status} />
                                ) : (
                                  ''
                                )}
                              </TableCell>
                            )}
                            {userRole === 'council' ? null : (
                              <TableCell align="left">
                                {option.referred_to_expert === 1 ? (
                                  <b style={{ color: 'green' }}>Yes</b>
                                ) : (
                                  <b style={{ color: '#E8762F' }}>No</b>
                                )}
                              </TableCell>
                            )}

                            <TableCell align="right">
                              <TreeCensusMenu
                                permissions={userPermissions}
                                treeCensusId={option.id}
                                treeCensusName={option.property?.owner_name}
                                qcStatus={option.qc_status}
                                councilId={coucilId}
                                zoneId={zoneId}
                                wardId={wardId}
                                pageNumber={page}
                                handleEdit={() => handleEdit(option)}
                                handleApprove={() => handleQcSubmit(null, option.id)}
                                handleQcDialog={() => handleQcDialog(option.id)}
                                handleCensusViewDialog={() => handleCensusViewDetailsDialog(option)}
                                handleCensusWardsDialog={() => handleCensusWardsDetailsDialog(option)}
                                // handleDelete={() => handleDelete(option)}
                                handleDelete={() => handleDeleteData(option)}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <TableContainer sx={{ minWidth: 400 }}>
                <Table size="small" aria-label="a dense table">
                  <UserListHead headLabel={userRole === 'council' ? COUNCIL_HEAD : TABLE_HEADTWO} />
                  {!showStatusList && (
                    <TableRow>
                      <TableCell align="center" colSpan={8} fontWeight={700} sx={{ p: 5 }}>
                        Please select import status
                      </TableCell>
                    </TableRow>
                  )}
                  <TableBody>
                    {showStatusList &&
                      importStatus?.data?.data?.map((option, index) => {
                        const dateStr = option.created_at;
                        const formattedDate = moment(dateStr).format('MMMM D, YYYY h:mm A');

                        return (
                          <TableRow hover>
                            <TableCell align="left">{(statusPage - 1) * rowsPerPage + (index + 1)}</TableCell>
                            <TableCell align="left">{formattedDate}</TableCell>
                            <TableCell align="left">{option.status}</TableCell>

                            <TableCell align="left">
                              {option.status === 'Failed' && (
                                <RemoveRedEyeIcon
                                  onClick={() => HandleViewError(option.error)}
                                  sx={{ cursor: 'pointer' }}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Scrollbar>

          <DeleteConfirmationDialog
            isOpenConfirm={topModalOpen}
            handleClose={() => handleDelete(optionData)}
            handleCancle={() => setTopModalOpen(!topModalOpen)}
          />

          {treeCensus && showImportStatus === false ? (
            <Pagination
              count={showList ? pageInfo.last_page : 0}
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
              sx={{ justifyContent: 'right', display: 'flex', mt: 3, mb: 3 }}
            />
          ) : null}
          {importStatus && showImportStatus === true ? (
            <Pagination
              count={showStatusList ? pageInfo.last_page : 0}
              page={statusPage}
              variant="outlined"
              shape="rounded"
              onChange={handleChangeStatus}
              sx={{ justifyContent: 'right', display: 'flex', mt: 3, mb: 3 }}
            />
          ) : null}
        </Card>
      </Container>
    </Page>
  );
}

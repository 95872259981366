import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  CardActionArea,
  CardMedia,
  Card,
  CardContent,
  Box,
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Tab,
  Typography,
  CircularProgress,
  IconButton,
  Link,
  Chip,
} from '@mui/material';
import { green, orange, red, gray } from '@mui/material/colors';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useDispatch, useSelector } from 'react-redux';
import FilePresentIcon from '@mui/icons-material/FilePresent';

import { GetSpecificTreeInfo } from '../../../actions/TreeOnMapAction';
import TreeDetailsWithReasonDialog from '../../../components/DialogBox/TreeCutting/TreeDetailsWithReasonDialog';
import MeetingListModal from '../../../components/DialogBox/meeting/MeetingListModal';
import SideInscpectorList from '../../../components/DialogBox/SiteInscpectorList';
import ApplicationStatusChip from '../../../components/ApplicationStatusChip';
import pdfImg from '../../../Assets/pdf.png';
import StatusChip from '../../../components/StatusChip';

const ViewDetailsApplication = ({ applicationDetailsData, showLoader,fetchApplication }) => {
  const dispatch = useDispatch();
  const { treeDetails, loggedUser } = useSelector((state) => ({
    treeDetails: state.treeLocation.treeDetails,
    loggedUser: state.auth.loggedUser,
  }));
  const [treeDetailsOpen, setDialogOpen] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);
  const [isReassigned, setIsReassigned] = useState(false);

  const onMarkerClick = (marker) => {
    setDialogOpen(!treeDetailsOpen);
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
    dispatch(GetSpecificTreeInfo(marker));
  };

  const [application, setApplication] = useState({ applicationId: '', applicationName: '' });
  const [openSiteIncepectorList, setOpenSiteIncepectorList] = useState(false);
  const [open, setOpen] = useState(false);
  const handleAddToMeeting = () => {
    if (applicationDetailsData) {
      setApplication({
        applicationId: applicationDetailsData?.id,
        applicationName: applicationDetailsData?.application_number,
      });
      setOpen(true);
    }
  };

  const handleAddSiteInscpector = () => {
    setOpenSiteIncepectorList(true);
    if (applicationDetailsData) {
      setApplication({
        applicationId: applicationDetailsData?.id,
        applicationName: applicationDetailsData?.application_number,
      });
      if(applicationDetailsData?.application_status?.id === 2 || applicationDetailsData?.application_status?.id === 3) {
        setIsReassigned(true)
      }
      else {
        setIsReassigned(false)
      }
    }
  };
  const handleClick = (meetingId, application) => {
    if (application && application?.applicationId) {
      console.log('APPLICATIONID', application?.applicationId);
    }
  };
  const handleClosesiteList = () => {

    setOpenSiteIncepectorList(false);
    fetchApplication();
  };
  const handleCloseModal = () => {
    fetchApplication()
    setOpen(!open);
  };
  console.log("TOTAL TREES",applicationDetailsData)
  return (
    <Box>
      {showLoader ? (
        <Card sx={{ margin: '15px', padding: '20px' }}>
          <Grid container>
            <Grid item lg={12} sm={12} sx={{ display: 'flex', justifyContent: 'center', alignItem: 'center' }}>
              <CircularProgress />
            </Grid>
          </Grid>
        </Card>
      ) : (
        <Box>
          <Card sx={{ margin: '15px', padding: '20px' }}>
            <Grid container>
              <Grid item lg={7} sm={12} sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <Typography sx={{ fontWeight: '700', fontSize: '20px' }}>
                  Application Number : {applicationDetailsData.application_number}
                </Typography>

                <ApplicationStatusChip status={applicationDetailsData?.application_status?.application_status} />
              </Grid>
              <Grid item lg={5} sm={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
              {(applicationDetailsData.application_status?.id === 5 || applicationDetailsData.application_status?.id === 10 || applicationDetailsData.application_status?.id === 13) &&
                <Button  size="small" variant="contained" sx={{ marginX: '10px' }} onClick={() => handleAddToMeeting()}>
                  Add To Meeting
                </Button>
                }

              {applicationDetailsData.application_status?.id === 1  &&
                <Button  size="small" variant="contained" sx={{ marginX: '10px' }} onClick={() => handleAddSiteInscpector()}>
                   Ready For Inspection
                </Button>
                }

{(applicationDetailsData.application_status?.id === 2 || applicationDetailsData.application_status?.id === 3)  &&
                <Button  size="small" variant="contained" sx={{ marginX: '10px' }} onClick={() => handleAddSiteInscpector()}>
                   Reassign Site Inspector
                </Button>
                }

{applicationDetailsData.application_status?.id === 7  &&
                <Button component={RouterLink} state={applicationDetailsData}
                to={'/dashboard/proclamation/UploadProclamation'}  size="small" variant="contained" sx={{ marginX: '10px' }} >
                   Update Proclamation
                </Button>
                }

{applicationDetailsData.application_status?.id === 8  &&
                <Button component={RouterLink} state={applicationDetailsData.newspaper_proclamations[0]?.id}
                to={'/dashboard/proclamation/AddObjection'}  size="small" variant="contained" sx={{ marginX: '10px' }} >
                   Add Proclamation Objection
                </Button>
                }

{(applicationDetailsData.application_status?.id === 8 || applicationDetailsData.application_status?.id === 9 || applicationDetailsData.application_status?.id === 13)  && (applicationDetailsData?.newspaper_proclamations[0]?.fees_status === 'Unpaid') &&
                <Button component={RouterLink} state={{ data: applicationDetailsData, ProclamationId: applicationDetailsData.newspaper_proclamations[0]?.id }} 
                to={'/dashboard/proclamation/AddRecipt'}  size="small" variant="contained" sx={{ marginX: '10px' }} >
                   Upload Proclamation Receipt
                </Button>
                }

{applicationDetailsData.application_status?.id === 4  &&
                <Button component={RouterLink} state={applicationDetailsData}
                to={'/dashboard/uploadDepositeAmount'}  size="small" variant="contained" sx={{ marginX: '10px' }} >
                   Update Deposit Amount
                </Button>
                }

{applicationDetailsData.application_status?.id === 11  &&
                <Button component={RouterLink} state={{data : applicationDetailsData}}
                to={'/dashboard/uploadProcessingFeesReceipt'}  size="small" variant="contained" sx={{ marginX: '10px' }} >
                   Upload Processing Fees Receipt
                </Button>
                }

{applicationDetailsData.application_status?.id === 12  &&
                <Button component={RouterLink}
                to={`/download-permission-letter/${applicationDetailsData.id}`} target='blank'  size="small" variant="contained" sx={{ marginX: '10px' }} >
                   Download Permission Letter
                </Button>
                }
                {applicationDetailsData.application_status?.id === 12  &&
                <Button component={RouterLink}
                to={`/download-note-sheet/${applicationDetailsData.id}`} target='blank'  size="small" variant="contained" sx={{ marginX: '10px' }} >
                   View NoteSheet
                </Button>
                }
                
                {/* <Button size="small" variant="contained" onClick={() => handleAddSiteInscpector()}>
                  Ready For Inspection
                </Button> */}
              </Grid>
            </Grid>
            <Divider sx={{ marginY: '15px' }} />

            <Grid container spacing={5}>
              <Grid item lg={12}>
                <Grid container spacing={5}>
                  <Grid item lg={3} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}>Applicant Name</Typography>
                    <Typography>{applicationDetailsData?.name}</Typography>
                  </Grid>
                  <Grid item lg={3} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}>Contact Number</Typography>
                    <Typography>{applicationDetailsData?.mobile}</Typography>
                  </Grid>
                  <Grid item lg={3} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}>Date</Typography>
                    <Typography>{applicationDetailsData?.created_at}</Typography>
                  </Grid>
                  <Grid item lg={3} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}>Applied By</Typography>
                    <Typography>{applicationDetailsData?.applied_by}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* --- */}
              <Grid item lg={12}>
                <Grid container spacing={5}>
                  <Grid item lg={3} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}>Ward No. </Typography>
                    <Typography>{applicationDetailsData?.ward?.name}</Typography>
                  </Grid>
                  <Grid item lg={3} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}>Property No. </Typography>
                    <Typography>{applicationDetailsData?.property_number ?applicationDetailsData?.property_number : "NA"}</Typography>
                  </Grid>
                  <Grid item lg={3} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}>Address</Typography>
                    <Typography>{applicationDetailsData?.location}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* --- */}
              <Grid item lg={12}>
                <Grid container spacing={5}>
                  <Grid item lg={3} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}>Total Trees Requested</Typography>
                    <Typography>{applicationDetailsData?.total_trees}</Typography>
                  </Grid>
                  <Grid item lg={3} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}>Total Trees Permitted</Typography>
                    <Typography>
                      {applicationDetailsData?.total_trees_permitted}
                    </Typography>
                  </Grid>
                
                  <Grid item lg={3} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}> Total Trees Need to Re-planted</Typography>
                    <Typography>
                      {applicationDetailsData?.total_trees_to_be_planted !== null && applicationDetailsData?.total_trees_to_be_planted !== undefined
                        ? applicationDetailsData?.total_trees_to_be_planted
                        : 'NA'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* --- */}
              <Grid item lg={12}>
                <Grid container spacing={5}>
                  <Grid item lg={3} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}>Total Trees Re-planted</Typography>
                    <Typography>
                      {applicationDetailsData?.total_trees_replanted !==null && applicationDetailsData?.total_trees_replanted !==undefined
                        ? applicationDetailsData?.total_trees_replanted
                        : 'NA'}
                    </Typography>
                  </Grid>
                  <Grid item lg={3} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}>Total Died Re-planted Trees</Typography>
                    <Typography>
                    {applicationDetailsData?.died_replanted_trees !==null && applicationDetailsData?.died_replanted_trees !==undefined
                        ? applicationDetailsData?.died_replanted_trees
                        : 'NA'}
                    </Typography>
                  </Grid>
                  <Grid item lg={3} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}>Have 100 plus trees ?</Typography>
                    {applicationDetailsData?.have_100_plus_trees === 1 ? (
                      <Chip
                        label="Yes"
                        size="small"
                        style={{
                          marginX: '10px',
                          fontWeight: '600',
                          padding: '1px 10px',
                          backgroundColor: green[600],
                          color: 'white',
                          '&:hover': {
                            backgroundColor: green[800],
                          },
                        }}
                      />
                    ) : (
                      <Chip
                        label="No"
                        size="small"
                        style={{
                          marginX: '10px',
                          fontWeight: '600',
                          padding: '1px 10px',
                          backgroundColor: red[600],
                          color: 'white',
                          '&:hover': {
                            backgroundColor: red[800],
                          },
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item lg={3} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}>Have heritage trees ?</Typography>
                    {applicationDetailsData?.have_heritage_trees === 1 ? (
                      <Chip
                        label="Yes"
                        size="small"
                        style={{
                          marginLeft: '10px',
                          fontWeight: '600',
                          padding: '1px 10px',
                          backgroundColor: green[600],
                          color: 'white',
                          '&:hover': {
                            backgroundColor: green[800],
                          },
                        }}
                      />
                    ) : (
                      <Chip
                        label="No"
                        size="small"
                        style={{
                          marginLeft: '0px',
                          fontWeight: '400',
                          padding: '1px 10px',
                          backgroundColor: red[600],
                          color: 'white',
                          '&:hover': {
                            backgroundColor: red[800],
                          },
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={12}>
                <Grid container spacing={5}>
                  <Grid item lg={3} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}>Deposit Amount</Typography>
                    {applicationDetailsData?.deposit_amount_status ? 
                    <Typography>
                      <span sx={{ color: '#214c50' }}>{'\u20B9'}</span> {applicationDetailsData?.deposit_amount}
                      { applicationDetailsData.deposit_amount_status!==null && applicationDetailsData.deposit_amount_status === 'Unpaid' ? 
                        <Chip
                          label="Unpaid"
                          size="small"
                          style={{
                            marginLeft: '10px',
                            fontWeight: '600',
                            padding: '1px 10px',
                            backgroundColor: red[600],
                            color: 'white',
                            '&:hover': {
                              backgroundColor: red[800],
                            },
                          }}
                        />
                       : 
                        <Chip
                          label="Paid"
                          size="small"
                          style={{
                            marginLeft: '10px',
                            fontWeight: '400',
                            padding: '1px 10px',
                            backgroundColor: green[600],
                            color: 'white',
                            '&:hover': {
                              backgroundColor: green[800],
                            },
                          }}
                        />
                      }
                    </Typography>
                    :"NA"}
                  </Grid>
                  <Grid item lg={3} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}>Claimable Deposit Amount</Typography>
                    <Typography>
                      {applicationDetailsData.deposit_amount ?
                      <><span sx={{ color: '#214c50' }}>{'\u20B9'}</span> {Number(applicationDetailsData?.deposit_amount) - Number(applicationDetailsData?.deducted_deposit_amount) }</>:"NA"
                    }
                    </Typography>
                  </Grid>
                  <Grid item lg={3} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}>Processing Fees</Typography>

                    <Typography sx={{ color: '#214c50' }}>
                      {applicationDetailsData.processing_fees ?<><span sx={{ color: '#214c50' }}>{'\u20B9'}</span> {applicationDetailsData?.processing_fees}</>:"NA" }
                      {applicationDetailsData.processing_fees && (applicationDetailsData.processing_fees_status === 'Paid' ? (
                        <Chip
                          label="Paid"
                          size="small"
                          style={{
                            marginLeft: '10px',
                            fontWeight: '400',
                            padding: '1px 10px',
                            backgroundColor: green[600],
                            color: 'white',
                            '&:hover': {
                              backgroundColor: green[800],
                            },
                          }}
                        />
                      ) : (
                        <Chip
                          label="Unpaid"
                          size="small"
                          style={{
                            marginLeft: '10px',
                            fontWeight: '600',
                            padding: '1px 10px',
                            backgroundColor: red[600],
                            color: 'white',
                            '&:hover': {
                              backgroundColor: red[800],
                            },
                          }}
                        />
                      )) }
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={12}>
                <Grid container spacing={5}>
                {applicationDetailsData?.deposit_amt_receipt &&
                <Grid item lg={3} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}>Deposit Receipt</Typography>
                    <a href={`${process.env.REACT_APP_BASE_URL}/${applicationDetailsData?.deposit_amt_receipt}`} rel="noopener noreferrer" target="_blank">
                    <img
                      src={applicationDetailsData?.deposit_amt_receipt?.endsWith(".pdf") ? pdfImg : `${process.env.REACT_APP_BASE_URL}/${applicationDetailsData?.deposit_amt_receipt}`}
                      width={'80px'}
                      height={'80px'}
                      alt={'gst'}
                      style={{ borderRadius: '10px', margin: '0px',width:'80px' }}
                    />
                    </a>
                  </Grid>}
                  {applicationDetailsData?.processing_fees_receipt &&
                  <Grid item lg={3} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}> Processing Fees Receipt</Typography>
                    
                    <a href={`${process.env.REACT_APP_BASE_URL}/${applicationDetailsData?.processing_fees_receipt}`} rel="noopener noreferrer" target="_blank">
                    <img
                      src={ applicationDetailsData?.processing_fees_receipt?.endsWith(".pdf") ? pdfImg : `${process.env.REACT_APP_BASE_URL}/${applicationDetailsData?.processing_fees_receipt}`}
                      width={'80px'}
                      height={'80px'}
                      alt={'gst'}
                      style={{ borderRadius: '10px', margin: '10px' }}
                    />
                    </a>
                  </Grid>}
                </Grid>
              </Grid>
            </Grid>
          </Card>

          <Card sx={{ margin: '15px', padding: '20px' }}>
            <Grid container>
              <Grid item lg={7}>
                <Typography sx={{ fontWeight: '700', fontSize: '20px' }}>Application Documents</Typography>
              </Grid>
            </Grid>
            <Divider sx={{ marginY: '15px' }} />
            {applicationDetailsData?.documents?.length > 0 ? (
              <Grid container spacing={2}>
                {applicationDetailsData?.documents?.map((info, index) => {
                  return (
                    <Grid item lg={3} md={6} sm={12}>
                      <Card>
                        <CardActionArea>
                          <CardContent>
                            <Typography fontWeight={'700'}>
                              {' '}
                              {info.document_type === 'tax_receipt' ? 'Tax receipt' : 'Written application'}{' '}
                            </Typography>
                            {(() => {
                              if (info.document_url.includes('.pdf')) {
                                return (
                                  <img
                                    src={pdfImg}
                                    width={'80px'}
                                    height={'80px'}
                                    alt={'gst'}
                                    style={{ borderRadius: '10px', margin: '10px' }}
                                  />
                                );
                              }

                              if (info.document_url.includes('.jpg')) {
                                return (
                                  <img
                                    src={`${process.env.REACT_APP_BASE_URL}/${info.document_url}`}
                                    width={'80px'}
                                    height={'80px'}
                                    alt={'gst'}
                                    style={{ borderRadius: '10px', margin: '10px' }}
                                  />
                                );
                              }

                              return (
                                <FilePresentIcon
                                  sx={{
                                    fontSize: '130px',
                                    color: 'black',
                                    opacity: '0.2',
                                    // margin: '10px',
                                  }}
                                />
                              );
                            })(1)}
                            <Box>
                              <Link
                                href={`${process.env.REACT_APP_BASE_URL}/${info.document_url}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                underline="none"
                                style={{ display: 'block', marginBottom: '10px' }}>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  sx={{ mt: '5px', width: '100px', fontWeight: '300' }}>
                                  View File
                                </Button>
                              </Link>
                            </Box>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Typography sx={{ fontWeight: '700' }}>No documents available</Typography>
                </Grid>
              </Grid>
            )}
          </Card>

          <Card sx={{ margin: '15px', padding: '20px' }}>
            <Grid container>
              <Grid item lg={7}>
                <Typography sx={{ fontWeight: '700', fontSize: '20px' }}>Cutting Trees</Typography>
              </Grid>
            </Grid>
            <Divider sx={{ marginY: '15px' }} />
            <Grid container spacing={5}>
              {applicationDetailsData?.cutting_trees?.map((info) => {
                return (
                  <Grid item lg={3} md={6} sm={12}>
                    <Card sx={{ position: 'relative' }}>
                    <div style={{position:'absolute',left:'5px',top:'2px'}}>
                      <StatusChip  status={info.status==="Pending" ? "" : info.status} IsCuttingTrees={info.status==="Pending" ? "Pending" : ""} />
                      </div>
                      <CardActionArea sx={{marginTop:'5px'}}>
                        <CardContent>
                          <Typography gutterBottom sx={{ fontWeight: '700' }}>
                            {info.tree_number ?info.tree_number : "-"}
                          </Typography >

                          <Typography>
                            {info.tree_name.name}
                          </Typography>
                          {info.tree_cutting_reason?.reason ? (
                            <Typography>{info.tree_cutting_reason?.reason}</Typography>
                          ) : (
                            ''
                          )}
                        </CardContent>
                        <CardMedia
                          component="img"
                          height="200"
                          sx={{ padding: '10px', borderRadius: '35px' }}
                          image={`${process.env.REACT_APP_BASE_URL}/${info.image_url}`}
                          alt="green iguana"
                        />
                        {info.census_tree_id ? 
                        <IconButton
                          sx={{
                            color: 'black',
                            backgroundColor: 'white',
                            borderRadius: '50%',
                            boxShadow: '0 0 5px rgba(0,0,0,0.2)',
                            marginRight: '5px',
                            width: '30px',
                            height: '30px',
                            position: 'absolute',
                            top: 5,
                            right: 5,
                          }}>
                          <VisibilityIcon fontSize="small" onClick={() => onMarkerClick(info.census_tree_id)} />
                        </IconButton> :null}
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Card>
        </Box>
      )}
      {treeDetailsOpen ? <TreeDetailsWithReasonDialog isOpen={treeDetailsOpen} data={treeDetails} /> : null}
      {open ? (
        <MeetingListModal
          isOpen={open}
          handleClick={handleClick}
          handleClose={handleCloseModal}
          application={application}
        />
      ) : null}
      {openSiteIncepectorList ? (
        <SideInscpectorList
          isOpen={openSiteIncepectorList}
          isReassigned = {isReassigned}
          handleClick={handleClick}
          handleClose={handleClosesiteList}
          application={application}
        />
      ) : null}
    </Box>
  );
};

export default ViewDetailsApplication;

import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// @mui
import { Grid, Container, Typography, Stack, Select, CircularProgress } from '@mui/material';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
// components
import { Link, animateScroll as scroll } from 'react-scroll';
import Page from '../components/Page';
import { GetDashboardByCouncilId, getTeamsByCouncilId, getTeamDetailByCouncilTeam, GetDashboard, GetCutTreesDashboard } from '../actions/DashboardAction';
import { ShowLoader } from '../actions/CommonAction';
import AssociateZeroTree from './Dashboardsection/AssociateZeroTree';
import YesterdayLoggedIn from './Dashboardsection/YesterdayLoggedIn';
import LightCard from './Dashboardsection/LightCard';
import CensusLightCard from './Dashboardsection/CensusLightCard';
import CouncilAdminCard from './Dashboardsection/CouncilAdminCard';
import CouncilLocationCard from './Dashboardsection/CouncilLocationCard';
import CensusCountCard from './Dashboardsection/CensusCountCard';
import QcCountableCard from './Dashboardsection/QcCountableCard';
import DarkCard from './Dashboardsection/DarkCard';
import PropertyCharts from './Dashboardsection/PropertyCharts';
import CommanCard from './Dashboardsection/CommanCard';
import DashboardFooter from './Dashboardsection/DashboardFooter';
import AssociateCard from './Dashboardsection/AssociateCard';
import { TreePieCharts } from './Dashboardsection/TreePieCharts';
import { TreeByTreePieChart } from './Dashboardsection/TreeByTreePieChart';

import UsersCard from './Dashboardsection/UsersCard';
import DataAnalystCard from './Dashboardsection/DataAnalystCard';
import WorktypeCard from './Dashboardsection/WorktypeCard';
import BaseColorGraph from './Dashboardsection/BaseColorGraph';
import { AllTreesGraph } from './Dashboardsection/AllTreesGraph';
import CensusTreeGraph from './Dashboardsection/CensusTreeGraph';
import WorktypeCensusCard from './Dashboardsection/WorktypeCensusCard';
import YesterdayHighLow from './Dashboardsection/YesterdayHighLow';
import YesterdayHighLowCensus from './Dashboardsection/YesterdayHighLowCensus';
import TreeDetail from './Dashboardsection/TreeDetail';
import LastTreeNumbers from './Dashboardsection/LastTreeNumbers';
import Deviation from './Dashboardsection/Deviation';
import MasterData from './Dashboardsection/MasterData';
import LocationWiseTreeData from './Dashboardsection/LocationWiseTreeData';
import TreeConditionCard from './Dashboardsection/TreeConditionCard';
import { GetActiveCouncil } from '../actions/CouncilAction';
import FullLoader from '../components/Loader/FullLoader';
import RevenueChart from './Dashboardsection/RevenueChart';
import DepositRefundSizedChart from './Dashboardsection/DepositRefundChart';
import CouncilAdminCutTreesCard from './Dashboardsection/CouncilAdminCutTreesCard';
import WardWiseCutTreesCard from './Dashboardsection/WardWiseCutTreesCard';
import TreeReasonCard from './Dashboardsection/TreeReasonCard';
import CutAndReplantedTreeChart from './Dashboardsection/CutAndReplantedTreeChart';
import YearwiseCutTreesCard from './Dashboardsection/YearwiseCutTreesCard';
// ----------------------------------------------------------------------

export default function TreeCutDashboard() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [councilId, setCouncilId] = useState();
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const {
    loggedUser,
    dashboardCouncil,
    council,
    showLoader,
    dashboardCouncilTeams,
    dashboardTeamDetailbyCouncilTeamId,
    dashboardCutTrees
  } = useSelector((state) => ({
    loggedUser: state.auth.loggedUser,
    dashboardCouncil: state.dashboardCouncil.dashboardCouncil,
    dashboardCouncilTeams: state.dashboardCouncilTeams.dashboardCouncilTeams,
    dashboardTeamDetailbyCouncilTeamId: state.dashboardTeamDetailbyCouncilTeamId.dashboardTeamDetailbyCouncilTeamId,
    council: state.council.activeCouncil,
    showLoader: state.common.showLoader,
    dashboardCutTrees: state.dashboardCouncil.dashboardCutTrees,
  }));

  console.log("DASHBOARD CUT TREES", dashboardCutTrees)
  // console.log('dashboardCouncil........', dashboardCouncil);
  // console.log("dashboardCouncil?.council_records?.work_type_counts?.base_color", dashboardCouncil);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // console.log("councilcalled..................");
    if (loggedUser.roles[0].slug === 'superadmin' || loggedUser.roles[0].slug === 'admin') {
      dispatch(GetActiveCouncil(1));
    }
  }, [])
  useEffect(() => {
    if (council) {
      setCouncilId(council[0].id);
    }
  }, [council])
  const firstRun = useRef(true);
  const secondRun = useRef(true);
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    if (councilId && (loggedUser.roles[0].slug === 'superadmin' || loggedUser.roles[0].slug === 'admin')) {
      // console.log("called");
      dispatch(ShowLoader(true))
      dispatch(GetDashboardByCouncilId(councilId));
      dispatch(getTeamsByCouncilId(councilId));
    }

  }, [councilId]);
  useEffect(() => {
    if (loggedUser.roles[0].slug === 'qc_census_offsite' || loggedUser.roles[0].slug === 'qc_base_color_offsite' || loggedUser.roles[0].slug === 'council' || loggedUser.roles[0].slug ==='data_analyst') {
      console.log("inside if")
      dispatch(GetCutTreesDashboard())
    }
  }, [])

  // console.log("dashboardCouncilTeams.length", dashboardCouncilTeams);
  useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    if (dashboardCouncilTeams?.[0]?.id) {
      // console.log("called");
      dispatch(getTeamDetailByCouncilTeam(councilId, dashboardCouncilTeams?.[0]?.id));
    }
  }, [dashboardCouncilTeams?.[0]?.id]);

  const useStyles = makeStyles({
    icon: {
      fill: '#214C50',
    },
  });
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();

  return (
    <>{loggedUser.roles[0].slug === 'superadmin' || loggedUser.roles[0].slug === 'admin' ? <>
      {dashboardCouncil === null ? <FullLoader showLoader={1} /> : <>
        <FullLoader showLoader={showLoader} />
        <Page title="Dashboard">
          <Container maxWidth="xl" style={{ borderBottom: '1px solid red' }} id="projectSection">
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0.5}>
              <Typography variant="h4" gutterBottom>
                Welcome {loggedUser?.name}
                <Typography variant="h6" style={{ fontWeight: 400 }}>
                  {loggedUser?.designation}
                </Typography>
              </Typography>
            </Stack>

          </Container>

        </Page>
      </>}
    </> :
      null

    }
      {loggedUser.roles[0].slug === 'council' ?
        <Page title="Cut Trees Dashboard">
          <Container maxWidth="xl" style={{ borderBottom: '1px solid #dbd9d9' }} id="projectSection">
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0.5}>
              <Typography variant="h4" gutterBottom>
                Welcome  {loggedUser?.name},
                <Typography variant="h6" style={{ fontWeight: 400 }}>
                  {loggedUser?.designation}
                </Typography>
              </Typography>
            </Stack>
          </Container>
          <br />
          <br />

          <Container >
            <Grid container spacing={3} alignItems="center">
              <Grid container alignItems="center"
                justifyContent="center"
                rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={10} md={10} sm={10}>
                  <CouncilAdminCutTreesCard projects={dashboardCutTrees} />
                </Grid>
              </Grid>
            </Grid>
          </Container>
          <br />

          <br />
          <br />
          

          <Grid container spacing={3}>
            <Grid container rowSpacing={1} alignItems="center"
              justifyContent="center">
              <Grid item xs={10} md={10} sm={10}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid container item xs={12} md={12} sm={12} spacing={3}>
                    <Grid item xs={12} sm={6} md={8}>
                      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginLeft: '-24px', padding: '0px 20px' }}>
                        <Typography variant="h4" gutterBottom>
                          Revenue
                         
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>

                 
                  <Grid item xs={12} md={12} sm={12}>
                    <RevenueChart data={dashboardCutTrees} />
                  </Grid>
                 
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <br />
          <br />
          <br />

          <Grid container spacing={3}>
            <Grid container rowSpacing={1} alignItems="center"
              justifyContent="center">
              <Grid item xs={10} md={10} sm={10}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid container item xs={12} md={12} sm={12} spacing={3}>
                    <Grid item xs={12} sm={6} md={8}>
                      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginLeft: '-24px', padding: '0px 20px' }}>
                        <Typography variant="h4" gutterBottom>
                          Deposit Refund and Seized
                         
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>

                 
                  <Grid item xs={12} md={12} sm={12}>
                    <DepositRefundSizedChart data={dashboardCutTrees} />
                  </Grid>
                 
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <br />
          <br />
          <br />

          <Grid container spacing={3}>
            <Grid container rowSpacing={1} alignItems="center"
              justifyContent="center">
              <Grid item xs={10} md={10} sm={10}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid container item xs={12} md={12} sm={12} spacing={3}>
                    <Grid item xs={12} sm={6} md={8}>
                      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginLeft: '-24px', padding: '0px 20px' }}>
                        <Typography variant="h4" gutterBottom>
                          Cut Trees and Replanted Trees
                         
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>

                 
                  <Grid item xs={12} md={12} sm={12}>
                    <CutAndReplantedTreeChart data={dashboardCutTrees} />
                  </Grid>
                 
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <br />
          <br />
          <br />

          <Grid container spacing={3}>
            <Grid container rowSpacing={1} alignItems="center"
              justifyContent="center">
              <Grid item xs={10} md={10} sm={10}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={12} md={12} sm={10}>
                    <YearwiseCutTreesCard projects={dashboardCutTrees?.yearly_cut_trees} />
                  </Grid>
                  
                  {/* <Grid item xs={12} md={12} sm={10}>
                  <TreeReasonCard type="tree_name_wise_cut" projects={dashboardCutTrees?.tree_name_wise_cut_tree} />
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />


          <Grid container spacing={3}>
            <Grid container rowSpacing={1} alignItems="center"
              justifyContent="center">
              <Grid item xs={10} md={10} sm={10}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={12} md={12} sm={10}>
                    <WardWiseCutTreesCard projects={dashboardCutTrees?.ward_wise_cut_tree} />
                  </Grid>
                  
                  {/* <Grid item xs={12} md={12} sm={10}>
                  <TreeReasonCard type="tree_name_wise_cut" projects={dashboardCutTrees?.tree_name_wise_cut_tree} />
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />

          <Grid container spacing={3}>
            <Grid container rowSpacing={1} alignItems="center"
              justifyContent="center">
              <Grid item xs={10} md={10} sm={10}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={12} md={12} sm={12}>
                  <TreeReasonCard type="tree_name_wise_cut" projects={dashboardCutTrees?.tree_name_wise_cut_tree} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />

          <Grid container spacing={3}>
            <Grid container rowSpacing={1} alignItems="center"
              justifyContent="center">
              <Grid item xs={10} md={10} sm={10}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={12} md={12} sm={12}>
                  <TreeReasonCard type="tree_name_wise_replanted" projects={dashboardCutTrees?.tree_name_wise_replanted_tree} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
          <Grid container spacing={3}>
            <Grid container rowSpacing={1} alignItems="center"
              justifyContent="center">
              <Grid item xs={10} md={10} sm={10}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={12} md={12} sm={12}>
                    <TreeReasonCard type="reason_wise_cut" projects={dashboardCutTrees?.reason_wise_cut_tree} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <br />
         
        </Page>
        :
        null
      }

    </>
  );
}

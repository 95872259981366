import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import moment from 'moment';
import { useFormik } from 'formik';
import {
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
  Chip,
  TableCell,
  Box,
  Table,
  TableRow,
  TableContainer,
  TableBody,
  Pagination,
  Tooltip,
  CircularProgress,
  Drawer,
  MenuItem,
  TextField
} from '@mui/material';
import Select from 'react-select';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { makeStyles } from '@material-ui/core/styles';

import { useDispatch, useSelector } from 'react-redux';
import { downloadExcel } from 'react-export-table-to-excel';

import TextWithTooltip from '../../components/Tooltip';

import MeetingListModal from '../../components/DialogBox/meeting/MeetingListModal';
import {
  GetExcelCuttedTrees,
  GetCuttedTrees,
  SearchCuttedTrees,
} from '../../actions/TreeCuttingPermissionAction';

import Page from '../../components/Page';

import TreeCuttingListMenu from '../../sections/@dashboard/tree-cutting/TreeCuttingListMenu';
import TreeCuttingApplicationToolbar from '../../sections/@dashboard/tree-cutting/TreeCuttingApplicationToolbar';
import { UserListHead, UserListToolbar, UserFormListMenu } from '../../sections/@dashboard/user';
import StatusChip from '../../components/StatusChip';
import SideInscpectorList from '../../components/DialogBox/SiteInscpectorList';
import UseMoreMenuAction from '../../components/UseMoreMenuAction';
import ApplicationStatusChip from '../../components/ApplicationStatusChip';
import Iconify from '../../components/Iconify';
import { GetActiveTreeName } from '../../actions/TreeNameAction';
import { GetTreeCuttingReasonsList } from '../../actions/TreeCuttingReasonAction';


const TABLE_HEAD = [
  { id: 'srno', label: '#', alignRight: false },

  { id: 'name', label: 'Tree Name', alignRight: false },
  { id: 'treeAge', label: 'Tree Age', alignRight: false },
  { id: 'ward', label: 'Ward', alignRight: false },
  { id: 'reason', label: 'Reason', alignRight: false },
  { id: 'applicationNo', label: 'Application No', alignRight: false },
  { id: 'approvedDate', label: 'Approved Date', alignRight: false },
];

export default function CuttedTrees(props) {
  const dispatch = useDispatch();
  const maxLength = 8;
  const [searchValue, setSearchValue] = useState(null);
  const [statusId, setStatusId] = useState('');
  const [showList, setShowList] = useState(false);
  const [open, setOpen] = useState(false);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(5);
  const [loader, setLoader] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  // const [lastPage, setLastPage] = useState(2);
  // const scrollContainerRef = useRef(null);
  // const loader = useRef(null);
  const [meetingParam, setMeetingParam] = useState({});
  const [application, setApplication] = useState({ applicationId: '', applicationName: '' });
  const [treeNameInput, setTreeNameInput] = React.useState();
  const [filterBy , setFilterBy] = useState('');
  const [filterId , setFilterId] = useState('');
  const [wardID, setWardID] = React.useState('');
  const [reasonId, setReasonId] = React.useState('');
  const [filterStartDate, setFilterStartDate] = useState('');
  const [filterEndDate, setFilterEndDate] = useState('');

  const [showClearFilter, setShowClearFilter] = useState(false);
  const navigate = useNavigate();


  const [newState, setNewState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [siteInspectorList, setSiteInspectorList] = useState([]);

  const filterOptions = [
    { label: 'By Date', value: 'approved_date' },
    { label: 'Heritage Tree', value: 'heritage_trees' },
    { label: 'By Ward', value: 'ward' },
    { label: 'By Tree Name', value: 'tree_name' },
    { label: 'By Reason', value: 'reason' },
  ];



  let timer = null;
  const filterByName = (event) => {
    const { value } = event.currentTarget;
    clearTimeout(timer);
    // Wait for X ms and then process the request
    timer = setTimeout(() => {
      if (value) {
        setSearchValue(value);
      } else {
        setSearchValue('');
      }
    }, 1000);
  };

  const handleStatusChange = (e) => {
    setStatusId(e.target.value);
  };

  const useStyles = makeStyles({
    success: {
      backgroundColor: '#DDFAD1',
      color: '#507C59',
      border: '1px solid #507C59',
      fontSize: '12px',
      borderRadius: '5px',
      padding: '4px 10px',
      fontWeight: 600,
    },
    pending: {
      backgroundColor: '#efcbbd',
      color: '#CE5623',
      border: '1px solid #CE5623',
      fontSize: '12px',
      borderRadius: '5px',
      padding: '4px 10px',
      fontWeight: 600,
      pointerEvents: 'none',
    },
    icon: {
      fill: '#214C50',
    },
  });
  const classes = useStyles();

  const sampleData = [{}];

  const {
    cuttedTrees,
    excelCuttedTrees,
    isReportDownloadable,
    pageInfo,
    searchTreeCuttingApplications,
    siteinspector,
    treeName,
    wards,
    treeCuttingReason
  } = useSelector((state) => ({
    cuttedTrees: state.treeCutting.cuttedTrees,
    excelCuttedTrees: state.treeCutting.excelCuttedTrees,
    isReportDownloadable: state.treeCutting.isReportDownloadable,
    pageInfo: state.treeCutting.pageInfo,
    searchTreeCuttingApplications: state.treeCutting.searchTreeCuttingApplications,
    siteinspector: state.treeCutting.siteinspector,
    treeName: state.treeName.activeTreeName,
    wards: state.wards.activeWardsByCID,
    treeCuttingReason: state.treeCuttingReason.treeCuttingReason,
  }));

  useEffect(()=>{
    dispatch(GetActiveTreeName(1));
  },[])

  useEffect(() => {
    setLoader(true);
    
    dispatch(GetCuttedTrees( 1, rowsPerPage,filterBy,filterId,filterStartDate,filterEndDate)).then((response) => {
      if (response.status === 200) {
        // setFilteredApplication(response.data.data);
        setLoader(false);
      }
    });
  }, [dispatch,siteinspector]);

  useEffect(() => {
    if (pageInfo) {
      setCount(pageInfo?.total);
      setCurrentPage(pageInfo?.current);
    }
  }, [pageInfo]);

  const secondRun = useRef(true);
  useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    // setFilteredApplication(treeCuttingAllApplications);
    setLoader(false);
    setShowList(true);
  }, [dispatch, cuttedTrees]);

  const thirdRun = useRef(true);
  useEffect(() => {
    if (thirdRun.current) {
      thirdRun.current = false;
      return;
    }
    // setFilteredApplication(searchTreeCuttingApplications);
    setLoader(false);
    setShowList(true);
  }, [dispatch, searchTreeCuttingApplications]);

  useEffect(() => {
    if (searchValue ) {
      // const searchParam = {
      //   page,
      //   limit: rowsPerPage,
      //   search: searchValue,
      // };

      dispatch(SearchCuttedTrees(1,rowsPerPage,searchValue)).then((response) => {
        if (response.status === 200) {
          setLoader(false);
        }
      });
    } else {
      dispatch(GetCuttedTrees(1, rowsPerPage,filterBy,filterId,filterStartDate,filterEndDate));
    }
  }, [searchValue]);

  // useEffect(() => {
  //   setFilteredApplication(searchTreeCuttingApplications);
  // }, [dispatch, searchTreeCuttingApplications]);

  /* #region Export functionality */
  const [exportButtonPressed, setExportButtonPressed] = useState(false);

  useEffect(() => {
    if (exportButtonPressed) {
      DownloadReportExcel();
      setExportButtonPressed(false);
    }
  }, [isReportDownloadable, excelCuttedTrees]);

  const header = [
    '#',
    'Tree Name',
    'Tree Age',
    'Ward',
    'Reason',
    'Application No',
    'Approved Date'
  ];
  function DownloadReportExcel() {
    const value1 = [];
    excelCuttedTrees?.map((option, index) => {
      const value2 = [index + 1];
      
      value2.push(option?.site_inspection_report?.tree_name?.name);
      value2.push(option?.site_inspection_report?.age_by_girth);
      value2.push(option?.site_inspection_report?.cutting_tree_application?.ward?.name);
      value2.push(option?.tree_cutting_reason?.reason);
      value2.push(option?.site_inspection_report?.cutting_tree_application?.application_number);
      value2.push(moment(option?.updated_at ? option?.updated_at : '-').format('YYYY-DD-MM'));
      value1.push(value2);
      return null;
    });
    downloadExcel({
      fileName: `Cut Trees`,
      sheet: 'Cut Trees',
      tablePayload: {
        header,
        // accept two different data structures
        body: value1,
      },
    });
  }

  const exportReports = () => {
    dispatch(GetExcelCuttedTrees(searchValue,filterBy==="approved_date" ? filterStartDate && filterEndDate ? filterBy : "" : filterId ? filterBy : "" , filterId , filterStartDate, filterEndDate));
    setExportButtonPressed(true);
  };
  /* #endRegion */

  const handleChangePage = (event, newPage) => {
    
    setPage(newPage);

    setLoader(true);
    // setShowList(false);
    if (searchValue) {
      dispatch(SearchCuttedTrees(newPage, rowsPerPage, searchValue));
    } else {
      dispatch(GetCuttedTrees( newPage, rowsPerPage,filterBy , filterId , filterStartDate , filterEndDate));
    }
  };



  const toggleDrawer = (anchor, open) => (event) => {
    // setshowImportStatus(false);
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setNewState({ ...newState, [anchor]: open });
  };

  const handleTreeNameSelect = (e) => {
    setTreeNameInput(e);
    console.log("E",e);
    formik.setFieldValue('treeNameFrom',e.value);
  };

  const handleFilterChange = (event) => {
    setFilterBy(event.target.value);
    formik.resetForm();
    formik.setFieldError({});
    formik.setFieldValue('filter',event.target.value)
    setWardID('')
      setTreeNameInput('')
      setReasonId('')
    if(event.target.value==='reason'){
      
      dispatch(GetTreeCuttingReasonsList());
    }
  };

  const handleWardChange = (event) => {
    setWardID(event.target.value);
  };

  const handleReasonChange = (event) => {
    setReasonId(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchValue("");
  }


  const handleClearFilter = () => {
    navigate(0); // Navigates to the current route, triggering a reload
    // setShowClearFilter(false)
  };

  const FilterSchema = Yup.object().shape({
    filter: Yup.string().required('Please select filter'),
    ward: Yup.string().when("filter", {
      is: "ward", // Condition: If filter equals "1"
      then: Yup.string().required("Ward is required"),
      otherwise: Yup.string(),
    }),

    treeNameFrom: Yup.string().when("filter", {
      is: "tree_name", // Condition: If filter equals "1"
      then: Yup.string().required("Tree Name is required"),
      otherwise: Yup.string(),
    }),
    
    fromDate: Yup.string().when("filter", {
      is: 'approved_date', // Condition: If filter equals "1"
      then: Yup.string().required("Start date is required"),
      otherwise: Yup.string(),
    }),
    toDate: Yup.string().when("filter", {
      is: 'approved_date',
      then: Yup.string().required("End date is required").test(
        "is-greater",
        "End date must be greater than start date",
        (value, context) => {
          const { fromDate } = context.parent;  // Access values directly from context
          return !fromDate || new Date(fromDate) < new Date(value); // Compare dates
        }
      ),
      otherwise: Yup.string(),
    }),
    reason: Yup.string().when("filter", {
      is: "reason", // Condition: If filter equals "1"
      then: Yup.string().required("Reason is required"),
      otherwise: Yup.string(),
    }),
    
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      filter : '',
      ward : '',
      treeNameFrom : '',
      fromDate : '',
      toDate : '',
      reason : ''
    },
    validationSchema: FilterSchema,
    onSubmit: (value) => {
      let filterIdForObj = '';

      if(filterBy==="reason"){
        filterIdForObj = reasonId
      }  else if (filterBy==="ward") {
        filterIdForObj = wardID
      } else if (filterBy==="tree_name") {
        filterIdForObj = treeNameInput?.value
      }
      else if (filterBy==="heritage_trees") {
        filterIdForObj = treeNameInput?.value ? treeNameInput?.value : ''
      }

      setNewState({ ...newState, right: false });
      setLoader(true);
      setFilterId(filterIdForObj);
      setFilterStartDate(value.fromDate)
      setFilterEndDate(value.toDate)
      setShowClearFilter(true)
      dispatch(GetCuttedTrees( 1, rowsPerPage, filterBy , filterIdForObj , value.fromDate , value.toDate)).then((response) => {
        if (response.status === 200) {
          setLoader(false);
        }
      })
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errors.treeNameFrom && touched.treeNameFrom ? "#FF4842" : state.isFocused ? "blue" : base.borderColor,
      "&:hover": {
        borderColor: errors.treeNameFrom && touched.treeNameFrom  ? "#FF4842" : state.isFocused ? "blue" : base.borderColor,
      },
    }),
  };

  console.log("ERROR",errors);
  console.log("TOUCHED",touched);
  return (
    <Page title="Tree Cutting Application">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Cut Trees
            <Typography variant="h6" style={{ fontWeight: 400 }}>
              It is showing list of all cut trees
            </Typography>
          </Typography>
          <Box display="flex" flexDirection="row-reverse" gap={2}>
          <Button
              onClick={toggleDrawer('right', true)}
              variant="contained"
              to="#"
              // sx={{ fontSize: '10px', fontWeight: '700', height: '27px' }}
              startIcon={<Iconify icon="eva:funnel-fill" />}>
              Filters
            </Button>
          </Box>
        </Stack>

        <Stack direction="row"  mb={1}>
          <Box sx={{ height: '100' }}>
            <Drawer
              sx={{
                '& .MuiDrawer-paper': {
                  width: '350px',
                  maxWidth: '100%',
                  justifyContent: 'start',
                  alignItems: 'center',
                  paddingTop:'2%'
                },
              }}
              anchor={'right'}
              open={newState.right}
              onClose={toggleDrawer('right', false)}>
              <div style={{maxWidth:'100%'}}>
                <Grid container spacing={1} style={{ width: '90%', marginLeft: '5%', marginRight: '5%' }}> 
                  <Stack direction="column" spacing={2} style={{width:'100%'}}>
                    <Grid item xs={12}>
                    <TextField
                    fullWidth
                      select
                      id="filter"
                      name="filter"
                      label="Select Filter Option*"
                      displayEmpty
                      value={filterBy}
                      style={{ width: '100%', marginTop: 5,minWidth:'200px' }}
                      size="small"
                      onChange={(e) => {
                        handleFilterChange(e);
                        formik.handleChange(e);
                      }}
                      error={touched.filter && Boolean(errors.filter)}
                      helperText={touched.filter && errors.filter}
                      // onChange={handleWardChange}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}>
                      <MenuItem disabled value="">
                        <em>Select Filter*</em>
                      </MenuItem>
                      {filterOptions?.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))
                       }
                    </TextField>
                  </Grid>
                  {filterBy==='approved_date' &&
                  <div>
                  <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="fromDate"
                    type="date"
                    label="Start Date"
                    margin="normal"
                    name="fromDate"
                    style={{ width: '100%', marginTop: 5 }}
                    size="small"
                    value={values.fromDate}
                    onChange={formik.handleChange}
                    error={touched.fromDate && Boolean(errors.fromDate)}
                  helperText={touched.fromDate && errors.fromDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="toDate"
                    type="date"
                    label="End Date"
                    margin="normal"
                    name="toDate"
                    value={values.toDate}
                    style={{ width: '100%', marginTop: 5 }}
                    size="small"
                    onChange={formik.handleChange}
                    error={touched.toDate && Boolean(errors.toDate)}
                  helperText={touched.toDate && errors.toDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                </div> 
                  }
                  {filterBy==='ward' &&
                  <Grid item xs={12}>
                    <TextField
                      select
                      fullWidth
                      id="ward"
                      name='ward'
                      label="Ward"
                      displayEmpty
                      value={wardID}
                      style={{ width: '100%', marginTop: 5 }}
                      size="small"
                      onChange={(e) => {
                        handleWardChange(e);
                        formik.handleChange(e);
                      }}
                      error={touched.ward && Boolean(errors.ward)}
                      helperText={touched.ward && errors.ward}
                      // onChange={handleWardChange}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}>
                      <MenuItem disabled value="">
                        <em>Select Ward*</em>
                      </MenuItem>
                      {wards?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))
                        }
                    </TextField>
                  </Grid>
                  }
                  {filterBy==='reason' &&
                  <Grid item xs={12}>
                    <TextField
                      select
                      fullWidth
                      id="reason"
                      name='reason'
                      label="Reason"
                      displayEmpty
                      value={reasonId}
                      style={{ width: '100%', marginTop: 5 }}
                      size="small"
                      onChange={(e) => {
                        handleReasonChange(e);
                        formik.handleChange(e);
                      }}
                      error={touched.reason && Boolean(errors.reason)}
                  helperText={touched.reason && errors.reason}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}>
                      <MenuItem disabled value="">
                        <em>Select Tree Cutting Reason*</em>
                      </MenuItem>
                      {treeCuttingReason?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.reason}
                            </MenuItem>
                          ))
                        }
                    </TextField>
                </Grid>}
                {(filterBy==='heritage_trees' || filterBy==='tree_name') &&
                     <Grid item xs={12}>
                    <Select
                      id="treeNameFrom"
                      name='treeNameFrom'
                      placeholder="Select Tree Name"
                      label="Tree Name"
                      value={treeNameInput}
                      styles={customStyles}
                      className="react-select-container"
                      isClearable
                      componentsProps={{
                        listbox: {
                          sx: { backgroundColor: '#000' },
                        },
                      }}
                      style={{ borderColor: 'red' }}
                      clearable={false}
                      options={treeName?.map((item) => {
                        return { value: item.id, label: item.name };
                      })}
                      // eslint-disable-next-line react/jsx-no-bind
                      onChange={handleTreeNameSelect}
                    />
                    {errors.treeNameFrom && touched.treeNameFrom && (
              <div style={{ color: '#FF4842', marginTop: '0.25rem',fontSize:'0.75rem' }}>
                {errors.treeNameFrom}
              </div>
            )}
                  </Grid>}
                  </Stack>
                  <Button
                    onClick={()=>formik.handleSubmit()}
                    variant="contained"
                    style={{ width: '60%', marginLeft: '20%', marginRight: '20%', marginTop: 10 }}>
                    Apply
                  </Button>
                  {showClearFilter && 
                    <Button
                      onClick={()=>handleClearFilter()}
                      variant="contained"
                      style={{ width: '60%', marginLeft: '20%', marginRight: '20%', marginTop: 10,backgroundColor:'#bdbdbd' }}>
                      Clear Filter
                    </Button>
                  }
                </Grid>
              </div>
            </Drawer>
          </Box>
        </Stack>


        <Card>
          <TreeCuttingApplicationToolbar
            onFilterName={filterByName}
            handleClearSearch = {handleClearSearch}
            statusId={statusId}
            handleStatusChange={handleStatusChange}
            // exportReports={exportReports}
            showExport
            handleexportReports={exportReports}
          />
          <TableContainer sx={{ minWidth: 700, overflow: 'auto' }}>
            <Table size="small" aria-label="a dense table" sx={{ overflow: 'hidden' }}>
              <UserListHead headLabel={TABLE_HEAD} />
              {loader ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={9} style={{ whiteSpace: 'nowrap', width: '2000px' }}>
                      {loader && (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <CircularProgress />
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {cuttedTrees?.length > 0 ? (
                    cuttedTrees?.map((value, index) => {
                      return (
                        <TableRow hover>
                          <TableCell align="left">
                            <b>{pageInfo.per_page * (pageInfo.current_page - 1) + index + 1}</b>
                          </TableCell>
                          
                          <TableCell align="left">
                            <TextWithTooltip text={value?.site_inspection_report?.tree_name?.name} maxLength={maxLength} />
                          </TableCell>
                          <TableCell>{value?.site_inspection_report?.age_by_girth}</TableCell>
                          <TableCell align="left">{value?.site_inspection_report?.cutting_tree_application?.ward?.name}</TableCell>
                          <TableCell align="left">{value?.tree_cutting_reason?.reason}</TableCell>

                          <TableCell align="left">{value?.site_inspection_report?.cutting_tree_application?.application_number}</TableCell>
                          <TableCell align="left">{moment(value?.updated_at ? value?.updated_at : '-').format('YYYY-DD-MM')}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9}>
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                          <Typography>No data found !</Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <Box>
            <Pagination
              count={pageInfo.last_page }
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
              sx={{ justifyContent: 'right', display: 'flex', mt: 3, mb: 3 }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

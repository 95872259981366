import { useEffect, useState } from 'react';
import * as pdfjsLib from "pdfjs-dist";
// import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf"
import { GlobalWorkerOptions } from "pdfjs-dist";


import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import useResponsive from '../hooks/useResponsive';
import Server from "../api";
import { SetNewAlert } from '../actions/AlertActions';

// Set the worker source
GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js";

export default function NoteSheet() {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [images, setImages] = useState([])
  const [receiptImage, setReceiptImage] = useState([])
  const [data, setData] = useState({});
  const { applicationId } = useParams();
  const dispatch = useDispatch();


  const mdUp = useResponsive('up', 'md');

  useEffect(async () => {

    const renderPdfAsImages = async () => {
      try {
        // Load the PDF file

        const pdfUrl = 'http://localhost:3000/sample.pdf'

        const pdf = await pdfjsLib.getDocument(pdfUrl).promise;

        // const pageImages = [];
        const pageImages = await Promise.all(
          Array.from({ length: pdf.numPages }, async (_, index) => {
            const page = await pdf.getPage(index + 1); // PDF page numbers start at 1
            const viewport = page.getViewport({ scale: 2 });
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            canvas.width = viewport.width;
            canvas.height = viewport.height;
        
            const renderContext = { canvasContext: context, viewport };
            await page.render(renderContext).promise;
        
            return canvas.toDataURL("image/png");
          })
        );

        console.log("PAGE IMAGES",pageImages)

        setImages(pageImages); // Update state with image URLs
      } catch (error) {
        console.error("Error rendering PDF:", error);
      }
    };

    const renderDocumentsAsImages = async (documents,type) => {
      try {
        const processedImages = await Promise.all(
          documents.map(async (doc) => {
            try {
              if (doc?.endsWith(".pdf")) {
                try {
                  const pdf = await pdfjsLib.getDocument(doc).promise;
                  const pdfImages = await Promise.all(
                    Array.from({ length: pdf.numPages }, async (_, index) => {
                      const page = await pdf.getPage(index + 1);
                      const viewport = page.getViewport({ scale: 2 });
                      const canvas = document.createElement("canvas");
                      const context = canvas.getContext("2d");
                      canvas.width = viewport.width;
                      canvas.height = viewport.height;
        
                      const renderContext = { canvasContext: context, viewport };
                      await page.render(renderContext).promise;
        
                      return canvas.toDataURL("image/png");
                    })
                  );
                  return pdfImages; // Return array of images for this PDF
                } catch (error) {
                  console.error("Error processing PDF:", error);
                  return []; // Return empty array on error, or handle differently
                }
              }
        
              if (!doc.endsWith(".pdf")) {
                // Directly return the image URL for image documents
                return [doc];
              }
        
              throw new Error(`Unsupported document type: ${doc.type}`);
            } catch (error) {
              console.error("Error processing document:", error);
              return []; // Return empty array if there's a general error
            }
          })
        );
        
    
        // Flatten the resulting array
        const allImages = processedImages.flat();
        console.log("ALL IMAGES", allImages);
        if(type==="receipt"){
          setReceiptImage(allImages)
        } else {
          setImages(allImages); // Update state with all images
        }
        
      } catch (error) {
        console.error("Error rendering documents:", error);
      }
    };
    


    if(applicationId) {
      try {
        const response = await Server.get(`/api/get-note-sheet/${applicationId}`);
        console.log("RESPONSE",response.data);
        setData(response.data)
        const document = []

        response.data?.documents.forEach((item) => {
          document.push(`${process.env.REACT_APP_BASE_URL}/${item}`);
        });


        renderDocumentsAsImages(document,"document");

        // const receiptDocument = [
        //   "http://localhost:3000/fly.jpg",
        //   "http://localhost:3000/drylab.pdf"
        // ]

        const receiptDocument = [];

        if(response.data?.deposit_amount_receipt){
          receiptDocument.push(`${process.env.REACT_APP_BASE_URL}/${response.data?.deposit_amount_receipt}`);
        }

        if(response.data?.processing_fees_receipt){
          receiptDocument.push(`${process.env.REACT_APP_BASE_URL}/${response.data?.processing_fees_receipt}`);
        }

        if(response.data?.proclamation_fees_receipt){
          receiptDocument.push(`${process.env.REACT_APP_BASE_URL}/${response.data?.proclamation_fees_receipt}`);
        }
        

        renderDocumentsAsImages(receiptDocument,"receipt");

        setIsDataLoaded(true);
      } catch(e) {
        dispatch(SetNewAlert({
          msg: e.response.data.message,
          alertType: "danger",
        }));
      }
      
    }
    else {
      dispatch(SetNewAlert({
        msg: "Invalid Application Id",
        alertType: "danger",
      }));
    }
    
  }, []);

  useEffect(() => {
    if (isDataLoaded && isImageLoaded) {
      // window.print()
    }
  }, [isDataLoaded, isImageLoaded]);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  console.log("IMAGES",images);

  return (
    <div>
      {data && isDataLoaded && 
      <div id="content-to-print" style={{ padding: '30px' }}>
        {mdUp ? (
          <div>


            <div style={{ marginLeft: '-30px', marginRight: '-30px', marginTop: '-10px' }}>
              <img src={data?.letterhead} alt="QR Code" style={{ width: '100%', height: '120px' }}  onLoad={handleImageLoad} />
            </div>

            <div style={{ justifyContent:'space-between',display:'flex', marginTop: '20px', paddingBottom: '20px' }}>
              <p style={{ margin: 0 }}>
                <strong>{data?.application_number}</strong> 
              </p>
              <p style={{ margin: 0 }}>
              <strong>दिनांक:</strong> {data?.permission_date}
              </p>
            </div>
            <div style={{ marginTop: '15px', paddingBottom: '20px' }}>
              <p>प्रति,</p>
              <p>{data?.applicant_name}</p>
              <p>{data?.address}</p>
              <br />
              <p>
                <strong>विषय:</strong> झाडे तोडण्याची परवानगी मिळणेबाबत
              </p>
              <p>
              <strong>संदर्भ:</strong> 1. आपले दिनांक {data?.application_date} रोजीचा अर्ज
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2. जाहीरनामा दिनांक {data?.proclamation_date}
              </p>
              <br />
              <p>
              &nbsp;&nbsp;&nbsp;&nbsp;वरील संदर्भांकित विषयाच्या अनुषंगाने कळविण्यात येते की, संदर्भित पत्रात नमूद केल्यानुसार झाड तोडण्यास
                महाराष्ट्र नागरी क्षेत्र झाडाची जतन व संरक्षण अधिनियम १९७५ चे कलम ८ (३) तसेच शासन निर्णय क्र
                टीपीएस/१८१५/सीआर-२१६/युडी-१३ / दि ११/७/२०१७ अन्वये खालील प्रमाणे शर्ती अटी वर निर्देशित केलीली व
                अर्जदाराच्या अर्जानुसार चिन्हांकित केल्यापैकी खालील झाडे तोडण्याची परवानगी देण्यात येत आहे.
              </p>
              <ol style={{ marginTop: '10px',paddingLeft: '10px' }}>
              {data?.approved_trees?.map((value,index)=>{
                    return <li><ul style={{marginTop: '10px', paddingLeft: '10px',listStyleType: 'none' }}>
                    <li><strong>झाडाचे नाव : </strong>{value.tree_name}</li>
                    <li><strong>झाडाचे वय : </strong>{value.tree_age} </li>
                    </ul></li>
                })}
              </ol>
            </div>
            <strong>शर्ती अटी :</strong>
            <ol style={{ marginTop: '10px', paddingLeft: '10px', paddingBottom: '10px' }}>
            <li>किमान {data?.total_trees_to_be_planted} वृक्ष लागवड करून त्याचे संगोपन व संवर्धन करण्यात यावे</li>
              <li>
                सदर झाड आपल्या स्वखर्चाने काढण्यात यावे , कोणत्याही प्रकारची हानी होणार नाही याची दक्षता घ्यावी ,
                कोणत्याही प्रकारची हानी झाल्यास नगर परिषद जबाबदार राहणार नाही
              </li>
              <li>
                वृक्ष अधिकारी यांनी स्थळ निरीक्षण केल्यानुसार व निदर्शनानुसारच झाड किंवा फांद्या तोडण्यात यावी , त्या
                पेक्षा जास्त झाडे तोडल्यास आपणावर नियमानुसार कारवाही करण्यात येईल याची नोंद घ्यावी{' '}
              </li>
              <li>झाडाबाबत किंवा मालकी हक्काबाबत वाद निर्माण झाल्यास परवानगी रद्द समझण्यात यावी</li>
              <li>
                झाड किंवा फांद्या तोडताना विद्युत वाहक तार असल्यास त्यासाठी विद्युत विभागाची परवानगी घेऊनच तोडण्याची
                कारवाही करावी{' '}
              </li>
              <li>कायदा व सुव्यवस्था अबाधित राहील याची दक्षता घ्यावी </li>
              <li>वरील अटी शर्तीचे उल्लंघन केल्यास सदर परवानगी रद्द समझण्यात यावी </li>
              <li>
                आत क्र १ चे पालन करून दार ६ महिन्याला अहवाल सादर करावा , अहवाल सादर न केल्यास सुरक्षा अमानत रक्कम जप्त
                करण्यात येईल याची नोंद घ्यावी{' '}
              </li>
            </ol>

            <div style={{ textAlign: 'right', marginTop: '20px' }}>
              <p>मुख्याधिकारी,</p>
              <p>{data?.council}</p>
            </div>


            <div style={{ display:'flex',marginTop :'10px' ,justifyContent:'center',alignItems:'center', flexDirection:'column', pageBreakBefore : 'always', breakBefore:'page', }}>
                    <h1>
                        {data?.council}
                    </h1>
                    <h1>
                       CONTINUATION SHEET
                    </h1>
            </div>

            <div style={{ marginTop: '30px', paddingBottom: '20px' }}>
              <p style={{ margin: 0 }}>
              <strong>Name of Department : </strong> ___________________________ 
              </p>
              <p style={{ margin: 0 }}>
              <strong>Subject : </strong> ___________________________
              </p>
              <p style={{ margin: 0 }}>
              <strong>Cases & Class No : </strong> ___________________________
              </p>
            </div>

            <div style={{ marginTop: '30px', paddingBottom: '20px',border : '2px solid', padding : '20px' }}>
                <center><strong>Order of Proceeding With Signature of Officer</strong></center>
                <br />
                <p>महोदय,</p>
                <p>
              &nbsp;&nbsp;&nbsp;&nbsp;अर्जदार <strong>{data?.applicant_name}</strong> यांचा दिनांक : <strong>{data?.application_date}</strong> रोजी च्या प्राप्त अर्जा मध्ये खालील
              <strong> {data?.total_trees} </strong>वृक्ष तोडणायची परवानगी अपेक्षित आहे.
              </p>
              {/* <ol style={{ marginTop: '10px', paddingLeft: '20px', paddingBottom: '20px' }}>
              <li>किमान {data?.total_trees_to_be_planted} वृक्ष लागवड करून त्याचे संगोपन व संवर्धन करण्यात यावे</li>
              <li>
                सदर झाड आपल्या स्वखर्चाने काढण्यात यावे , कोणत्याही प्रकारची हानी होणार नाही याची दक्षता घ्यावी ,
                कोणत्याही प्रकारची हानी झाल्यास नगर परिषद जबाबदार राहणार नाही
              </li>
              <li>
                वृक्ष अधिकारी यांनी स्थळ निरीक्षण केल्यानुसार व निदर्शनानुसारच झाड किंवा फांद्या तोडण्यात यावी , त्या
                पेक्षा जास्त झाडे तोडल्यास आपणावर नियमानुसार कारवाही करण्यात येईल याची नोंद घ्यावी{' '}
              </li>
              <li>झाडाबाबत किंवा मालकी हक्काबाबत वाद निर्माण झाल्यास परवानगी रद्द समझण्यात यावी</li>
              <li>
                झाड किंवा फांद्या तोडताना विद्युत वाहक तार असल्यास त्यासाठी विद्युत विभागाची परवानगी घेऊनच तोडण्याची
                कारवाही करावी{' '}
              </li>
              </ol> */}

              <p>
              <ol style={{marginTop: '10px', paddingLeft: '20px', }}>
                {data?.cutting_trees?.map((value,index)=>{
                    return <li><ul style={{marginTop: '10px', paddingLeft: '20px',listStyleType: 'none' }}>
                    <li><strong>वृक्षाचे नाव : </strong>{value.tree_name}</li>
                    <li><strong>तोडण्याचे कारण : </strong>{value.reason} </li>
                    </ul></li>
                })}
              
              </ol>
              </p>
                <br />
              <p>
              सदर झाडांचे स्थळ निरीक्षण करावे लागेल, झाडांचे स्थळ निरीक्षण करण्याकरिता <strong> {data?.site_inspector} </strong> यांना आदेशित करण्या करीत सादर.
              </p>

              <div style={{marginTop: '30px' }}>
              <p>{data?.council}</p>
              <p><strong>वृक्ष अधिकारी,</strong></p>
              
            </div>
            <br />
            <br />
            <p>महोदय,</p>
                
                <p>
              &nbsp;&nbsp;&nbsp;&nbsp;उपरोक्त आदेशा नुसार सदर झाडांचे निरीक्षण केले असता, खालील निदर्शने आढळली :
              </p>

              <p>
              <ol style={{marginTop: '10px', paddingLeft: '20px', }}>
                {data?.cutting_trees?.map((value,index)=>{
                    return <li><ul style={{marginTop: '10px', paddingLeft: '20px', listStyleType: 'none'}}>
                    <li><strong>झाडाचे नाव : </strong>{value?.inspection?.tree_name}</li>
                    <li><strong>अंदाजे वय : </strong>{value.inspection?.tree_age} </li>
                    <li><strong>परीघ : </strong>{value.inspection?.girth} </li>
                    <li>
                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '10px' }}>
                      <strong>निदर्शने : </strong>
                    <ol style={{ paddingLeft: '10px', margin: 0}}>
                    {value.inspection?.observations?.map((obs,index)=>{
                      return <li>{obs}</li>
                    })}
                    </ol>
                    </div>
                    </li>
                    <li style={{  marginTop: '7px'}}><strong>अर्जदाराने दिलेले कारण : </strong>{value?.inspection?.given_reason==="Valid" ? "योग्य" : "अयोग्य"} </li>
                    </ul></li>
                })}
              
              </ol>
              </p>
              <br />
              <p>
              स्थळनिरीक्षण अहवाल पुढील कारवाही साठी सविनय सादर.
              </p>
              {/* <ol style={{ marginTop: '10px', paddingLeft: '20px', paddingBottom: '20px' }}>
              <li>किमान {data?.total_trees_to_be_planted} वृक्ष लागवड करून त्याचे संगोपन व संवर्धन करण्यात यावे</li>
              <li>
                सदर झाड आपल्या स्वखर्चाने काढण्यात यावे , कोणत्याही प्रकारची हानी होणार नाही याची दक्षता घ्यावी ,
                कोणत्याही प्रकारची हानी झाल्यास नगर परिषद जबाबदार राहणार नाही
              </li>
              <li>
                वृक्ष अधिकारी यांनी स्थळ निरीक्षण केल्यानुसार व निदर्शनानुसारच झाड किंवा फांद्या तोडण्यात यावी , त्या
                पेक्षा जास्त झाडे तोडल्यास आपणावर नियमानुसार कारवाही करण्यात येईल याची नोंद घ्यावी{' '}
              </li>
              <li>झाडाबाबत किंवा मालकी हक्काबाबत वाद निर्माण झाल्यास परवानगी रद्द समझण्यात यावी</li>
            </ol> */}

            <div style={{marginTop: '30px' }}>
              <p>{data?.council}</p>
              <p><strong>वृक्ष अधिकारी,</strong></p>
              
            </div>
            <br />
            <br />
            <p>महोदय,</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;सादर वृक्ष स्थळ अहवालानुसार , खालील झाडे तोडण्याचे कारण योग्य वाटते.</p>

            <p>
              <ol style={{marginTop: '10px', paddingLeft: '20px', }}>
                {data?.approved_trees?.map((value,index)=>{
                    return <li><ul style={{marginTop: '10px', paddingLeft: '20px', listStyleType: 'none'}}>
                    <li><strong>वृक्षाचे नाव : </strong>{value.tree_name}</li>
                    <li><strong>अंदाजे वय : </strong>{value.tree_age}</li>
                    <li><strong>कारण : </strong>{value.reason} </li>
                    </ul></li>
                })}
              
              </ol>
              </p>
              <br />
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;वृक्ष तोड पर्वांगीकरिता महा नागरी क्षेत्र झाडांचे संरक्षण व जतन अधिनियम १९७५ कलम ८ (३) क नुसार नागरिकांच्या हरकती मागवाव्या लागतील तसेच नगर विकास विभागाचे शाषण परिपत्रक क्र एम यू एम /२०२२/प्र क्र २५/नवि १७ दिनांक १९ जानेवारी २००२ नुसार भरपाई वृक्षांची संख्या
              <strong> {data?.total_trees_to_be_planted} </strong>इतकी येते, इतक्या वृक्ष करिता रक्कम रुपये <strong> {data?.deposit_per_tree} </strong>प्रति वृक्षा प्रमाणे सुरक्षा अमानत म्हणून
              <strong> {data?.deposit_amount} </strong>रुपये भरणा केलेले आहे व अवलोकनार्थ भरणा पावती सोबत जोडली आहे तरी भरपाई वृक्षारोपण व नागरिकांच्या हरकती मागविण्याकरिता जाहीरनामा पत्र तयार करून अर्जदारास पत्र देण्याच्या आदेशार्थ सादर.
              </p>
              <div style={{marginTop: '30px' }}>
              <p>{data?.council}</p>
              <p><strong>वृक्ष अधिकारी,</strong></p>
              
            </div>

            <br />
            <br />
            <p>महोदय,</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;अर्जदाराने दिनांक <strong>{data?.proclamation_date}</strong> चे <strong>{data?.newspaper}</strong> वर्तमानपत्रात जाहीरनामा प्रसिद्ध केला असून मागील
            <strong> {data?.waiting_days_for_objection} </strong>दिवसात कोणताही आक्षेप आलेला नाही तरी संबंधिता कडून वृक्ष तोड परवानगी शुल्क <strong>{data?.processing_fees} </strong>रुपये मिळाले असून अवलोकनार्थ भरणा पावती सोबत जोडली आहे.
            </p>
            <br />
            <p>
            परवानगी पत्र तयार करून स्वाक्षरी तथा परवानगी साठी सादर
            </p>
            <div style={{marginTop: '30px' }}>
              <p>{data?.council}</p>
              <p><strong>वृक्ष अधिकारी,</strong></p>
              
            </div>
            </div>

            <div >
            {images.length > 0 ? (
              images.map((src, index) => (
                <div key={index} style={{ height:'700px',marginTop: '30px',pageBreakBefore : 'always', breakBefore:'page'}}>
                  <img src={src} alt={`Page ${index + 1}`} style={{ width: "100%", height:'700px', marginBottom: "20px" }} />
                </div>
              ))
            ) : (
              <p>Loading PDF...</p>
            )}
          </div>

          <div >
            {data?.approved_trees?.length > 0 && (
              data?.approved_trees?.map((src, index) => (
                <div key={index} style={{ height:'700px',marginTop: '30px',pageBreakBefore : 'always', breakBefore:'page'}}>
                  <img src={`${process.env.REACT_APP_BASE_URL}/${src.image_url}`} alt={`Page ${index + 1}`} style={{ width: "100%", height:'700px', marginBottom: "20px" }} />
                </div>
              ))
            ) }
          </div>

          <div>
            {data?.newspaper_image && 
                <div  style={{ height:'700px',marginTop: '30px',pageBreakBefore : 'always', breakBefore:'page'}}>
                  <img src={`${process.env.REACT_APP_BASE_URL}/${data.newspaper_image}`} alt={`Page ${data.newspaper_image}`} style={{ width: "100%", height:'700px', marginBottom: "20px" }} />
                </div>
                }
          </div>

          <div >
            {receiptImage.length > 0 ? (
              receiptImage.map((src, index) => (
                <div key={index} style={{ height:'700px',marginTop: '30px',pageBreakBefore : 'always', breakBefore:'page'}}>
                  <img src={src} alt={`Page ${index + 1}`} style={{ width: "100%", height:'700px', marginBottom: "20px" }} />
                </div>
              ))
            ) : (
              <p>Loading PDF...</p>
            )}
          </div>
            

          </div>
        ) : (
          <div style={{ fontSize: '10px' }}>
          <div style={{ marginLeft: '-30px', marginRight: '-30px', marginTop: '-10px' }}>
              <img src={data?.letterhead} alt="QR Code" style={{ width: '100%', height: '120px' }}  onLoad={handleImageLoad} />
            </div>

            <div style={{ justifyContent:'space-between',display:'flex', marginTop: '20px', paddingBottom: '20px' }}>
              <p style={{ margin: 0 }}>
                <strong>{data?.application_number}</strong> 
              </p>
              <p style={{ margin: 0 }}>
              <strong>दिनांक:</strong> {data?.permission_date}
              </p>
            </div>
            <div style={{ marginTop: '20px', paddingBottom: '20px' }}>
              <p>प्रति,</p>
              <p>{data?.applicant_name}</p>
              <p>{data?.address}</p>
              <br />
              <p>
                <strong>विषय:</strong> झाडे तोडण्याची परवानगी मिळणेबाबत
              </p>
              <p>
              <strong>संदर्भ:</strong> 1. आपले दिनांक {data?.application_date} रोजीचा अर्ज
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2. जाहीरनामा दिनांक {data?.proclamation_date}
              </p>
              <br />
              <p>
              &nbsp;&nbsp;&nbsp;&nbsp;वरील संदर्भांकित विषयाच्या अनुषंगाने कळविण्यात येते की, संदर्भित पत्रात नमूद केल्यानुसार झाड तोडण्यास
                महाराष्ट्र नागरी क्षेत्र झाडाची जतन व संरक्षण अधिनियम १९७५ चे कलम ८ (३) तसेच शासन निर्णय क्र
                टीपीएस/१८१५/सीआर-२१६/युडी-१३ / दि ११/७/२०१७ अन्वये खालील प्रमाणे शर्ती अटी वर निर्देशित केलीली व
                अर्जदाराच्या अर्जानुसार चिन्हांकित केल्यापैकी खालील झाडे तोडण्याची परवानगी देण्यात येत आहे.
              </p>
              <ol style={{ marginTop: '10px',paddingLeft: '10px' }}>
              {data?.approved_trees?.map((value,index)=>{
                    return <li><ul style={{marginTop: '10px', paddingLeft: '10px', listStyleType: 'none'}}>
                    <li><strong>झाडाचे नाव : </strong>{value.tree_name}</li>
                    <li><strong>झाडाचे वय : </strong>{value.tree_age} </li>
                    </ul></li>
                })}
              </ol>
            </div>
            <strong>शर्ती अटी :</strong>
            <ol style={{ marginTop: '10px', paddingLeft: '10px', paddingBottom: '20px' }}>
            <li>किमान {data?.total_trees_to_be_planted} वृक्ष लागवड करून त्याचे संगोपन व संवर्धन करण्यात यावे</li>
              <li>
                सदर झाड आपल्या स्वखर्चाने काढण्यात यावे , कोणत्याही प्रकारची हानी होणार नाही याची दक्षता घ्यावी ,
                कोणत्याही प्रकारची हानी झाल्यास नगर परिषद जबाबदार राहणार नाही
              </li>
              <li>
                वृक्ष अधिकारी यांनी स्थळ निरीक्षण केल्यानुसार व निदर्शनानुसारच झाड किंवा फांद्या तोडण्यात यावी , त्या
                पेक्षा जास्त झाडे तोडल्यास आपणावर नियमानुसार कारवाही करण्यात येईल याची नोंद घ्यावी{' '}
              </li>
              <li>झाडाबाबत किंवा मालकी हक्काबाबत वाद निर्माण झाल्यास परवानगी रद्द समझण्यात यावी</li>
              <li>
                झाड किंवा फांद्या तोडताना विद्युत वाहक तार असल्यास त्यासाठी विद्युत विभागाची परवानगी घेऊनच तोडण्याची
                कारवाही करावी{' '}
              </li>
              <li>कायदा व सुव्यवस्था अबाधित राहील याची दक्षता घ्यावी </li>
              <li>वरील अटी शर्तीचे उल्लंघन केल्यास सदर परवानगी रद्द समझण्यात यावी </li>
              <li>
                आत क्र १ चे पालन करून दार ६ महिन्याला अहवाल सादर करावा , अहवाल सादर न केल्यास सुरक्षा अमानत रक्कम जप्त
                करण्यात येईल याची नोंद घ्यावी{' '}
              </li>
            </ol>

            <div style={{ textAlign: 'right', marginTop: '30px' }}>
              <p>मुख्याधिकारी,</p>
              <p>{data?.council}</p>
            </div>

           
           
            

          </div>
        )}
      </div>
      }


      {data && isDataLoaded && <center style={{paddingBottom:'10px'}}><button className='print-btn' style={{padding:'5px',cursor:'pointer',minWidth:'80px'}} onClick={()=>window.print()}>Save as Pdf / Print</button></center>}
    </div>
  );
}

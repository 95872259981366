import React, { useState, useEffect } from 'react';
import {
  CardActionArea,
  CardMedia,
  Card,
  CardContent,
  Box,
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Tab,
  Typography,
  CircularProgress,
  IconButton,
  Link,
  Chip,
  Modal
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { green, orange, red, gray } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { format } from 'date-fns';
import pdfFile from '../../../Assets/pdf.png'

const ProclamationApplicationDetails = ({ applicationDetailsData, showLoader }) => {
  console.log('applicationDetailsData1998', applicationDetailsData);

    const [open, setOpen] = useState(false);
   const [selectedImageForModal, setSelectedImageForModal] = useState('');
  const openDocument = (url) => {
    window.open(url,'_blank')
  }

  const handleOpen = (imageUrl) => {
    setOpen(true);
    setSelectedImageForModal(imageUrl)
  }

  const handleClose = () => setOpen(false);

  return (
    <>
      <Box>
        {showLoader ? (
          <Card sx={{ margin: '15px', padding: '20px' }}>
            <Grid container>
              <Grid item lg={12} sm={12} sx={{ display: 'flex', justifyContent: 'center', alignItem: 'center' }}>
                <CircularProgress />
              </Grid>
            </Grid>
          </Card>
        ) :
        
        (
          <Box>

<Modal
                  open={open}
                  onClose={handleClose}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Box
                    sx={{
                      position: 'relative',
                    }}>
                    <IconButton
                      onClick={handleClose}
                      sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        color: 'black',
                      }}>
                      <CloseIcon />
                    </IconButton>

                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/${selectedImageForModal}`}
                      alt="img"
                      height="600px"
                      width="600px"
                      style={{ borderRadius: '15px', objectFit: 'cover' }}
                    />
                  </Box>
                </Modal>
          {applicationDetailsData?.newspaper_proclamations.length===0 && 
                              <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop : '100px',
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" color="textSecondary">
                            No Record
                          </Typography>
                        </Box>
                        }

          {applicationDetailsData?.newspaper_proclamations.map((info) => {
            return (
              <Card sx={{ margin: '15px', padding: '20px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: '700' }}>Newspaper Name</Typography>
                    <Typography> {info.newspaper.newspaper}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: '700' }}>Publish Date </Typography>
                    <Typography>     {info?.published_date ? format(new Date(info.published_date), 'MMMM d, yyyy') : ''}</Typography> 
                    
                  </Grid>

                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: '700' }}> Waiting Days For Objection</Typography>
                    <Typography>{info.waiting_days_for_objections} Days</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: '700' }}>Fees</Typography>
                    <Box sx={{display:'flex',flexDirection:"row"}}>
                    <Typography>
                      {' '}
                      {'\u20B9'}
                      {info.fees}
                    </Typography>

                    {info?.fees_status === "Unpaid" ? (
                      <Chip
                        label="Unpaid"
                        size="small"
                        style={{
                          marginLeft: '10px',
                          fontWeight: '600',
                          padding: '1px 10px',
                          backgroundColor: red[600],
                          color: 'white',
                          '&:hover': {
                            backgroundColor: red[800],
                          },
                        }}
                      />
                    ) : (
                      <Chip
                        label="Paid"
                        size="small"
                        style={{
                          marginLeft: '10px',
                          fontWeight: '400',
                          padding: '1px 10px',
                          backgroundColor: green[600],
                          color: 'white',
                          '&:hover': {
                            backgroundColor: green[800],
                          },
                        }}
                      />
                    )}
                     </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ position: 'relative', width:'300px' }}>
                    <Typography sx={{ fontWeight: '700' }}>Newspaper Image</Typography>
                    
                    {/* <img
                      src={`${process.env.REACT_APP_BASE_URL}/${info.image}`}
                      alt="abc"
                      style={{
                        width: '300px',
                        height: '200px',
                        borderRadius: '20px',
                        position: 'relative'

                      }}
                    /> */}
                    <CardMedia
                      component="img"
                      height="200px"
                      width="200px"
                      sx={{ borderRadius: '20px',marginTop:'10px' }}
                      image={`${process.env.REACT_APP_BASE_URL}/${info.image}`}
                      alt="Image"
                      onClick={()=>handleOpen(info.image)}
                    />
                      <IconButton
                    sx={{
                      color: 'black',
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      boxShadow: '0 0 5px rgba(0,0,0,0.2)',

                      width: '25px',
                      height: '25px',
                      position: 'absolute',
                      top: 35,
                      right: 5,
                    }}
                    onClick={()=>handleOpen(info.image)}
                    >
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                  </Box>

                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: '700' }}>Fees Receipt </Typography>
                    {info.fees_receipt ?
                    <Button type='hidden' onClick={()=>openDocument( `${process.env.REACT_APP_BASE_URL}/${info.fees_receipt}`)}>
                    <img src={info.fees_receipt?.endsWith(".pdf") ? pdfFile : `${process.env.REACT_APP_BASE_URL}/${info.fees_receipt}`} 
                    alt="abc"
                    
                    style={{
                      width: '300px',
                      height: '200px',
                      borderRadius: '20px',
                      cursor:'pointer'
                    }} />
                    </Button> :"NA"
                    }
                  </Grid>
                </Grid>

                <Divider sx={{ marginY: '15px' }} />

                <Grid container spacing={2} sx={{ marginTop: '10px' }}>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: '700' }}>Objection By User</Typography>
                    <Typography>{info?.objection_by_user ?info?.objection_by_user : "NA"}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: '700' }}>Objection Date</Typography>
                    <Typography>{info?.objection_date ? info?.objection_date : "NA"}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: '700' }}>Objection Received</Typography>
                    <Typography>{info?.objection_received ? info?.objection_received : "NA"}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: '700' }}>Objection Document </Typography>
                    {info.objection_document ? 
                    // <img src={`${process.env.REACT_APP_BASE_URL}/${info.objection_document}`} 
                    // alt="abc"
                    // style={{
                    //   width: '300px',
                    //   height: '200px',
                    //   borderRadius: '20px',
                    // }} />
                    <Button type='hidden' style={{padding:'0px',marginTop:'7px'}} onClick={()=>openDocument( `${process.env.REACT_APP_BASE_URL}/${info.objection_document}`)}>
                    <img src={info.objection_document.endsWith(".pdf") ? pdfFile : `${process.env.REACT_APP_BASE_URL}/${info.objection_document}`} 
                    alt="abc"
                    
                    style={{
                      width: '300px',
                      height: '200px',
                      borderRadius: '20px',
                      cursor:'pointer'
                    }} />
                    </Button>
                    :"NA"
                  }
                  </Grid>
                </Grid>
              </Card>
            );
          })}

          </Box>

        )
        
        }
      </Box>
    </>
  );
};

export default ProclamationApplicationDetails;

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams, Link as RouterLink, useLocation } from 'react-router-dom';
import { downloadExcel } from 'react-export-table-to-excel';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Tooltip,
  TableCell,
  Box,
  Table,
  TableRow,
  TableContainer,
  TableBody,
  Pagination,
  TooltipButtion,
  CircularProgress,
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
  Chip,
  Drawer,
  MenuItem,
  TextField,
  Autocomplete
} from '@mui/material';
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import TextWithTooltip from '../../components/Tooltip';
import ArrayTooltip from '../../components/ArrayTooltip';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import TreeCuttingApplicationToolbar from '../../sections/@dashboard/tree-cutting/TreeCuttingApplicationToolbar';
import { UserListHead, UserListToolbar, UserFormListMenu } from '../../sections/@dashboard/user';
import { SearchMeeting, GetMeetings, CancelMeeting, GetExcelMeeting, GetAttendeesList, GetTreeCuttingApplicationsList } from '../../actions/MeetingActions';
import StatusChip from '../../components/StatusChip';
import CancelMeetingDialog from '../../components/DialogBox/CancelMeetingDialog';
import UseMoreMenuAction from '../../components/UseMoreMenuAction';
import { GetActiveUserByRoleId } from '../../actions/UserAction';
import { GetAllTreeCuttingApplications } from '../../actions/TreeCuttingPermissionAction';

const TABLE_HEAD = [
  { id: 'srno', label: '#', alignRight: false },
  { id: 'MeetingTitle', label: 'Title', alignRight: false },
  { id: 'MeetingDate', label: ' Date & Time', alignRight: false },
  { id: 'Attendees', label: 'Attendees', alignRight: false },
  { id: 'Applications', label: 'Applications', alignRight: false },
  { id: 'Status', label: 'Status', alignRight: false },
  { id: 'Actions', label: 'Actions', alignRight: false },
];

export default function ViewMeetings(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const maxLength = 5;
  const { meeting, pageInfo, addMeetingLog, loggedUser, excelData,attendees,activeUserByRoleID } = useSelector((state) => ({
    meeting: state.MeetingReducer.meeting,
    pageInfo: state.MeetingReducer.pageInfo,
    addMeetingLog: state.MeetingReducer.addMeetingLog,
    loggedUser: state.auth.loggedUser,
    excelData: state.MeetingReducer.excelData,
    attendees: state.MeetingReducer.attendees,
    activeUserByRoleID : state.users.activeUserByRoleID,
  }));

  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(10);
  const [topModalOpen, setTopModalOpen] = React.useState(false);
  const [deleteId, setDeleteId] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [loader, setLoader] = useState(false);
  const [filterId , setFilterId] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const [filterInput, setFilterInput] = useState('');
  const [filterStartDate, setFilterStartDate] = useState('');
  const [filterEndDate, setFilterEndDate] = useState('');
  const [selectedAttendeeOptions, setSelectedAttendeeOptions] = useState('');
  const [selectedOptions, setSelectedOptions] = useState();
  const [attendeeId, setAttendeeId] = React.useState();
  const [applicationNumber, setApplicationNumber] = React.useState();
  const [selectedApplication, setSelectedApplication] = React.useState();
  const [applicationData, SetApplicationData] = useState([]);

  const [showClearFilter, setShowClearFilter] = useState(false);

  const [newState, setNewState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const useStyles = makeStyles({
    success: {
      backgroundColor: '#DDFAD1',
      color: '#507C59',
      border: '1px solid #507C59',
      fontSize: '12px',
      borderRadius: '5px',
      padding: '4px 10px',
      fontWeight: 600,
    },
    pending: {
      backgroundColor: '#efcbbd',
      color: '#CE5623',
      border: '1px solid #CE5623',
      fontSize: '12px',
      borderRadius: '5px',
      padding: '4px 10px',
      fontWeight: 600,
      pointerEvents: 'none',
    },
    icon: {
      fill: '#214C50',
    },
  });
  const classes = useStyles();

  const filterOptions = [
    { label: 'By Application', value: 'application' },
    { label: 'By Attendees', value: 'attendee' },
    { label: 'By Date', value: 'meeting_date' },
    { label: 'Scheduled', value: 'Scheduled' },
    { label: 'Done', value: 'Done' },
    { label: 'Expired/Cancelled', value: 'Expired' },
  ];

  useEffect(() => {
    setLoader(true);
    let status = ""

    if(filterBy==="Scheduled" || filterBy==="Done" || filterBy==="Expired") {
      status = filterBy
    }
    dispatch(GetMeetings(page, rowsPerPage,status ? null :filterBy , filterId , filterStartDate , filterEndDate, filterInput, status)).then((response) => {
      if (response.status === 200) {
        setLoader(false);
      }
    });
  }, []);

  useEffect(() => {
    setLoader(true);
    dispatch(SearchMeeting(1, rowsPerPage, searchValue)).then((response) => {
      if (response.status === 200) {
        setLoader(false);
      }
    });
  }, [searchValue]);

  let timer = null;
  const filterByName = (event) => {
    const { value } = event.currentTarget;

    clearTimeout(timer);
    timer = setTimeout(() => {
      if (value) {
        setSearchValue(value);
      } else {
        setSearchValue('');
      }
    }, 1000);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (searchValue) {
      dispatch(SearchMeeting(newPage, rowsPerPage, searchValue));
    } else {
      let status = ""

      if(filterBy==="Scheduled" || filterBy==="Done" || filterBy==="Expired") {
        status = filterBy
      }
      dispatch(GetMeetings(newPage, rowsPerPage, status ? null : filterBy , filterId , filterStartDate , filterEndDate, filterInput, status));
    }
  };

  const handleDelete = (id) => {
    dispatch(CancelMeeting(deleteId)).then((resposne) => {
      if (resposne.status === 200) {
        let status = ""

        if(filterBy==="Scheduled" || filterBy==="Done" || filterBy==="Expired") {
          status = filterBy
        }
        dispatch(GetMeetings(page.current, rowsPerPage,status ? null :filterBy , filterId , filterStartDate , filterEndDate, filterInput, status));
      }
    });

    setTopModalOpen(!topModalOpen);
  };

  const handleOpenDelete = (id) => {
    setTopModalOpen(true);
    setDeleteId(id);
  };

  const handleEdit = (meetingId) => {
    console.log('update-meeting', meetingId);
    navigate(`/dashboard/update-meeting/${meetingId}`);
  };
  // -------------- Export reprt----------------------
  const [exportButtonPressed, setExportButtonPressed] = useState(false);
  const header = ['#', 'Meeting Date ', 'Meeting Time', 'Meeting title', 'Attendees', 'Applications', 'Status'];
  function DownloadReportExcel() {
    const value1 = [];
    excelData?.map((option, index) => {
      const value2 = [index + 1];
      value2.push(option?.date_of_meeting);
      value2.push(option?.meeting_time);
      value2.push(option?.meeting_title);
      if (option?.attendees) {
        const attendeesString = option.attendees.map((info) => ` ${info.full_name}`).join(' , ');
        value2.push(attendeesString);
      } else {
        value2.push(''); // Push an empty string if attendees are not defined
      }

      // Concatenate application numbers with <br/> tags
      if (option?.cutting_tree_applications) {
        const applicationsString = option.cutting_tree_applications
          .map((info) => `${info.application_number}`)
          .join(' , ');
        value2.push(applicationsString);
      } else {
        value2.push(''); // Push an empty string if applications are not defined
      }
      value2.push(option?.meeting_status);

      value1.push(value2);
      return null;
    });
    downloadExcel({
      fileName: `Tree Cutting Meetings`,
      sheet: 'Tree Cutting Meetings',
      tablePayload: {
        header,
        // accept two different data structures
        body: value1,
      },
    });
  }
  useEffect(() => {
    if (exportButtonPressed) {
      DownloadReportExcel();
      setExportButtonPressed(false);
    }
  }, [excelData]);

  useEffect(() => {
    dispatch(GetAllTreeCuttingApplications()).then((response) => {
      if (response?.status === 200) {
        SetApplicationData(response?.data.data);
      }
    });
  },[])

  const handleexportReports = () => {
    console.log('export called897789');
    let status = ""

    if(filterBy==="Scheduled" || filterBy==="Done" || filterBy==="Expired") {
      status = filterBy
    }
    dispatch(GetExcelMeeting(status ? null : filterBy , filterId , filterStartDate , filterEndDate, filterInput, status));
    setExportButtonPressed(true);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    // setshowImportStatus(false);
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setNewState({ ...newState, [anchor]: open });
  };


  const FilterSchema = Yup.object().shape({
    filter: Yup.string().required('Please select filter'),
    application: Yup.string().when("filter", {
      is: "application", // Condition: If filter equals "1"
      then: Yup.string().required("Please enter Application Number"),
      otherwise: Yup.string(),
    }),
    attendee: Yup.string().when("filter", {
      is: "attendee", // Condition: If filter equals "1"
      then: Yup.string().required("Attendee is required"),
      otherwise: Yup.string(),
    }),
    
    fromDate: Yup.string().when("filter", {
      is: "meeting_date",
      then: Yup.string().required("Start date is required"),
      otherwise: Yup.string(),
    }),
    toDate: Yup.string().when("filter", {
      is: "meeting_date",
      then: Yup.string().required("End date is required").test(
        "is-greater",
        "End date must be greater than start date",
        (value, context) => {
          const { fromDate } = context.parent;  // Access values directly from context
          return !fromDate || new Date(fromDate) < new Date(value); // Compare dates
        }
      ),
      otherwise: Yup.string(),
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      filter : '',
      application : '',
      attendee : '',
      fromDate : '',
      toDate : '',
    },
    validationSchema: FilterSchema,
    onSubmit: (value) => {
      console.log("INSIDE SUMIT");
      let filterIdForObj = '';

      if(filterBy==="attendee"){
        filterIdForObj = attendeeId
      }
      let filterInputForObj = '';

      if (filterBy==="application") {
        filterInputForObj = value.application
      }

      let status = ""

      if(filterBy==="Scheduled" || filterBy==="Done" || filterBy==="Expired") {
        status = filterBy
      }

      setNewState({ ...newState, right: false });
      setLoader(true);
      setFilterId(filterIdForObj);
      setFilterInput(filterInputForObj)
      setFilterStartDate(value.fromDate)
      setFilterEndDate(value.toDate)
      setPage(1);
      setShowClearFilter(true);
      dispatch(GetMeetings(1, rowsPerPage, status ? null : filterBy , filterIdForObj , value.fromDate , value.toDate, filterInputForObj,status)).then((response) => {
        if (response.status === 200) {
          setLoader(false);
        }
      })
      
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleFilterChange = (event) => {
    setFilterBy(event.target.value);
    formik.resetForm();
    formik.setFieldError({});
    formik.setFieldValue('filter',event.target.value)
    if(event.target.value==='attendee'){
      dispatch(GetActiveUserByRoleId(14))
      
    }
    else {
      setAttendeeId('')
    }

    if(event.target.value!=='application') {
      setApplicationNumber('')
    }

  };



  const handleAttendeeChange = (event) => {
    setAttendeeId(event.target.value);
  };

  // const handleApplicationNumberChange = (e) => {
  //   setApplicationNumber(e.target.value);
  //   console.log('data', e.target.value);
  // };


  const handleApplicationNumberChange = (e) => {
    setApplicationNumber(e);
    formik.setFieldValue('application',e.value);
  };

  const handleClearSearch = () => {
    setSearchValue("");
  }

  const handleClearFilter = () => {
    navigate(0); // Navigates to the current route, triggering a reload
    // setShowClearFilter(false)
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errors.application && touched.application ? "#FF4842" : state.isFocused ? "blue" : base.borderColor,
      "&:hover": {
        borderColor: errors.application && touched.application  ? "#FF4842" : state.isFocused ? "blue" : base.borderColor,
      },
    }),
  };

  return (
    <Page title="Tree Cutting Application">
      <CancelMeetingDialog
        isOpenConfirm={topModalOpen}
        handleClose={() => handleDelete()}
        handleCancle={() => setTopModalOpen(!topModalOpen)}
      />
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Meetings
            <Typography variant="h6" style={{ fontWeight: 400 }}>
              It is showing list of all Meetings and its details
            </Typography>
          </Typography>
          <Box display="flex" flexDirection="row-reverse" gap={2}>
          <Button
              onClick={toggleDrawer('right', true)}
              variant="contained"
              to="#"
              // sx={{ fontSize: '10px', fontWeight: '700', height: '27px' }}
              startIcon={<Iconify icon="eva:funnel-fill" />}>
              Filters
            </Button>
          <Button variant="contained" component={RouterLink} to="/dashboard/create-meeting">
            Add Meeting
          </Button>
          </Box>
        </Stack>

        <Stack direction="row"  mb={1}>
          <Box sx={{ height: '100' }}>
            <Drawer
              sx={{
                '& .MuiDrawer-paper': {
                  width: '350px',
                  maxWidth: '100%',
                  justifyContent: 'start',
                  alignItems: 'center',
                  paddingTop:'2%'
                },
              }}
              anchor={'right'}
              open={newState.right}
              onClose={toggleDrawer('right', false)}>
              <div >
                <Grid container spacing={1} style={{ width: '90%', marginLeft: '5%', marginRight: '5%' }}> 
                  <Stack direction="column" spacing={2} style={{width:'100%'}}>
                    <Grid item xs={12}>
                    <TextField
                    fullWidth
                      select
                      id="filter"
                      name="filter"
                      label="Select Filter Option*"
                      displayEmpty
                      value={filterBy}
                      style={{ width: '100%', marginTop: 5,minWidth:'200px' }}
                      size="small"
                      onChange={(e) => {
                        handleFilterChange(e);
                        formik.handleChange(e);
                      }}
                      error={touched.filter && Boolean(errors.filter)}
                      helperText={touched.filter && errors.filter}
                      // onChange={handleWardChange}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}>
                      <MenuItem disabled value="">
                        <em>Select Filter By*</em>
                      </MenuItem>
                      {filterOptions?.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))
                       }
                    </TextField>
                  </Grid>
                  {filterBy==='attendee' &&
                  <Grid item xs={12}>
                  <TextField
                    select
                    fullWidth
                    id="attendee"
                    name='attendee'
                    label="Select Attendee"
                    displayEmpty
                    value={attendeeId}
                    style={{ width: '100%', marginTop: 5 }}
                    size="small"
                    onChange={(e) => {
                      handleAttendeeChange(e);
                      formik.handleChange(e);
                    }}
                    error={touched.attendee && Boolean(errors.attendee)}
                helperText={touched.attendee && errors.attendee}
                    // onChange={handleWardChange}
                    inputProps={{
                      classes: {
                        icon: classes.icon,
                      },
                    }}>
                    <MenuItem disabled value="">
                      <em>Select Attendee*</em>
                    </MenuItem>
                    {activeUserByRoleID?.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.full_name}
                          </MenuItem>
                        ))
                      }
                  </TextField>
                </Grid>
                  }
                  {filterBy==='application' &&
                  <Grid item xs={12}>
                   {/* <TextField
                      fullWidth
                      id="application"
                      name='application'
                      placeholder="Enter Application Number"
                      label="Application Number"
                      value={applicationNumber}
                      style={{ width: '100%', marginTop: 5 }}
                      
                      onChange={(e) => {
                        handleApplicationNumberChange(e);
                        formik.handleChange(e);
                      }}
                      error={touched.application && Boolean(errors.application)}
                      helperText={touched.application && errors.application}
                    /> */}

                  <Select
                      id="application"
                      name='application'
                      placeholder="Enter Appication No"
                      label="Application No"
                      value={applicationNumber}
                      isClearable
                      styles={customStyles}
                      className="react-select-container"
                      componentsProps={{
                        listbox: {
                          sx: { backgroundColor: '#000' },
                        },
                      }}
                      style={{ borderColor: 'red' }}
                      clearable={false}
                      options={applicationData?.map((item) => {
                        return { value: item.application_number, label: item.application_number };
                      })}
                      // eslint-disable-next-line react/jsx-no-bind
                      onChange={(e)=>{
                        handleApplicationNumberChange(e)
                        // formik.handleChange(e)
                      }}
                    
                    />
                    {errors.application && touched.application && (
              <div style={{ color: '#FF4842', marginTop: '0.25rem',fontSize:'0.75rem' }}>
                {errors.application}
              </div>
            )}

                  </Grid>
                  }
                   
                  {(filterBy==='meeting_date') &&
                  <div>
                  <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="fromDate"
                    type="date"
                    label="Start Date"
                    margin="normal"
                    name="fromDate"
                    style={{ width: '100%', marginTop: 5 }}
                    size="small"
                    value={values.fromDate}
                    onChange={formik.handleChange}
                    error={touched.fromDate && Boolean(errors.fromDate)}
                  helperText={touched.fromDate && errors.fromDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="toDate"
                    type="date"
                    label="End Date"
                    margin="normal"
                    name="toDate"
                    value={values.toDate}
                    style={{ width: '100%', marginTop: 5 }}
                    size="small"
                    onChange={formik.handleChange}
                    error={touched.toDate && Boolean(errors.toDate)}
                  helperText={touched.toDate && errors.toDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                
                </div>}
                  </Stack>
                  <Button
                    onClick={formik.handleSubmit}
                    variant="contained"
                    style={{ width: '60%', marginLeft: '20%', marginRight: '20%', marginTop: 10 }}>
                    Apply
                  </Button>
                  {showClearFilter && 
                  <Button
                    onClick={()=>handleClearFilter()}
                    variant="contained"
                    style={{ width: '60%', marginLeft: '20%', marginRight: '20%', marginTop: 10,backgroundColor:'#bdbdbd' }}>
                    Clear Filter
                  </Button>
                  }
                </Grid>
              </div>
            </Drawer>
          </Box>
        </Stack>

        <Card>
          {/* <UserListToolbar numSelected={0} placeHolder={'Search users...'} onFilterName={filterByName} /> */}
          <TreeCuttingApplicationToolbar
            hideFilter
            handleClearSearch = {handleClearSearch}
            exportReport
            showExport
            handleexportReports={handleexportReports}
            onFilterName={filterByName}
          />
          {/* <Scrollbar> */}

          <TableContainer sx={{ minWidth: 700, overflowX: 'auto' }}>
            <Table size="small" aria-label="a dense table">
              <UserListHead headLabel={TABLE_HEAD} />
              {loader ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={8}>
                      {loader && (
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                          <CircularProgress />
                        </div>
                      )}
                      {/* Add your table rows here */}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {meeting?.length > 0 ? (
                    meeting?.map((info, index) => {
                      // const formattedTime = moment(info.meeting_time, 'HH:mm').format('h:mm A');
                      // console.log(`Formatted time: ${formattedTime}`);

                      return (
                        <TableRow hover>
                          <TableCell align="left">{(page - 1) * rowsPerPage + (index + 1)}</TableCell>
                          <TableCell>
                            <TextWithTooltip text={info.meeting_title} maxLength={12} />
                          </TableCell>

                          <TableCell align="left">{moment(info.date_of_meeting).format('DD MMMM YYYY')}<br/>{info.meeting_time}</TableCell>

                          <TableCell align="left">
                            {info && info.attendees ? (
                              <TextWithTooltip
                                text={info.attendees.map((app) => app.full_name).join(', ')}
                                maxLength={maxLength}
                              />
                            ) : (
                              <span>---</span>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            {info && info.cutting_tree_applications && info.cutting_tree_applications.length > 0 ? (
                              <ArrayTooltip records={info.cutting_tree_applications} />
                            ) : (
                              <span>---</span>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <StatusChip status={info.meeting_status} />
                          </TableCell>

                          <TableCell align="left">
                            <UseMoreMenuAction
                              id={info?.id}
                              status={info.meeting_status}
                              handleDelete={() => handleOpenDelete(info?.id)}
                              handleEdit={() => handleEdit(info?.id)}
                              editUrl="/dashboard/update-meeting"
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8}>
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                          <Typography>No data found !</Typography>
                        </div>

                        {/* Add your table rows here */}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <Box>
            <Pagination
              count={pageInfo.last_page}
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
              sx={{ justifyContent: 'right', display: 'flex', mt: 3, mb: 3 }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

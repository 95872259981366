import React, { useState } from 'react';
import { Card, Grid, Typography, Container, Stack, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import ProjectImg from '../../Assets/plant_master_data.png';
import TreedetailStatusButton from '../../components/statusbutton/TreedetailStatusButton';
// import UserListToolbar from '../../sections/@dashboard/user';
const data = [
    {

        ward_name: "A1",
        tree_count: 2
    },
    {
        ward_name: "A2",
        tree_count: 1
    }
];

const YearwiseCutTreesCard = (props) => {
    const [data, setData] = useState({
        itemsToShow: 12,
    }); // i named it data youcan name it whatever suits you

    // console.log("masterdata", props);
    const filterByName = () => { };
    const useStyles = makeStyles({
        success: {
            backgroundColor: '#DDFAD1',
            color: '#507C59',
            border: '1px solid #507C59',
            fontSize: '12px',
            borderRadius: '5px',
            padding: '4px 10px',
            fontWeight: 600,
            pointerEvents: 'none',
        },
        pending: {
            backgroundColor: '#efcbbd',
            color: '#CE5623',
            border: '1px solid #CE5623',
            fontSize: '12px',
            borderRadius: '5px',
            padding: '4px 10px',
            fontWeight: 600,
            pointerEvents: 'none',
        },
        border: {
            // border: '1px solid #9b9393',
            borderBottom: '1px solid #9b9393',
            // borderRight: '1px solid #9b9393',
            // borderTop: '1px solid #9b9393',
            // borderLef: '1px solid #9b9393',
            // borderBottom: '1px solid #9b9393',

            width: '60%',
            // margin: '10px'
        },
    });
    const classes = useStyles();
    const showMore = () => {
        if (data.itemsToShow) {
            setData({ ...data, itemsToShow: props.projects?.length })
        }
        else {
            setData({ itemsToShow: 12 })
        }
    };
    const ShowLess = () => {
        if (data.itemsToShow) {
            setData({ ...data, itemsToShow: 12 })
        }
        else {
            setData({ ...data, itemsToShow: props.projects?.length })
        }
    }

    return (
        <>
            <Grid container item xs={12} md={12} sm={12} spacing={3} mb={4}>
                <Grid item xs={12} sm={6} md={8}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginLeft: '-24px' }}>
                        <Typography variant="h4" gutterBottom>
                            Year wise cut tree counts
                            <Typography variant="h6" style={{ fontWeight: 400 }}>
                                It is showing all your year wise cut tree counts
                            </Typography>
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <Card style={{ padding: '50px 50px 25px 50px', background: '#214C50' }}>
                            <Grid container rowSpacing={1}>
                                {props.projects?.slice(0, data.itemsToShow).map((item, i) => (
                                    <Grid item xs={4} className={classes.border} key={i} style={{ padding: '0' }}>
                                        <Grid container rowSpacing={1}>
                                            <Grid item xs={10}  key={i} style={{ padding: '0' }}>
                                                <Typography
                                                    variant="h4"
                                                    style={{ color: '#fff', fontWeight: 600 }}

                                                >
                                                    <Typography variant="h5" style={{ fontSize: '20px', padding: '5%' }}>
                                                        {item.year}
                                                       

                                                    </Typography>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}  key={i}>
                                                <Typography
                                                    variant="h4"
                                                    style={{ color: '#fff', fontWeight: 600 }}

                                                >
                                                    <Typography variant="h5" style={{ color: '#D4E489',fontSize: '20px', padding: '5%' }}>
                                                        {item?.total_cut_trees}

                                                    </Typography>
                                                </Typography>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                )
                                )
                                }
                            </Grid>
                            <div style={{ textAlign: 'right', padding: '2%' }}>
                                {data.itemsToShow < props.projects?.length && (
                                    <Button onClick={showMore} variant='contained' style={{ background: '#fff', color: '#000' }}><KeyboardDoubleArrowDownIcon /></Button>
                                )}
                                {data.itemsToShow > 12 && (
                                    <Button onClick={ShowLess} variant='contained' style={{ background: '#fff', color: '#000' }}><KeyboardDoubleArrowUpIcon /></Button>
                                )}
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default YearwiseCutTreesCard;

import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Card, CardMedia, Box, Button, Stack } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import NewTreeDas from '../../Assets/tree-cut.jpg';
import DeviationImg from '../../Assets/deviation_leaf.png';

import { AppWidgetSummary } from '../../sections/@dashboard/app';

import ProjectImg from '../../Assets/toplant_dashboard.png';
//   import Iconify from '../../../components/Iconify';
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        // p: 1,
        // m: 1,
        // bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
        // color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
        // border: '1px solid',
        // borderColor: (theme) =>
        //     theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
        // borderRadius: 2,
        fontSize: '0.875rem',
        fontWeight: '700',
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const data = [
  {
    ward_name: 'A1',
    tree_count: 2,
  },
  {
    ward_name: 'A2',
    tree_count: 1,
  },
];
const CouncilAdminCutTreesCard = (props) => {
  // const { count, title, subtitle } = props.projects;
  const [age, setAge] = React.useState('');
  const [getTreeCount, setTreeCount] = React.useState(0);
  const handleChange = (event) => {
    console.log('event', event.target);
    setAge(event.target.value);
    setTreeCount(event.target.value);
  };
  const useStyles = makeStyles({
    wrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, auto)',
      padding: '10px 15px',
    },
    paddingClass: {
      padding: '10px 15px',
      height:'100%'
    },
    cardleftSection: {
      padding: '5px 20px 15px 7px',
      fontWeight: 600,
    },
    cardCount: {
      padding: '5px 5px 5px 5px',
      fontSize: '35px',
      // borderBottom: '1px solid #EEEEEE'
    },
    border: {
      borderBottom: '1px solid #EEEEEE',
      width: '60%',
    },
    borderRight: {
      borderRight: '1px solid',
      textAlign: 'center',
      height:'100%'
    },
    borderAll: {
      border: '1px solid',
    },
    success: {
      backgroundColor: '#C8FADE',
      color: '#214C50',
      border: '1px solid #214C50',
      borderRadius: '12px',
      padding: '10px 25px 0px 25px',
      fontWeight: 600,
      // pointerEvents: 'none',
    },
    darkSection: {
      backgroundColor: '#214C50',
      color: '#fff',
      borderRadius: '12px',
      fontWeight: 600,
      // pointerEvents: 'none',
      fontSize: '20px',
      padding: '15px',
    },
  });
  const classes = useStyles();
  return (
    <Grid container spacing={1}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Card style={{ height: '550' }}>
            <CardMedia component="img" height="244" image={NewTreeDas} alt="Paella dish" />
            <Grid item xs={12}>
              <Grid container rowSpacing={1} style={{ textAlign: 'center' }}>
                <Grid item xs={4}>
                  <div className={classes.paddingClass}>
                    <Box
                      className={classes.borderRight}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                      <Item>
                        <div>
                          <h4 className={classes.cardCount}>
                            <h2>{props.projects?.total_cut_trees}</h2>
                            Total Trees Cut
                          </h4>
                          {/* </div> */}
                        </div>
                      </Item>
                    </Box>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.paddingClass}>
                    <Box
                         className={classes.borderRight}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                      <Item>
                        <div>
                          <h4 className={classes.cardCount}>
                            <h2>{props.projects?.heritage_trees_cut}</h2>
                            Heritage Trees Cut
                          </h4>
                          {/* </div> */}
                        </div>
                      </Item>
                    </Box>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.paddingClass}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                      <Item>
                        <div>
                          <h4 className={classes.cardCount} style={{paddingTop:'2px !important'}}>
                            Total Replanted Trees
                            <Grid container rowSpacing={1} style={{ textAlign: 'center' }}>
                          <Grid item xs={6}>
                          <div>
                    <Box
                      className={classes.borderRight}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop:'5px'
                      }}>
                      <Item>
                        <div>
                          <h3>
                            <h6>{props.projects?.died_replanted_trees}</h6>
                            Died
                          </h3>
                          {/* </div> */}
                        </div>
                      </Item>
                    </Box>
                  </div>
                            </Grid>
                            <Grid item xs={6}>
                          <div>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop:'5px'
                      }}>
                      <Item>
                        <div>
                          <h3>
                            <h6>{props.projects?.survived_replanted_trees}</h6>
                            Survived
                          </h3>
                          {/* </div> */}
                        </div>
                      </Item>
                    </Box>
                  </div>
                            </Grid>
                          </Grid>
                          </h4>
                          
                        </div>
                      </Item>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CouncilAdminCutTreesCard;

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
  Chip,
  TableCell,
  Box,
  Table,
  TableRow,
  TableContainer,
  TableBody,
  Pagination,
  Tooltip,
  CircularProgress,
  Drawer,
  MenuItem,
  TextField
} from '@mui/material';
import Select from 'react-select';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { makeStyles } from '@material-ui/core/styles';

import { useDispatch, useSelector } from 'react-redux';
import { downloadExcel } from 'react-export-table-to-excel';

import TextWithTooltip from '../../components/Tooltip';

import MeetingListModal from '../../components/DialogBox/meeting/MeetingListModal';
import {
  GetTreeCuttingApplication,
  GetExcelTreeCuttingApplication,
  SearchTreeCuttingApplication,
  GetSiteInscepectorRellList,
} from '../../actions/TreeCuttingPermissionAction';

import Page from '../../components/Page';

import TreeCuttingListMenu from '../../sections/@dashboard/tree-cutting/TreeCuttingListMenu';
import TreeCuttingApplicationToolbar from '../../sections/@dashboard/tree-cutting/TreeCuttingApplicationToolbar';
import { UserListHead, UserListToolbar, UserFormListMenu } from '../../sections/@dashboard/user';
import StatusChip from '../../components/StatusChip';
import SideInscpectorList from '../../components/DialogBox/SiteInscpectorList';
import UseMoreMenuAction from '../../components/UseMoreMenuAction';
import ApplicationStatusChip from '../../components/ApplicationStatusChip';
import Iconify from '../../components/Iconify';
import { GetActiveTreeName } from '../../actions/TreeNameAction';
import { GetActiveUserByRoleId } from '../../actions/UserAction';
import { GetActiveWardsByCouncilId } from '../../actions/WardsActions';


const TABLE_HEAD = [
  { id: 'srno', label: '#', alignRight: false },
  { id: 'application no', label: 'Application No', alignRight: false },
  { id: 'name', label: 'Applicant', toolTip:"Applicant Name" ,  alignRight: false },
  { id: 'contact', label: 'Contact No', alignRight: false },
  { id: 'ward', label: 'Ward', alignRight: false },
  // { id: 'property', label: 'Property No', alignRight: false },
  // { id: 'address', label: 'Address', alignRight: false },
  { id: 'Requested', label: 'Requested', toolTip:"Requested Trees to cut", alignRight: false },
  { id: 'Permitted', label: 'Permitted',toolTip:"Permitted Trees", alignRight: false },

  { id: 'Inspector', label: 'Inspector',toolTip:"Site Inspector Assigned", alignRight: false },
  { id: 'InspectionAssignedat', label: 'Inspection Assigned at', alignRight: false },
  { id: 'inspectionstatus', label: 'Inspection Status', alignRight: false },

  { id: 'status', label: 'Status', toolTip:"Application Status", alignRight: false },

  { id: 'action', label: 'Action', alignRight: false },
];

export default function TreeCuttingAllApplication(props) {
  const dispatch = useDispatch();
  const maxLength = 8;
  const [searchValue, setSearchValue] = useState(null);
  const [filterName, setFilterName] = useState('');
  console.log('searchValue1998', searchValue);

  const [statusId, setStatusId] = useState('');
  const [showList, setShowList] = useState(false);
  const [open, setOpen] = useState(false);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(5);
  const [loader, setLoader] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  // const [lastPage, setLastPage] = useState(2);
  // const scrollContainerRef = useRef(null);
  // const loader = useRef(null);
  const [meetingParam, setMeetingParam] = useState({});
  const [application, setApplication] = useState({ applicationId: '', applicationName: '' });
  const [meetingId, setMeetingId] = useState('');
  const [treeNameInput, setTreeNameInput] = React.useState();
  const [applicantName, setApplicantName] = React.useState();
  const [filterId , setFilterId] = useState('');
  const [applicationTypeId , setApplicationTypeId] = useState('');
  const [statusTypeId , setStatusTypeId] = useState('');
  const [wardID, setWardID] = React.useState('');
  const [applicantId, setApplicantId] = React.useState('');
  const [siteInspectorId, setSiteInspectorId] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterStartDate, setFilterStartDate] = useState('');
  const [filterEndDate, setFilterEndDate] = useState('');
  const [showClearFilter, setShowClearFilter] = useState(false);
  const navigate = useNavigate();
  // To be removed
  const [meetingList, setMeetingList] = useState([
    {
      id: 1,
      meeting_title: 'Meeting related to cutting tree near power grid ',
      meeting_status: 'Scheduled',
      date_of_meeting: '21-08-2024',
      meeting_time: '',
    },
    {
      id: 2,
      meeting_title: 'Meeting related to historical tree cutting',
      meeting_status: 'Scheduled',
      date_of_meeting: '25-08-2024',
      meeting_time: '',
    },
    {
      id: 3,
      meeting_title: 'Meeting related to partially uprooted tree',
      meeting_status: 'Pending',
      date_of_meeting: '',
      meeting_time: '',
    },
    {
      id: 4,
      meeting_title: 'Meeting to cut trees for colonial establishments',
      meeting_status: 'Pending',
      date_of_meeting: '20-08-2024',
      meeting_time: '',
    },
    {
      id: 5,
      meeting_title: 'Meeting to cut trees for requesters personal comfort',
      meeting_status: 'Scheduled',
      date_of_meeting: '7-08-2024',
      meeting_time: '',
    },
  ]);

  const [newState, setNewState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [siteInspectorList, setSiteInspectorList] = useState([]);

  const filterOptions = [
    { label: 'By Wards', value: 'wards' },
    { label: 'By Applicant Name', value: 'applicant_name' },
    { label: 'By Site Inspector', value: 'site_inspector' },
    { label: 'By Inspection Status', value: 'inspection_status' },
    { label: 'By Tree Names', value: 'tree_name' },
    { label: 'By Application Date', value: 'application_date' },
    { label: 'Having Heritage Trees', value: 'heritage_trees' },
    { label: 'Having 100+ Trees ', value: '100_plus_trees' },
    { label: 'By Status', value: 'status' },
    { label: 'By Inspection Date', value: 'inspection_date' },
    // { label: 'Applications by re-planation update date range', value: 10 },
    { label: 'Rescheduled For Inspection', value: 'reschedule_for_inspection' },
    { label: 'By Meeting Date', value: 'meeting_date'},
    // { label: 'Application by Due re-planation update', value: 13 },
    { label: 'Completed', value: 'completed' },
  ];

  const applicationTypeOption = [
    { label: 'In Progress', value: 'In Progress' },
    { label: 'Rescheduled', value: 'Rescheduled' },
    { label: 'Done', value: 'Done' },
    // { label: 'Reassigned ', value: 'Reassigned' },
    {label : "Pending", value : 'Pending'}
  ]

  const statusTypeOption = [
    { label: 'New Application', value: 1 },
    { label: 'Awaiting Inspection', value: 2 },
    { label: 'In Inspection', value: 3 },
    { label: 'Awaiting Deposit Amount', value: 4 },
    {label : "Awaiting for Meeting Approval", value : 5},
    {label : "Rejected", value : 6},
    {label : "Awaiting Proclamation", value : 7},
    {label : "Awaiting Objections", value : 8},
    {label : "Awaiting Proclamation Fees", value : 9},
    {label : "Awaiting Reply from MVP", value : 10},
    {label : "Awaiting for Processing Fees", value : 11},
    {label : "Granted Permssion Letter", value : 12},
    {label : "Recieved Objections", value : 13},

  ]


  // State for selected chip
  const [selectedChips, setSelectedChips] = useState([]);

  const handleChipClick = async (chip) => {
    // const newSelection = selectedChip?.value === chip.value ? null : chip;
    // setSelectedChip(newSelection);
    
    // if (newSelection) {
    //    // Call API with selected chip
    // }

    const isSelected = selectedChips.some(selected => selected.value === chip.value);
    const newSelectedChips = isSelected
      ? selectedChips.filter(selected => selected.value !== chip.value)
      : [...selectedChips, chip];

    setSelectedChips(newSelectedChips);
  };

  console.log('useLocation12', useLocation);

  useEffect(() => {
    dispatch(GetSiteInscepectorRellList()).then((response) => {
      console.log('response45365', response);
      setSiteInspectorList(response?.data?.data?.data);
    });
  }, []);

  useEffect(() => {
    dispatch(GetActiveWardsByCouncilId(1, loggedUser?.council_id));
  }, []);

  let timer = null;
  const filterByName = (event) => {
    const { value } = event.currentTarget;
    
    clearTimeout(timer);
    // Wait for X ms and then process the request
    timer = setTimeout(() => {
      if (value) {
        setSearchValue(value);
      } else {
        setSearchValue('');
      }
    }, 1000);
  };

  const handleClearSearch = () => {
    setSearchValue("");
  }

  const handleStatusChange = (e) => {
    setStatusId(e.target.value);
  };

  const useStyles = makeStyles({
    success: {
      backgroundColor: '#DDFAD1',
      color: '#507C59',
      border: '1px solid #507C59',
      fontSize: '12px',
      borderRadius: '5px',
      padding: '4px 10px',
      fontWeight: 600,
    },
    pending: {
      backgroundColor: '#efcbbd',
      color: '#CE5623',
      border: '1px solid #CE5623',
      fontSize: '12px',
      borderRadius: '5px',
      padding: '4px 10px',
      fontWeight: 600,
      pointerEvents: 'none',
    },
    icon: {
      fill: '#214C50',
    },
  });
  const classes = useStyles();

  const sampleData = [{}];

  const {
    treeCuttingAllApplications,
    excelTreeCuttingApplications,
    isReportDownloadable,
    pageInfo,
    searchTreeCuttingApplications,
    siteinspector,
    treeName,
    wards,
    activeUserByRoleID,
    loggedUser
  } = useSelector((state) => ({
    treeCuttingAllApplications: state.treeCutting.treeCuttingApplications,
    excelTreeCuttingApplications: state.treeCutting.excelTreeCuttingApplications,
    isReportDownloadable: state.treeCutting.isReportDownloadable,
    pageInfo: state.treeCutting.pageInfo,
    searchTreeCuttingApplications: state.treeCutting.searchTreeCuttingApplications,
    siteinspector: state.treeCutting.siteinspector,
    treeName: state.treeName.activeTreeName,
    wards: state.wards.activeWardsByCID,
    activeUserByRoleID : state.users.activeUserByRoleID,
    loggedUser: state.auth.loggedUser,
  }));

  useEffect(()=>{
    dispatch(GetActiveTreeName(1));
  },[])

  console.log("ACTIVE USER",activeUserByRoleID);

  useEffect(() => {
    setLoader(true);
    let filterIdForObj = '';
    let filterByObj = filterBy

      if(filterBy==="applicant_name"){
        filterIdForObj = applicantId
        filterByObj = applicantId || ''
      } else if (filterBy==="site_inspector") {
        filterIdForObj = siteInspectorId
        filterByObj = siteInspectorId || ''
      } else if (filterBy==="wards") {
        filterIdForObj = wardID
        filterByObj = wardID || ''
      } else if (filterBy==="tree_name") {
        
        filterIdForObj = treeNameInput?.value
        filterByObj = treeNameInput?.value || ''
      } else if (filterBy==="status") {
        filterIdForObj = statusTypeId
        filterByObj = statusTypeId || ''
      } 

      let filterStatusForObj = '';

      if (filterBy==="site_inspector") {
        filterStatusForObj = applicationTypeId
      }

      if (filterBy==="inspection_status") {
        filterStatusForObj = applicationTypeId;
      }

    dispatch(GetTreeCuttingApplication(null, null, 1, rowsPerPage, filterByObj , filterIdForObj , filterStartDate , filterEndDate, filterStatusForObj)).then((response) => {
      if (response.status === 200) {
        // setFilteredApplication(response.data.data);
        setLoader(false);
      }
    });
  }, [dispatch,siteinspector]);

  useEffect(() => {
    if (pageInfo) {
      setCount(pageInfo?.total);
      setCurrentPage(pageInfo?.current);
    }
  }, [pageInfo]);

  const secondRun = useRef(true);
  useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    // setFilteredApplication(treeCuttingAllApplications);
    setLoader(false);
    setShowList(true);
  }, [dispatch, treeCuttingAllApplications]);

  const thirdRun = useRef(true);
  useEffect(() => {
    if (thirdRun.current) {
      thirdRun.current = false;
      return;
    }
    // setFilteredApplication(searchTreeCuttingApplications);
    setLoader(false);
    setShowList(true);
  }, [dispatch, searchTreeCuttingApplications]);

  useEffect(() => {
    if (searchValue ) {
      // const searchParam = {
      //   page,
      //   limit: rowsPerPage,
      //   search: searchValue,
      // };

      dispatch(SearchTreeCuttingApplication(1,rowsPerPage,searchValue)).then((response) => {
        if (response.status === 200) {
          setLoader(false);
        }
      });
    } else {

      let filterIdForObj = '';
      let filterByObj = filterBy

      if(filterBy==="applicant_name"){
        filterIdForObj = applicantId
        filterByObj = applicantId || ''
      } else if (filterBy==="site_inspector") {
        filterIdForObj = siteInspectorId
        filterByObj = siteInspectorId || ''
      } else if (filterBy==="wards") {
        filterIdForObj = wardID
        filterByObj = wardID || ''
      } else if (filterBy==="tree_name") {
        
        filterIdForObj = treeNameInput?.value
        filterByObj = treeNameInput?.value || ''
      } else if (filterBy==="status") {
        filterIdForObj = statusTypeId
        filterByObj = statusTypeId || ''
      } 

      let filterStatusForObj = '';

      if (filterBy==="site_inspector") {
        filterStatusForObj = applicationTypeId
      }

      if (filterBy==="inspection_status") {
        filterStatusForObj = applicationTypeId;
      }

      dispatch(GetTreeCuttingApplication(null, null, 1, rowsPerPage, filterByObj , filterIdForObj , filterStartDate , filterEndDate, filterStatusForObj));
    }
  }, [searchValue]);

  // useEffect(() => {
  //   setFilteredApplication(searchTreeCuttingApplications);
  // }, [dispatch, searchTreeCuttingApplications]);

  /* #region Export functionality */
  const [exportButtonPressed, setExportButtonPressed] = useState(false);

  useEffect(() => {
    if (exportButtonPressed) {
      DownloadReportExcel();
      setExportButtonPressed(false);
    }
  }, [isReportDownloadable, excelTreeCuttingApplications]);

  const header = [
    '#',
    'Application Number',
    'Name of Applicant',
    'Property Number',
    'Ward',
    'Mobile',
    'Email',
    'Location',
    'Trees to cut (Requested)',
    'Trees to cut (Granted)',
    'Total Trees to be planted',
    'Inspector',
    'Inspection Assigned at',
    'Inspection Status',
    'Status'
    // 'Purpose',
  ];
  function DownloadReportExcel() {
    const value1 = [];
    excelTreeCuttingApplications?.map((option, index) => {
      const value2 = [index + 1];
      value2.push(option?.application_number);
      value2.push(option?.name);
      value2.push(option?.property_number ? option?.property_number : '-');
      value2.push(option?.ward?.name);
      value2.push(option?.mobile);
      value2.push(option?.email);
      // value2.push(option.location_type)
      value2.push(option?.location);
      value2.push(option?.total_trees);
      value2.push(option?.total_trees_permitted);
      value2.push(option?.total_trees_to_be_planted);
      value2.push(option?.active_inspection?.site_inspector?.full_name);
      value2.push(option?.active_inspection?.assigned_at);
      value2.push(option?.inspection_status);
      value2.push(option?.application_status?.application_status);
      // value2.push(option?.purpose_of_cutting);

      value1.push(value2);
      return null;
    });
    downloadExcel({
      fileName: `Tree Cutting`,
      sheet: 'Tree Cutting',
      tablePayload: {
        header,
        // accept two different data structures
        body: value1,
      },
    });
  }

  const exportReports = () => {
      let filterIdForObj = '';
      let filterByObj = filterBy

      if(filterBy==="applicant_name"){
        filterIdForObj = applicantId
        filterByObj = applicantId || ''
      } else if (filterBy==="site_inspector") {
        filterIdForObj = siteInspectorId
        filterByObj = siteInspectorId || ''
      } else if (filterBy==="wards") {
        filterIdForObj = wardID
        filterByObj = wardID || ''
      } else if (filterBy==="tree_name") {
        
        filterIdForObj = treeNameInput?.value
        filterByObj = treeNameInput?.value || ''
      } else if (filterBy==="status") {
        filterIdForObj = statusTypeId
        filterByObj = statusTypeId || ''
      } else if (filterBy==="application_date" || filterBy==="inspection_date" || filterBy==="meeting_date") {
        filterByObj = (filterStartDate && filterEndDate) ?filterBy : ''
      }  


      let filterStatusForObj = '';

      if (filterBy==="site_inspector") {
        filterStatusForObj = applicationTypeId
      }

      if (filterBy==="inspection_status") {
        filterStatusForObj = applicationTypeId;
      }
    dispatch(GetExcelTreeCuttingApplication(new Date(), new Date(), searchValue,filterByObj , filterIdForObj , filterStartDate , filterEndDate, filterStatusForObj));
    setExportButtonPressed(true);
  };
  /* #endRegion */

  const handleChangePage = (event, newPage) => {
    
    setPage(newPage);

    setLoader(true);
    // setShowList(false);
    if (searchValue) {
      dispatch(SearchTreeCuttingApplication(newPage, rowsPerPage, searchValue));
    } else {
      let filterIdForObj = '';
      let filterByObj = filterBy

      if(filterBy==="applicant_name"){
        filterIdForObj = applicantId
        filterByObj = applicantId ? filterBy : ''
      } else if (filterBy==="site_inspector") {
        filterIdForObj = siteInspectorId 
        filterByObj = siteInspectorId ? filterBy : ''
      } else if (filterBy==="wards") {
        filterIdForObj = wardID
        filterByObj = wardID ? filterBy : ''
      } else if (filterBy==="tree_name") {
        
        filterIdForObj = treeNameInput?.value
        filterByObj = treeNameInput?.value ? filterBy : ''
      } else if (filterBy==="status") {
        filterIdForObj = statusTypeId
        filterByObj = statusTypeId ? filterBy : ''
      } 

      let filterStatusForObj = '';

      if (filterBy==="site_inspector") {
        filterStatusForObj = applicationTypeId
      }

      if (filterBy==="inspection_status") {
        filterStatusForObj = applicationTypeId;
      }
      dispatch(GetTreeCuttingApplication(null, null, newPage, rowsPerPage, filterByObj , filterIdForObj , filterStartDate , filterEndDate, filterStatusForObj));
    }
  };

  const handleEdit = (id) => {
    console.log('Edit Application Data', id);
    // dispatch();
  };

  const handleAddToMeeting = (data, value) => {
    console.log('MEETING LIST', data);
    console.log('Application Id', value.id);
    if (data) {
      setMeetingList(data);
      setApplication({ applicationId: value.id, applicationName: value.application_number });
      setOpen(true);
    }
  };

  console.log("SEARCH BALUE",filterName);

  const handleCloseModal = () => {
    setOpen(!open);
    let filterIdForObj = '';
    let filterByObj = filterBy

      if(filterBy==="applicant_name"){
        filterIdForObj = applicantId
        filterByObj = applicantId || ''
      } else if (filterBy==="site_inspector") {
        filterIdForObj = siteInspectorId
        filterByObj = siteInspectorId || ''
      } else if (filterBy==="wards") {
        filterIdForObj = wardID
        filterByObj = wardID || ''
      } else if (filterBy==="tree_name") {
        
        filterIdForObj = treeNameInput?.value
        filterByObj = treeNameInput?.value || ''
      } else if (filterBy==="status") {
        filterIdForObj = statusTypeId
        filterByObj = statusTypeId || ''
      } 

      let filterStatusForObj = '';

      if (filterBy==="site_inspector") {
        filterStatusForObj = applicationTypeId
      }

      if (filterBy==="inspection_status") {
        filterStatusForObj = applicationTypeId;
      }
    dispatch(GetTreeCuttingApplication(null, null, 1, rowsPerPage, filterByObj , filterIdForObj , filterStartDate , filterEndDate, filterStatusForObj)).then((response) => {
      if (response.status === 200) {
        // setFilteredApplication(response.data.data);
        setLoader(false);
      }
    });
  };

  const handleClick = (meetingId, application) => {
    if (application && application?.applicationId) {
      console.log('APPLICATIONID', application?.applicationId);
      console.log('MeetingId', meetingId);
    }
  };
  const [openSiteIncepectorList, setOpenSiteIncepectorList] = useState(false);
  const [isReassigned, setIsReassigned] = useState(false);

  const handleAddSiteInscpector = (data, value) => {
    if (value) {
      setApplication({ applicationId: value.id, applicationName: value.application_number });
      if(value?.application_status?.id === 2 || value?.application_status?.id === 3) {
        setIsReassigned(true)
      }
      else {
        setIsReassigned(false)
      }
    }
    setOpenSiteIncepectorList(true);
  };

  const handleClosesiteList = () => {
    setOpenSiteIncepectorList(false);
    setLoader(true);
    let filterIdForObj = '';
    let filterByObj = filterBy

      if(filterBy==="applicant_name"){
        filterIdForObj = applicantId
        filterByObj = applicantId || ''
      } else if (filterBy==="site_inspector") {
        filterIdForObj = siteInspectorId
        filterByObj = siteInspectorId || ''
      } else if (filterBy==="wards") {
        filterIdForObj = wardID
        filterByObj = wardID || ''
      } else if (filterBy==="tree_name") {
        
        filterIdForObj = treeNameInput?.value
        filterByObj = treeNameInput?.value || ''
      } else if (filterBy==="status") {
        filterIdForObj = statusTypeId
        filterByObj = statusTypeId || ''
      } 

      let filterStatusForObj = '';

      if (filterBy==="site_inspector") {
        filterStatusForObj = applicationTypeId
      }

      if (filterBy==="inspection_status") {
        filterStatusForObj = applicationTypeId;
      }
    dispatch(GetTreeCuttingApplication(null, null, 1, rowsPerPage, filterByObj , filterIdForObj , filterStartDate , filterEndDate, filterStatusForObj)).then((response) => {
      if (response.status === 200) {
        // setFilteredApplication(response.data.data);
        setLoader(false);
      }
    });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    // setshowImportStatus(false);
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setNewState({ ...newState, [anchor]: open });
  };

  const handleTreeNameSelect = (e) => {
    setTreeNameInput(e);
    console.log('data', e);
    formik.setFieldValue('treeNameFrom',e.value);
  };

  const handleApplicantSelect = (e) => {
    setApplicantName(e);
    formik.setFieldValue('applicant',e.value);
    setApplicantId(e.value);
  }

  const handleFilterChange = (event) => {
    setFilterBy(event.target.value);
    formik.resetForm();
    formik.setFieldError({});
    formik.setFieldValue('filter',event.target.value)
    if(event.target.value==='site_inspector'){
      setWardID('')
      setApplicantId('')
      setTreeNameInput('')
      dispatch(GetSiteInscepectorRellList()).then((response) => {
        if (response.status === 200) {
          setSiteInspectorList(response.data.data);
        }
      });
    }

    if(event.target.value=== 'applicant_name'){
      dispatch(GetActiveUserByRoleId(15))
      setWardID('')
      setSiteInspectorId('')
      setTreeNameInput('')
    }
    if(event.target.value=== 'wards'){
      setSiteInspectorId('')
      setApplicantId('')
      setTreeNameInput('')
      dispatch(GetActiveWardsByCouncilId(1,loggedUser?.council_id))
    }
    if(event.target.value=== 'tree_name'){
      setWardID('')
      setApplicantId('')
      setSiteInspectorId('')
      dispatch(GetActiveTreeName(1));
    }
  };

  const handleApplicationTypeChange = (event) => {
    setApplicationTypeId(event.target.value)
  }

  const handleStatusTypeChange = (event) => {
    setStatusTypeId(event.target.value)
  }

  const handleWardChange = (event) => {
    setWardID(event.target.value);
  };

  const handleApplicantChange = (event) => {
    setApplicantId(event.target.value);
  };

  const handleSelect = (value) => {
    setSiteInspectorId(value.id)
  };

  const handleClearFilter = () => {
    navigate(0); // Navigates to the current route, triggering a reload
    // setShowClearFilter(false)
  };


  const FilterSchema = Yup.object().shape({
    filter: Yup.string().required('Please select filter'),
    ward: Yup.string().when("filter", {
      is: "wards", // Condition: If filter equals "1"
      then: Yup.string().required("Ward is required"),
      otherwise: Yup.string(),
    }),
    applicant: Yup.string().when("filter", {
      is: "applicant_name", // Condition: If filter equals "1"
      then: Yup.string().required("Applicant is required"),
      otherwise: Yup.string(),
    }),
    siteInspector: Yup.string().when("filter", {
      is: "site_inspector", // Condition: If filter equals "1"
      then: Yup.string().required("Site Inspector is required"),
      otherwise: Yup.string(),
    }),
    treeNameFrom: Yup.string().when("filter", {
      is: "tree_name", // Condition: If filter equals "1"
      then: Yup.string().required("Tree Name is required"),
      otherwise: Yup.string(),
    }),
    statusType: Yup.string().when("filter", {
      is: "status", // Condition: If filter equals "1"
      then: Yup.string().required("Status type is required"),
      otherwise: Yup.string(),
    }),

    inspectionStatus: Yup.string().when("filter", {
      is: "inspection_status", // Condition: If filter equals "1"
      then: Yup.string().required("Inspection Status is required"),
      otherwise: Yup.string(),
    }),
    
    
    fromDate: Yup.string().when("filter", {
      is: (value) => value === "application_date" || value === "inspection_date" || value === "meeting_date", // Condition: If filter equals "1"
      then: Yup.string().required("Start date is required"),
      otherwise: Yup.string(),
    }),
    toDate: Yup.string().when("filter", {
      is: (value) => value === "application_date" || value === "inspection_date" || value === "meeting_date", // Condition: If filter equals "1"
      then: Yup.string().required("End date is required").test(
        "is-greater",
        "End date must be greater than start date",
        (value, context) => {
          const { fromDate } = context.parent;  // Access values directly from context
          return !fromDate || new Date(fromDate) < new Date(value); // Compare dates
        }
      ),
      otherwise: Yup.string(),
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      filter : '',
      ward : '',
      applicant : '',
      siteInspector : '',
      treeNameFrom : '',
      fromDate : '',
      toDate : '',
      statusType : '',
      inspectionStatus:''
    },
    validationSchema: FilterSchema,
    onSubmit: (value) => {

      let filterIdForObj = '';

      if(filterBy==="applicant_name"){
        filterIdForObj = applicantId
      } else if (filterBy==="site_inspector") {
        filterIdForObj = siteInspectorId
      } else if (filterBy==="wards") {
        filterIdForObj = wardID
      } else if (filterBy==="tree_name") {
        filterIdForObj = treeNameInput?.value
      } else if (filterBy==="status") {
        filterIdForObj = statusTypeId
      } 

      let filterStatusForObj = '';

      if (filterBy==="site_inspector") {
        filterStatusForObj = applicationTypeId
      }

      if (filterBy==="inspection_status") {
        filterStatusForObj = applicationTypeId;
      }
      setNewState({ ...newState, right: false });
      setLoader(true);
      setFilterId(filterIdForObj);
      setFilterStatus(filterStatusForObj)
      setFilterStartDate(value.fromDate)
      setFilterEndDate(value.toDate)
      setPage(1);
      setShowClearFilter(true);
      dispatch(GetTreeCuttingApplication(null, null, 1, rowsPerPage, filterBy , filterIdForObj , value.fromDate , value.toDate, filterStatusForObj)).then((response) => {
        if (response.status === 200) {
          setLoader(false);
        }
      })
      
    },
  });

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errors.treeNameFrom && touched.treeNameFrom ? "#FF4842" : state.isFocused ? "blue" : base.borderColor,
      "&:hover": {
        borderColor: errors.treeNameFrom && touched.treeNameFrom  ? "#FF4842" : state.isFocused ? "blue" : base.borderColor,
      },
    }),
  };

  const applicantCustomStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errors.applicant && touched.applicant ? "#FF4842" : state.isFocused ? "blue" : base.borderColor,
      "&:hover": {
        borderColor: errors.applicant && touched.applicant  ? "#FF4842" : state.isFocused ? "blue" : base.borderColor,
      },
    }),
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Page title="Tree Cutting Application">
      <Container>
        {/* modules  */}
        {open ? (
          <MeetingListModal
            isOpen={open}
            handleClick={handleClick}
            handleClose={handleCloseModal}
            meetingList={meetingList}
            application={application}
          />
        ) : null}
        {openSiteIncepectorList ? (
          <SideInscpectorList
            isOpen={openSiteIncepectorList}
            isReassigned = {isReassigned}
            handleClick={handleClick}
            handleClose={handleClosesiteList}
            siteInspectorList={siteInspectorList}
            application={application}
          />
        ) : null}

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Tree Cutting Application
            <Typography variant="h6" style={{ fontWeight: 400 }}>
              It is showing list of all application its details
            </Typography>
          </Typography>
          <Box display="flex" flexDirection="row-reverse" gap={2}>
          <Button
              onClick={toggleDrawer('right', true)}
              variant="contained"
              to="#"
              // sx={{ fontSize: '10px', fontWeight: '700', height: '27px' }}
              startIcon={<Iconify icon="eva:funnel-fill" />}>
              Filters
            </Button>
          <Button variant="contained" component={RouterLink} to="/dashboard/create-tree-cutting-permission">
            Add New Application
          </Button>
          </Box>
        </Stack>

        <Stack direction="row"  mb={1}>
          <Box sx={{ height: '100' }}>
            <Drawer
              sx={{
                '& .MuiDrawer-paper': {
                  width: '350px',
                  maxWidth: '100%',
                  justifyContent: 'start',
                  alignItems: 'center',
                  paddingTop:'2%'
                },
              }}
              anchor={'right'}
              open={newState.right}
              onClose={toggleDrawer('right', false)}>
              <div style={{maxWidth:'100%'}} >
                <Grid container spacing={1} style={{ width: '90%', marginLeft: '5%', marginRight: '5%' }}> 
                  <Stack direction="column" spacing={2} style={{width:'100%'}}>
                    <Grid item xs={12}>
                    <TextField
                    fullWidth
                      select
                      id="filter"
                      name="filter"
                      label="Select Filter Option*"
                      displayEmpty
                      value={filterBy}
                      style={{ width: '100%', marginTop: 5,minWidth:'200px' }}
                      size="small"
                      onChange={(e) => {
                        handleFilterChange(e);
                        formik.handleChange(e);
                      }}
                      // InputLabelProps={{
                      //   shrink: true,
                      // }}
                      error={touched.filter && Boolean(errors.filter)}
                      helperText={touched.filter && errors.filter}
                      // onChange={handleWardChange}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}>
                      <MenuItem disabled value="">
                        <em>Select Filter*</em>
                      </MenuItem>
                      {filterOptions?.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))
                       }
                    </TextField>
                  </Grid>
                  {filterBy==='applicant_name' &&
                  <>
                  <Grid item xs={12}>
                    {/* <TextField
                      select
                      fullWidth
                      id="applicant"
                      name='applicant'
                      label="Applicant"
                      displayEmpty
                      value={applicantId}
                      style={{ width: '100%', marginTop: 5 }}
                      size="small"
                      onChange={(e) => {
                        handleApplicantChange(e);
                        formik.handleChange(e);
                      }}
                      error={touched.applicant && Boolean(errors.applicant)}
                  helperText={touched.applicant && errors.applicant}
                      // onChange={handleWardChange}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}>
                      <MenuItem disabled value="">
                        <em>Select Applicant*</em>
                      </MenuItem>
                      {activeUserByRoleID?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.full_name}
                            </MenuItem>
                          ))
                        }
                    </TextField> */}

                    <Select
                      id="applicant"
                      name='applicant'
                      placeholder="Select Applicant*"
                      label="Select Applicant"
                      value={applicantName}
                      isClearable
                      styles={applicantCustomStyles}
                      className="react-select-container"
                      componentsProps={{
                        listbox: {
                          sx: { backgroundColor: '#000' },
                        },
                      }}
                      style={{ borderColor: 'red' }}
                      clearable={false}
                      options={activeUserByRoleID?.map((item) => {
                        return { value: item.id, label: item.full_name };
                      })}
                      // eslint-disable-next-line react/jsx-no-bind
                      onChange={(e)=>{
                        handleApplicantSelect(e)
                        // formik.handleChange(e)
                      }}
                    
                    />
                    {errors.applicant && touched.applicant && (
              <div style={{ color: '#FF4842', marginTop: '0.25rem',fontSize:'0.75rem' }}>
                {errors.applicant}
              </div>
            )}

                  </Grid>
                 
                  
                </>
                  }
                  {filterBy==='wards' &&
                  <Grid item xs={12}>
                    <TextField
                      select
                      fullWidth
                      id="ward"
                      name='ward'
                      label="Ward*"
                      displayEmpty
                      value={wardID}
                      style={{ width: '100%', marginTop: 5 }}
                      size="small"
                      onChange={(e) => {
                        handleWardChange(e);
                        formik.handleChange(e);
                      }}
                      error={touched.ward && Boolean(errors.ward)}
                  helperText={touched.ward && errors.ward}
                      // onChange={handleWardChange}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}>
                      <MenuItem disabled value="">
                        <em>Select Ward*</em>
                      </MenuItem>
                      {wards?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))
                        }
                    </TextField>
                  </Grid>
                  }
                   {filterBy=== 'site_inspector' &&
                   <>
                  <Grid item xs={12}>
                  <TextField
                  id="siteInspector"
                  name='siteInspector'
                select
                fullWidth
                label="Select site Inspector*"
                value={siteInspectorId}
                onChange={(e)=>{
                  formik.handleChange(e);
                }}
                error={touched.siteInspector && Boolean(errors.siteInspector)}
                  helperText={touched.siteInspector && errors.siteInspector}
                SelectProps={{
                  variant: 'outlined',
                  MenuProps: {
                    PaperProps: {
                      className: classes.menuPaper,
                    },
                  },
                }}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}>
                {siteInspectorList?.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    style={{ minWidth: '500', minHeight: '30px' }}
                    onClick={() => {
                      handleSelect(option);
                    }}>
                    {option.full_name}
                  </MenuItem>
                ))}
              </TextField>
                  </Grid>
                  
                  </>
                  }
                  {(filterBy === 'site_inspector' || filterBy ==="inspection_status") && 
                  <Grid item xs={12}>
                  <TextField
                    fullWidth
                      select
                      id="inspectionStatus"
                      name="inspectionStatus"
                      label={filterBy ==="inspection_status" ? "Inspection Status*" : "Inspection Status"}
                      displayEmpty
                      value={applicationTypeId}
                      style={{ width: '100%', marginTop: 5 }}
                      size="small"
                      onChange={(e) => {
                        handleApplicationTypeChange(e);
                        formik.handleChange(e)
                      }}
                      error={touched.inspectionStatus && Boolean(errors.inspectionStatus)}
                  helperText={touched.inspectionStatus && errors.inspectionStatus}
                      // onChange={handleWardChange}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}>
                      <MenuItem disabled value="">
                        <em>Select Inspection Status*</em>
                      </MenuItem>
                      {applicationTypeOption?.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))
                       }
                    </TextField>
                  </Grid>
                  }
                  {(filterBy==='application_date' || filterBy==='inspection_date' || filterBy===10 || filterBy==='meeting_date') &&
                  <div>
                  <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="fromDate"
                    type="date"
                    label="Start Date*"
                    margin="normal"
                    name="fromDate"
                    style={{ width: '100%', marginTop: 5 }}
                    size="small"
                    value={values.fromDate}
                    onChange={formik.handleChange}
                    error={touched.fromDate && Boolean(errors.fromDate)}
                  helperText={touched.fromDate && errors.fromDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="toDate"
                    type="date"
                    label="End Date*"
                    margin="normal"
                    name="toDate"
                    value={values.toDate}
                    style={{ width: '100%', marginTop: 5 }}
                    size="small"
                    onChange={formik.handleChange}
                    error={touched.toDate && Boolean(errors.toDate)}
                  helperText={touched.toDate && errors.toDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                </div>}
                {filterBy==='tree_name' &&
                     <Grid item xs={12}>
                    <Select
                      id="treeNameFrom"
                      name='treeNameFrom'
                      placeholder="Select Tree Name*"
                      label="Tree Name"
                      value={treeNameInput}
                      isClearable
                      styles={customStyles}
                      className="react-select-container"
                      componentsProps={{
                        listbox: {
                          sx: { backgroundColor: '#000' },
                        },
                      }}
                      style={{ borderColor: 'red' }}
                      clearable={false}
                      options={treeName?.map((item) => {
                        return { value: item.id, label: item.name };
                      })}
                      // eslint-disable-next-line react/jsx-no-bind
                      onChange={(e)=>{
                        handleTreeNameSelect(e)
                        // formik.handleChange(e)
                      }}
                    
                    />
                    {errors.treeNameFrom && touched.treeNameFrom && (
              <div style={{ color: '#FF4842', marginTop: '0.25rem',fontSize:'0.75rem' }}>
                {errors.treeNameFrom}
              </div>
            )}
                  </Grid>}
                  {filterBy==='status' && 
                   <Grid item xs={12}>
                   <TextField
                     fullWidth
                       select
                       id="statusType"
                       name="statusType"
                       label="Status*"
                       displayEmpty
                       value={statusTypeId}
                       style={{ width: '100%', marginTop: 5 }}
                       size="small"
                       onChange={(e) => {
                         handleStatusTypeChange(e);
                         formik.handleChange(e)
                       }}
                       // onChange={handleWardChange}
                       error={touched.statusType && Boolean(errors.statusType)}
                  helperText={touched.statusType && errors.statusType}
                       inputProps={{
                         classes: {
                           icon: classes.icon,
                         },
                       }}>
                       <MenuItem disabled value="">
                         <em>Select Application Status*</em>
                       </MenuItem>
                       {statusTypeOption?.map((option) => (
                             <MenuItem key={option.value} value={option.value}>
                               {option.label}
                             </MenuItem>
                           ))
                        }
                     </TextField>
                   </Grid>
                  }
                  </Stack>
                  <Button
                    onClick={()=>formik.handleSubmit()}
                    variant="contained"
                    style={{ width: '60%', marginLeft: '20%', marginRight: '20%', marginTop: 10 }}>
                    Apply
                  </Button>
                  {showClearFilter && 
                  <Button
                    onClick={()=>handleClearFilter()}
                    variant="contained"
                    style={{ width: '60%', marginLeft: '20%', marginRight: '20%', marginTop: 10,backgroundColor:'#bdbdbd' }}>
                    Clear Filter
                  </Button>
                  }
                </Grid>
              </div>
            </Drawer>
          </Box>
        </Stack>


        <Card>
          <TreeCuttingApplicationToolbar
            onFilterName={filterByName}
            handleClearSearch = {handleClearSearch}
            statusId={statusId}
            handleStatusChange={handleStatusChange}
            // exportReports={exportReports}
            showExport
            handleexportReports={exportReports}
          />
          <TableContainer sx={{ minWidth: 700, overflow: 'auto' }}>
            <Table size="small" aria-label="a dense table" sx={{ overflow: 'hidden' }}>
              <UserListHead headLabel={TABLE_HEAD} showToolTip />
              {loader ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={9} style={{ whiteSpace: 'nowrap', width: '2000px' }}>
                      {loader && (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <CircularProgress />
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {treeCuttingAllApplications?.length > 0 ? (
                    treeCuttingAllApplications?.map((value, index) => {
                      console.log('Cuttinggg', value?.active_inspection?.site_inspector?.full_name);
                      return (
                        <TableRow hover>
                          <TableCell align="left">
                            <b>{pageInfo.per_page * (pageInfo.current_page - 1) + index + 1}</b>
                          </TableCell>
                          <TableCell>{value.application_number}</TableCell>
                          <TableCell align="left">
                            <TextWithTooltip text={value.name} maxLength={maxLength} />
                          </TableCell>
                          <TableCell align="left">{value.mobile}</TableCell>
                          <TableCell align="center">{value.ward.name}</TableCell>
                          {/* <TableCell align="left">{value.property_number}</TableCell> */}
                          {/* <TableCell align="left">
                            <TextWithTooltip text={value.location} maxLength={maxLength} />
                          </TableCell> */}
                          <TableCell align="left">{value.total_trees}</TableCell>

                          <TableCell align="left">{value?.total_trees_permitted}</TableCell>

                          {/* site inspector name */}
                          <TableCell align="left">{value?.active_inspection?.site_inspector?.full_name}</TableCell>
                          {/* site inspector assign date */}

                          <TableCell align="left">{value?.active_inspection?.assigned_at}</TableCell>

                          <TableCell align="left">{value?.inspection_status}</TableCell>

                          <TableCell align="left">
                            <ApplicationStatusChip status={value?.application_status?.application_status} />
                          </TableCell>

                          <TableCell align="right">
                            <UseMoreMenuAction
                              status={
                                value.application_status?.application_status
                                  ? value.application_status?.application_status
                                  : '---'
                              }
                              statusId={value?.application_status?.id}
                              IsApplication
                              handleAddToMeeting={() => handleAddToMeeting(meetingList, value)}
                              handleAddSiteInscpector={() => handleAddSiteInscpector(meetingList, value)}
                              id={value?.id}
                              // status={info.meeting_status}
                              // handleDelete={() => handleOpenDelete(info?.id)}
                              handleEdit={() => handleEdit(value?.id)}
                              editUrl="/dashboard/update-tree-cutting-permission"
                              data={value}
                              ProclamationId={value?.newspaper_proclamations[0]?.id}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9}>
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                          <Typography>No data found !</Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <Box>
            <Pagination
              count={pageInfo.last_page }
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
              sx={{ justifyContent: 'right', display: 'flex', mt: 3, mb: 3 }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
